import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import AdvertiserHeader from "components/Headers/AdHeader.jsx";
import { request } from "network/Request";
import {loadTossPayments} from '@tosspayments/payment-sdk';

const Charge = () => {
  const tossPayment = (itemId, price, name) => {
    startPayment(itemId, price, name, openTossPayment);
  }

  const startPayment = (itemId, price, name, doPayment) => {
    request({
      "bk-working": "payment-start",
      "charge_item_id": itemId,
      "charge_from": "toss"
    }, (result, res) => {
      if (result) {
        if (price !== res["price"]) {
          alert("금액 오류");
          return;
        }
        console.log(res["order_id"]);
        doPayment(res["order_id"], price, name);
      }
    })
  }

  const clientKey = 'live_ck_ODnyRpQWGrNkx2nZYRe8Kwv1M9EN';

  const openTossPayment = async (orderId, price, name) => {
    console.log(orderId, price, name);
    const tossPayments = await loadTossPayments(clientKey);
    tossPayments.requestPayment('카드', {
      amount: price,
      orderId: orderId,
      orderName: name,
      // successUrl: 'https://127.0.0.1:3000/advertiser/charge-complete',
      // failUrl: 'https://127.0.0.1:3000/advertiser/charge',
      successUrl: 'https://www.learnward.com/advertiser/charge-complete',
      failUrl: 'https://www.learnward.com/advertiser/charge-fail',
    })
  }

  return (
    <>
      <AdvertiserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="justify-content-center">
          <Col lg="10">
            <div className="pricing card-group flex-column flex-md-row mb-3">
              <Card className="card-pricing border-0 text-center mb-4">
                <CardHeader className="bg-transparent">
                  <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                    스타터팩
                  </h4>
                </CardHeader>
                <CardBody className="px-lg-7">
                  <div className="display-2">5만원</div>
                  <span className="text-muted">25,000코인</span>
                  <ul className="list-unstyled my-4">
                    <li>
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                            <i className="fas fa-terminal" />
                          </div>
                        </div>
                        <div>
                          <span className="pl-2">저가로 광고를 진행해보세요.</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <Button className="mb-3" color="info" type="button" onClick={() => tossPayment(1, 50000, "25,000코인 충전")}>
                    충전하기
                  </Button>
                </CardBody>
                <CardFooter>
                  <a
                    className="text-light"
                    onClick={(e) => e.preventDefault()}
                  >

                  </a>
                </CardFooter>
              </Card>
              <Card className="card-pricing bg-gradient-success zoom-in shadow-lg rounded border-0 text-center mb-4">
                <CardHeader className="bg-transparent">
                  <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                    대량충전
                  </h4>
                </CardHeader>
                <CardBody className="px-lg-7">
                  <div className="display-1 text-white">100만원</div>
                  <span className="text-white">500,000코인</span>
                  <ul className="list-unstyled my-4">
                    <li>
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                            <i className="fas fa-terminal" />
                          </div>
                        </div>
                        <div>
                          <span className="pl-2 text-white">
                            코인을 한번에 대량 충전하세요.
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <Button className="mb-3" color="secondary" type="button"  onClick={() => tossPayment(5, 1000000, "500,000 코인 충전")}>
                    충전하기
                  </Button>
                </CardBody>
                <CardFooter className="bg-transparent">
                  <a
                    className="text-white"
                    onClick={(e) => e.preventDefault()}
                  >
                  </a>
                </CardFooter>
              </Card>
            </div>
          </Col>
        </Row>
        <Card className="mt-3">
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">코인 구매</h3>
              </Col>
            </Row>
          </CardHeader>

          <Table className="align-items-center table-flush" hover responsive>
            <thead className="thead-light">
              <tr>
                <th>코인</th>
                <th>가격</th>
                <th>구매</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-success">
                <td>
                  25,000 코인
                </td>
                <td>
                  50,000원</td>
                <td>
                  <a
                    className="font-weight-bold" onClick={() => tossPayment(1, 50000, "2만5천 코인 충전")}
                  >
                    구매하기
                  </a>
                </td>
              </tr>
              <tr className="table-">
                <td>
                  50,000 코인
                </td>
                <td>
                  100,000원
                </td>
                <td>
                  <a
                    className="font-weight-bold"
                    onClick={() => tossPayment(2, 100000, "5만 코인 충전")}>
                    구매하기
                  </a>
                </td>
              </tr>
              <tr className="table-warning">
                <td>
                  150,000 코인
                </td>
                <td>
                  300,000원
                </td>
                <td>
                  <a
                    className="font-weight-bold"
                    onClick={() => tossPayment(3, 300000, "15만 코인 충전")}>
                    구매하기
                  </a>
                </td>
              </tr>
              <tr className="table-">
                <td>
                  250,000 코인
                </td>
                <td>
                  500,000원
                </td>
                <td>
                  <a
                    className="font-weight-bold"
                    onClick={() => tossPayment(4, 500000, "25만 코인 충전")}> 
                    구매하기
                  </a>
                </td>
              </tr>
              <tr className="table-">
                <td>
                  500,000 코인
                </td>
                <td>
                  1,000,000원
                </td>
                <td>
                  <a
                    className="font-weight-bold"
                    onClick={() => tossPayment(5, 1000000, "50만 코인 충전")}>
                    구매하기
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
}

export default Charge;
