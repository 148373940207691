/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import { request } from "network/Request.js"
import {navigation} from "components/Nav/Nav.jsx"
import dateFormat from "dateformat";

class AdConfirm extends React.Component {
  state = {
    requestCount: 1,
    requestCurrent: 0,
    adList: [],
    impressionState: 10,
  };

  componentDidMount() {
    this.requestRequestList(0);
  }

  requestRequestList(index) {
    request({
      "bk-working": "impression-request-list",
      "list_range": 5,
      "list_offset": index * 5,
      "impression_state": this.state.impressionState
    }, (result, res) => {
      if (result) {
        console.log(res);
        this.setState({
          adList: res["list_array"],
          requestCount: res["ad_count"]
        })
      } else {
        alert("네트워크 오류")
      }
    })
  }

  selectRequestCurrent = (index) => {
    this.setState({ requestCurrent: index })
    this.requestRequestList(index);
  }

  calculateFirst(current, count) {
    return (count <= 7 ? 0 :
      current + 7 > count ? count - 7 :
        current)
  }

  routeChange(path) {
    console.log(path);
    this.props.history.push(path);
  }

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd")
  }

  render() {
    return (
      <>
        <Container className="" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">대기 목록</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">이름</th>
                      <th scope="col">사용자</th>
                      <th scope="col">상태</th>
                      <th scope="col">광고타입</th>
                      <th scope="col">포인트</th>
                      <th scope="col">시작일</th>
                      <th scope="col">자세히</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.adList.map((page, i) => (
                      <tr key={i}>
                        <th scope="row">
                          <span className="mb-0 text-sm">
                            {page["campaign_name"]}
                          </span>
                        </th>
                        <td>
                          {page["user_nickname"]}</td>
                        <td>
                          {page["impression_state"]}</td>
                          <td>
                          {page["campaign_type"]}</td>
                        <td>
                          {page["impression_limit"]}</td>
                        <td>
                          {this.dateFormatting(page["register_date"])}</td>
                        <td>
                          <Button onClick={() =>
                            this.routeChange("judge?campaign_id=" + page["campaign_id"] + "&impression_state=" + page["impression_state"] + "&impression_id=" + page["impression_id"])
                          }
                          >보기</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {navigation(this.selectRequestCurrent, this.state.requestCurrent, this.state.requestCount)}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default AdConfirm;