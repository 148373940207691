/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledTooltip,
  FormGroup,
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import CommonFooter from "components/Footers/CommonFooter.jsx";

class Landing extends React.Component {
  render() {
    return (
      <>
        <IndexNavbar />
        <div className="main-content">
          <IndexHeader />
          <section className="py-6 pb-9 bg-default">
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col md="6">
                  <h2 className="display-3 text-white">
                    사용자에게 유익한 광고를 해보세요.
                  </h2>
                  <p className="lead text-white">
                    런워드는 자본주의식 영어공부라는 재밌는 영어공부 방법을 만들어가고 있어요.
                    광고를 유익하고 거부감 없는 영어교육용 콘텐츠로 만들어 사용자분들께 제공해요.
                    일반 디스플레이 광고에 비해 집중도가 높고 내용이 자연스럽게 전달되어요.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-lg-0 mt--7">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h4 className="h3 text-info text-uppercase">
                            전문 선생님이 콘텐츠를 제작해요.
                          </h4>
                          <p className="description mt-3">
                            런워드의 콘텐츠는 단순 번역이 아닙니다. 선생님들이 광고를 문장에 맞게 유익하고 어색하지 않은 콘텐츠로 재생산합니다.
                          </p>
                          <div>
                            <Badge color="info" pill>
                              파트너시스템
                            </Badge>
                            <Badge color="info" pill>
                              자연스러운
                            </Badge>
                            <Badge color="info" pill>
                              유익한
                            </Badge>
                            <Badge color="info" pill>
                              콘텐츠
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h4 className="h3 text-success text-uppercase">
                            거부감 없이 자연스럽게 홍보를 도와드립니다.
                          </h4>
                          <p className="description mt-3">
                            사용자분들은 광고를 통해 영어공부에 도움을 얻고 문제에 참여한 보상으로 포인트를 받아가요. 콘텐츠에 참여함으로서 거부감없이 내용이 자연스럽게 전될됩니다.
                          </p>
                          <div>
                            <Badge color="success" pill>
                              집중도
                            </Badge>
                            <Badge color="success" pill>
                              전달력
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h4 className="h3 text-warning text-uppercase">
                            사용자 타게팅
                          </h4>
                          <p className="description mt-3">
                            연령, 성별에 따라 사용자를 특정 사용자 그룹을 특정하여 홍보할 수 있어요. 사용자 타겟팅 범주는 효율적으로 늘어날 예정입니다.
                          </p>
                          <div>
                            <Badge color="warning" pill>
                              타게팅
                            </Badge>
                            <Badge color="warning" pill>
                              성별
                            </Badge>
                            <Badge color="warning" pill>
                              연령별
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>학습 콘텐츠로 광고하기.</h1>
                    <p>
                      런워드의 광고는 사용자들의 학습과 복습 콘텐츠로 사용됩니다. 광고기간이 끝나더라도 학습한 콘텐츠는 복습, 미션으로 지속적으로 노출됩니다. 사용자가 직접 광고콘텐츠에 참여하여 집중도가 높습니다.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle mr-3" color="success">
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">광고를 유힉한 콘텐츠로</h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle mr-3" color="success">
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">지속적인 사용자들 참여</h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle mr-3" color="success">
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">높은 콘텐츠 집중도</h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-2.png")}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h1>토요일 시험 문제 참여</h1>
                    <p>
                      매주 토요일 일곱시에는 일주일간 출시된 콘텐츠로 시험이 출제됩니다. 일반 광고들도 시험에 출제될 수 있지만 시험에 확정으로 출제하시면 일주일간 시험문제로 노출됩니다.
                    </p>
                    <Link
                      className="font-weight-bold text-warning mt-5"
                      to="/advertiser/exam"
                    >
                      시험 출제 참여하기
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-3.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>광고주 페이지로 광고 관리</h1>
                    <p>
                      광고주 페이지에서 광고등록 뿐만 아니라 진행중인 광고의 성과와 통계를 보실 수 있습니다. 지금 광고를 진행하고 성과를 확인해보세요.
                    </p>
                    <Link
                      className="font-weight-bold text-info mt-5"
                      to="/advertiser/index"
                    >
                      광고주 둘러보기
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">문의하실 내용이 있으시면 연락처를 남겨주세요.</h4>
                      <FormGroup
                        className="mt-5"
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupText>
                            <i className="ni ni-user-run" />
                          </InputGroupText>
                          <Input
                            id="subject"
                            placeholder="제목"
                            type="text"
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                          <Input
                            id="email"
                            placeholder="메일 주소"
                            type="email"
                            onFocus={e => this.setState({ emailFocused: true })}
                            onBlur={e => this.setState({ emailFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          id="message"
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="전달할 메세지"
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          onClick={(e) => this.sendCounsel()}
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        <CommonFooter />
      </>
    );
  }
}

export default Landing;
