import React from "react";

import {
    Button,
    Badge,
    Container,
    Input
} from "reactstrap"
import AdEdit from "views/ad/AdEdit.jsx"
import CampaignEdit from "views/ad/CampaignEdit.jsx"
import { request } from "network/Request.js"

class AdJudge extends React.Component {
    state = {
        campaignId: null,
        impressionId: null,
        impressionState: 10,
        adArray: null,
    };

    getWorking() {
        let impressionState = Number(this.state.impressionState);
        if (impressionState === 10) {
            return "accept-campaign";
        } else if (impressionState === 8) {
            return "accept-translation";
        } else if (impressionState === 6) {
            return "accept-record";
        } else if (impressionState === 5) {
            return "accept-complete";
        }
    }

    requestAdList(campaignId) {
        var data = {
            "bk-working": "admin-ad-list",
            "campaign_id": campaignId
        }

        request(data, (result, res) => {
            if (result) {
                var array = res["list_array"];
                this.setState({
                    adArray: array,
                })
            }
        });
    }

    requestJudge(accept) {
        if (Number(this.state.impressionState) === 4) {
            alert("최종승인은 광고주 페이지에서 해주세요.");
            return;
        }

        var reason = document.getElementById("judgeReason").value;
        let data = {
            "bk-working": this.getWorking(),
            "campaign_id": this.state.campaignId,
            "impression_id": this.state.impressionId,
            "accept": accept,
            "judge_reason": reason,
        }

        request(data, (result, res) => {
            if (result) {
                alert("등록 완료");
                window.history.back();
            } else {
                alert("등록 실패");
                console.log(res);
            }
        })
    }

    componentDidMount() {
        var campaignId = this.getQueryVariable("campaign_id");
        var impressionState = this.getQueryVariable("impression_state");
        var impressionId = this.getQueryVariable("impression_id");
        this.setState({
            campaignId: campaignId,
            impressionState: impressionState,
            impressionId: impressionId
        });
        this.requestAdList(campaignId);
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }

    render() {
        return <>
            <Container className="mb-5" >
                <Badge className="bg-primary mt-3" >광고 상태</Badge> <h3>{this.state.impressionState}</h3>
                {this.state.adArray != null ?
                    <div>
                        <CampaignEdit
                            working="impression-info-admin"
                            campaignId={this.state.campaignId} />
                        <AdEdit
                            ads={this.state.adArray}
                            ref="adEdit"
                            editable={false}
                            campaignId={this.state.campaignId} >
                        </AdEdit>
                    </div>
                    : ""}
                <Input id="judgeReason" maxLength="40" placeholder="거절 시 거절 사유"></Input>
                <br />
                <Button onClick={() => this.requestJudge(true)}>승인</Button>
                <Button onClick={() => this.requestJudge(false)}>거절</Button>
            </Container>
        </>
    }
}

export default AdJudge;