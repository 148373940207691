import React from "react";

// reactstrap components
import {
  Input,
  Button,
  Badge,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
} from "reactstrap";
import {
  init,
  count,
  current,
  newAdClick,
  removeAdClick,
  resourceTypeSelect,
  bindUI,
  pageSelect,
  updateAd,
  getAdArray,
  getFileArray,
} from "controller/AdController.jsx";
import { upload } from "controller/AdInsertController.jsx";
import AdRemoveModal from "views/advertiser/AdRemoveModal.jsx";
import "assets/css/learnward.css";
import { resourceType } from "controller/AdController";
import { getVideoArray } from "controller/AdController";

class AdSimpleRegister extends React.Component {
  state = {
    count: count,
    current: current,
    formModal: false,
    resourceType: "image",
  };

  componentDidMount() {
    bindUI();
    init();
    this.setState({ count: 1, current: 0 });
  }

  newPage() {
    newAdClick();
    this.setState({
      count: count,
      current: current,
    });
  }

  removeRequest() {
    this.refs.removeModal.toggleModal();
  }

  removePage = () => {
    if (count < 2) return;
    var newCurrent = removeAdClick();
    this.setState({
      current: newCurrent,
      count: this.state.count - 1,
    });
  };

  uploadAdCall(campaign) {
    updateAd(this.state.current);
    upload(campaign, getAdArray(), getFileArray(), getVideoArray());
  }

  handlePageClick(page) {
    pageSelect(page);
    this.setState({
      current: page,
      resourceType: resourceType,
    });
  }

  imageError() {
    document.getElementById("image_preview").src =
      require("assets/img/theme/image_fallback.jpg").default;
  }

  media() {
    return (
      <Col className="sm-6" lg="6">
        <Badge className="mb-2" color="primary">
          광고 미디어 유형을 선택하세요.
        </Badge>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="inputGroupSelect01">
              미디어 유형
            </label>
          </div>
          <select
            id="resourceTypeSelect"
            className="custom-select"
            onChange={(e) => {
              resourceTypeSelect();
              this.setState({ resourceType: e.target.value });
            }}
          >
            <option value="image">Image</option>
            <option value="video">Video</option>
          </select>
        </div>
        <div id="resourceInput" className="custom-file col-sm">
          <Input
            accept={"image/jpeg"}
            type="file"
            id="ad-resource-input"
            name="filename"
          />
          <label className="custom-file-label" htmlFor="ad-resource-input">
            파일을 선택해주세요.
          </label>
        </div>
        <div
          className="ad-resource-container mb-2 bg-secondary"
          id="image_preview_container"
        >
          <img
            id="image_preview"
            src={require("assets/img/theme/image_fallback.jpg").default}
            className="ad-resource"
            alt="ad upload resource"
            onError={() => this.imageError()}
          />
        </div>
        <Col id="video_preview_container" hidden>
            <Input
              accept={"video/mp4"}
              type="file"
              id="ad-video-input"
              name="filename"
            />
          <div className="ad-resource-container ">
            <video id="video_preview" className="ad-resource" controls />
          </div>
        </Col>
      </Col>
    );
  }

  render() {
    return (
      <>
        <Col className="">
          <Card className="shadow border-0">
            <CardBody className="py-5">
              <Container className="">
                <Row>
                  {this.media()}
                  <Col lg="6">
                    <Badge className="mt-2 mb-2" color="primary">
                      광고 문구
                    </Badge>
                    <form className="was-validated mb-1">
                      <Input
                        type="textarea"
                        className="form-control is-invalid mb-2"
                        id="translation"
                        placeholder="문장 혹은 대화"
                        required
                      ></Input>
                    </form>
                    <Badge className="mt-2 mb-2" color="primary">
                      1인당 비용
                    </Badge>
                    <div className="was-validated input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">사용자 당</span>
                      </div>
                      <input
                        id="cpco"
                        type="number"
                        className="form-control"
                        min="1"
                        data-bind="value:replyNumber"
                        defaultValue="5"
                        aria-label="원"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">코인</span>
                      </div>
                      <div className="input-group-append">
                        <button
                          className="btn btn-secondary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="사용자 한 사람에게 사용되는 광고 비용입니다."
                        >
                          ?
                        </button>
                      </div>
                    </div>
                    <h5>
                      낮은 비용을 사용하시면 상황에 따라 광고가 노출되지 않을 수
                      있습니다.
                      <br />
                      광고비용이 늘어나면 추가적인 간접광고가 많아집니다.
                    </h5>
                  </Col>
                </Row>
                <div className="container row">
                  <div className="col-sm"></div>
                </div>
                <div className="container mt-3">
                  <nav aria-label="Page navigation example">
                    <ul
                      className="pagination-sm justify-content-center"
                      id="pagination"
                    ></ul>
                  </nav>
                </div>
                <Pagination
                  className="pagination justify-content-center mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  {[...Array(this.state.count)].map((page, i) => (
                    <PaginationItem active={i === this.state.current} key={i}>
                      <PaginationLink onClick={(e) => this.handlePageClick(i)}>
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <form className="text-right mb-2 ml-3">
                    <Button
                      className="btn btn-primary"
                      size="sm"
                      type="button"
                      onClick={() => this.removeRequest()}
                    >
                      삭제
                    </Button>
                    <Button
                      className="btn btn-primary"
                      size="sm"
                      type="button"
                      onClick={() => this.newPage()}
                    >
                      추가
                    </Button>
                  </form>
                </Pagination>
              </Container>
              <AdRemoveModal ref="removeModal" removeUpdate={this.removePage} />
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default AdSimpleRegister;
