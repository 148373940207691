import React from "react";

// reactstrap components
import { Col } from "reactstrap";
import AdEdit from "views/ad/AdEdit.jsx"
import CampaignEdit from "views/ad/CampaignEdit.jsx"
import AuthModal from 'views/auth/AuthModal.jsx'
import { isSignIn } from "network/Request";
import { request } from "network/Request.js"
import AdHeader from "components/Headers/AdHeader.jsx";
import Button from "reactstrap/lib/Button";

class AdminContentsEdit extends React.Component {
  state = {
    campaignId: null,
    impressionId: null,
    impressionState: 10,
    expired: false,
    adArray: null,
  };

  componentDidMount() {
    console.log("ad edit")
    var campaignId = this.getQueryVariable("campaign_id");
    var impressionState = this.getQueryVariable("impression_state");
    var impressionId = this.getQueryVariable("impression_id");
    var expired = this.getQueryVariable("expired");
    console.log(expired);
    this.setState({
      campaignId: campaignId,
      impressionState: impressionState,
      impressionId: impressionId,
      expired: expired,
    });
    this.requestAdList(campaignId);
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  edit = () => {
    this.refs.adEdit.uploadAdCall();
    console.log(this.state.adArray[0]["ad_english"])
    if (!isSignIn()) {
      this.refs.loginmodal.toggleModal();
      return;
    }
  }

  requestAdList(campaignId) {
    var data = {
      "bk-working": "advertiser-ad-list",
      "campaign_id": campaignId,
    }

    request(data, (result, res) => {
      console.log(res);
      if (result) {
        var array = res["list_array"];
        this.setState({
          adArray: array,
        })
      }
    });
  }

  render() {
    return (
      <>
        <AdHeader />
        <div>
          <Col className="mt--8" id="adregister_ad">
            {Number(this.state.impressionState) === 1 ?
              <Col className="ml-5 mb-3">
                <h3 className="display-10 text-white">준비가 완료되었습니다.</h3>
                <button type="button" className="btn btn-primary" onClick={() => this.release()}>광고 바로 시작</button>
              </Col>
              : Number(this.state.impressionState) > 1 ?
                <Col className="ml-5 mb-3">
                  <h3 className="display-10 text-white">광고가 준비중입니다.</h3>
                </Col>
                : Number(this.state.impressionState) < 0 ?
                  <h3 className="ml-5 display-10 text-white">광구자 중단되었습니다.</h3>
                  :
                  <h3 className="ml-5 display-10 text-white">광고가 정상적으로 운영되고 있습니다.</h3>
            }
            {this.state.adArray != null ?
              <div >
                <CampaignEdit
                  editable={true}
                  working="impression-info-admin"
                  expired={this.state.expired}
                  campaignId={this.state.campaignId} />
                <AdEdit
                  ads={this.state.adArray}
                  ref="adEdit"
                  editable={true}
                  campaignId={this.state.campaignId} >
                </AdEdit>
              </div>
              : ""}
          </Col>
          <Button onClick={() => this.edit()}>수정완료</Button>
        </div>
        <AuthModal ref="loginmodal" updateUser={this.props.updateUser} />
      </>
    );
  }
}

export default AdminContentsEdit;
