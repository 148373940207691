/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import AdHeader from "components/Headers/AdHeader.jsx";
import { request } from "network/Request.js";
import { navigation } from "components/Nav/Nav.jsx";
import dateFormat from "dateformat";
import { useNavigate } from "react-router";

const AdManage = () => {
  const [liveCount, setLiveCount] = useState(0);
  const [liveCurrent, setLiveCurrent] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [endCurrent, setEndCurrent] = useState(0);
  const [adList, setAdList] = useState([]);
  const [expireList, setExpireList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    requestLiveList(0);
    requestExpireList(0);
  }, []);

  const requestLiveList = (index) => {
    requestList("campaign-list", index, (res) => {
      setAdList(res["list_array"]);
      setLiveCount(res["ad_count"]);
    });
  }

  const requestExpireList = (index) => {
    requestList("campaign-expire-list", index, (res) => {
      console.log(res);
      setExpireList(res["list_array"]);
      setEndCount(res["ad_count"]);
    });
  }

  const requestList = (working, index, resultDelegate) => {
    request(
      {
        "bk-working": working,
        list_range: 5,
        list_offset: index * 5,
      },
      (result, res) => {
        if (result) {
          resultDelegate(res);
        } else {
          alert("네트워크 오류");
        }
      }
    );
  }

  const selectLiveCurrent = (index) => {
    setLiveCurrent(index);
    requestLiveList(index);
  };

  const selectEndCurrent = (index) => {
    setEndCurrent(index);
    requestExpireList(index);
  };

  const dateFormatting = (timeMillisec) => {
    console.log(timeMillisec);
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd");
  }

  const routeChange = (path) => {
    console.log(path);
    navigate(path);
  }

  return (
    <>
      <AdHeader />
      {/* Page content */}
      <Container className="mt--8" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">캠페인 목록</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">이름</th>
                    <th scope="col">노출수</th>
                    <th scope="col">클릭수</th>
                    <th scope="col">정답수</th>
                    <th scope="col">사용/제한</th>
                    <th scope="col">만료일</th>
                    <th scope="col">더보기</th>
                  </tr>
                </thead>
                <tbody>
                  {adList.map((page, i) => (
                    <tr key={i}>
                      <th scope="row">
                        <span className="mb-0 text-sm">
                          <Badge
                            color=""
                            className="badge-dot badge-lg mr-5 ml-2"
                          >
                            {page["impression_state"] === 0 ? (
                              <i className="bg-green" />
                            ) : page["impression_state"] === 4 ? (
                              <i className="bg-primary" />
                            ) : (
                              <i className="bg-warning" />
                            )}
                            {page["list_campaign_name"]}
                          </Badge>
                        </span>
                      </th>
                      <td>{page["list_view_count"]}</td>
                      <td>{page["list_click_count"]}</td>
                      <td>{page["list_answer_count"]}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">
                            {page["list_campaign_used"]}/
                            {page["list_campaign_limit"]}
                          </span>
                        </div>
                      </td>
                      <td>{dateFormatting(page["list_ad_expire"])}</td>
                      <td>
                        <Button
                          onClick={() =>
                            routeChange(
                              "adedit?campaign_id=" +
                              page["campaign_id"] +
                              "&impression_state=" +
                              page["impression_state"] +
                              "&impression_id=" +
                              page["impression_id"] +
                              "&expired=false"
                            )
                          }
                        >
                          보기
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {navigation(
                  selectLiveCurrent,
                  liveCurrent,
                  liveCount
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">중지된 캠페인</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">이름</th>
                    <th scope="col">노출수</th>
                    <th scope="col">클릭수</th>
                    <th scope="col">전환수</th>
                    <th scope="col">사용/제한</th>
                    <th scope="col">만료일</th>
                    <th scope="col">상태</th>
                  </tr>
                </thead>
                <tbody>
                  {expireList.map((page, i) => (
                    <tr key={i}>
                      <th scope="row">
                        <span className="mb-0 text-sm">
                          {page["list_campaign_name"]}
                        </span>
                      </th>
                      <td>{page["list_view_count"]}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          {page["list_click_count"]}
                        </Badge>
                      </td>
                      <td></td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">
                            {page["list_campaign_used"]}/
                            {page["list_campaign_limit"]}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            routeChange(
                              "adedit?campaign_id=" +
                              page["campaign_id"] +
                              "&impression_state=" +
                              page["impression_state"] +
                              "&impression_id=" +
                              page["impression_id"] +
                              "&expired=true"
                            )
                          }
                        >
                          보기
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="bg-default py-4">
                {navigation(
                  selectEndCurrent,
                  endCurrent,
                  endCount
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AdManage;
