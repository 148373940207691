/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Input,
  Col,
  Badge,
  Collapse,
} from "reactstrap";
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko)

class EventCampaignRegister extends React.Component {
  state = {
    "remainedPoint": 0,
    "expire": new Date(),
    isOpen: false,
    genderSelect: "all",
    minOpen:false,
    minBirth: new Date(),
    maxOpen:false,
    maxBirth: new Date(),
  }

  target() {
    const toggle = () => this.setState({ isOpen: !this.state.isOpen });

    return (
      <div className="mt-3" >
        <Badge className="bg-primary">
          세부설정(선택사항)
                </Badge>
        <Button className="mt-3 ml-3" size="sm" color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}>펼치기</Button>
        <Collapse isOpen={this.state.isOpen}>
          <Card>
            <CardBody>
              <Badge className="bg-primary mt-3">
                이벤트 광고는 타켓팅을 지원하지 않습니다.
                </Badge>
              <br />
              <Collapse isOpen={this.state.maxOpen}>

                <DatePicker
                  locale="ko"
                  showYearDropdown
                  className="mt-2"
                  selected={this.state.maxBirth}
                  onChange={(date) => this.setState({ maxBirth: date })}
                />
                </Collapse>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }

  getValueIfNotNull(field) {
    var obj = document.getElementById(field);
    if (obj !== null) {
      return obj.value;
    }
    return null;
  }

  getCampaign() {
    var cpa = this.getValueIfNotNull('cpa');
    if (!cpa)
      cpa = 0;
    var actionSelect = document.getElementById('actionSelect');
    var action = null;
    if (actionSelect !== null)
      action = actionSelect.options[actionSelect.selectedIndex].value;
    if (!action)
      action = "no";
      
    var name = this.getValueIfNotNull('campaign_name');
    var url = this.getValueIfNotNull('campaign_url');
    var expire = this.state.expire.getTime();

    return {
      "action_reward": cpa,
      "action_type": action,
      "impression_limit": 10000,
      "campaign_name": name,
      "campaign_type": "translate",
      "campaign_url": url,
      "campaign_expire": expire,
      "total_gender" : this.state.genderSelect === "all",
      "gender" : this.state.genderSelect === "m" ? 1 : 2,
      "use_min_age" : this.state.minOpen,
      "min_age" : this.state.minBirth.getTime(),
      "use_max_age" : this.state.maxOpen,
      "max_age" : this.state.maxBirth.getTime(),
    };
  }

  handleChange = date => {
    this.setState({
      expire: date
    });
  };

  componentDidMount() {
  }

  campaignTypeSelect() {

  }

  render() {
    return (
      <>
        <Col className="">
          <Card className="mb--5">
            <CardBody className="mb">
              <Badge className="bg-primary">
                캠페인 이름 (광고 이름.)
                </Badge>
                <form className="was-validated mb-1">
                <Input placeholder="(예: 공부하고 리워드받는 런워드!)" className="mt-2 form-control is-invalid" id="campaign_name" maxLength={40} required></Input>
                </form>
              <div className="mb-4 mt-2">
                <Badge className="bg-primary mt-2">
                  연결할 url
                </Badge>
                <Input className="mt-2" id="campaign_url" placeholder="http 혹은 https를 포함한 전체 주소"></Input>
                <Badge className="bg-primary mt-3">
                  만료일
                </Badge>
                <br />
                <DatePicker
                  className="mt-2"
                  selected={this.state.expire}
                  onChange={this.handleChange}
                />
                <br />
                <br />
                <h5>이벤트광고는 타겟팅을 지원하지 않습니다.</h5>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default EventCampaignRegister;
