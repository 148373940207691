/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

function IndexHeader() {
  return (
    <>
      <div className="header bg-info pt-5 pb-7">
        <Container>
          <div className="header-body">
            <Row className="align-items-center">
              <Col lg="6">
                <div className="pr-5">
                  <h1 style={{fontFamily: "paybooc"}} className="display-2 text-white font-weight-bold mb-0">
                    자본주의가 낳은 영어공부 런워드
                  </h1>
                  <br/>
                  <h2 style={{fontFamily: "paybooc"}} className="display-4 text-white font-weight-light">
                    런워드에서 문장으로 영어를 배워보세요. 영어실력이 자연스럽게 늘어나요.
                  </h2>
                  <p className="text-white mt-4">
                    학습하고 열심히 복습 하셨다면 미션에 참가해보세요.
                    복습을 꾸준히 하여 추가 포인트를 얻고 선착순 미션에 도전해보세요. 시험에 우승하면 엄청난 포인트를 얻을 수 있어요. (비보상형 플랫폼은 미션이 제공되지 않습니다.)
                  </p>
                  <div className="mt-5">
                    <Button
                      className="btn-neutral my-2"
                      color="default"
                      href="https://learnward.page.link/N8fh"
                      >
                      스토어에서 앱 다운로드
                    </Button>
                    <Button
                      className="my-2"
                      color="default"
                      href="/advertiser/index"
                    >
                      런워드에 광고하기
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <Row className="pt-5">
                  <Col md="6">
                    <Card>
                      <CardBody>
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                          {/* <i className="ni ni-active-40" /> */}
                          <img alt="learn" src={require("assets/img/media/study128.png")} width="25" />
                        </div>
                        <h5 className="h3">학습하기</h5>
                        <p>전문 선생님이 제작한 콘텐츠를 학습해보세요. 모두 무료!</p>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                        <img alt="review" src={require("assets/img/media/review128.png")} width="25" />
                        </div>
                        <h5 className="h3">복습하기</h5>
                        <p>
                          작문하기, 따라 말하기, 랜덤퀴즈 등의 다양한 복습기능을 제공해요.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="pt-lg-5 pt-4" md="6">
                    <Card className="mb-4">
                      <CardBody>
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                        <img alt="mission" src={require("assets/img/media/thunderbolt128.png")} width="25" />
                        </div>
                        <h5 className="h3">다양한 미션</h5>
                        <p>
                          매일매일 스피드퀴즈, 선착순 미션에 참여해서 포인트를 얻어가세요.
                        </p>
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <CardBody>
                        <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                        <img alt="exam" src={require("assets/img/media/scholar128.png")} width="25" />
                        </div>
                        <h5 className="h3">시험</h5>
                        <p>시험에서 살아남으면 초대박 포인트를 얻을 수 있어요.</p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
