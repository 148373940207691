import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
// core components
import AdvertiserHeader from "components/Headers/AdHeader.jsx";
import { request } from "network/Request";

const ChargeComplete = () => {
  const [chargeState, setChargeState] = useState("loading");
  const [chargedCoin, setChargedCoin] = useState("loading");
  const params = new URLSearchParams(window.location.search) // id=123

  useEffect(() => {
    request({
      "bk-working": "payment-toss",
      "order_id": params.get("orderId"),
      "payment_key": params.get("paymentKey"),
      "amount": params.get("amount")
    }, (result, response) => {
      console.log(response);
      if (result) {
        setChargeState("complete");
        setChargedCoin(response["coin"]);
      }
    })
  }, []);
  
  return (
    <>
      <AdvertiserHeader />
      {/* Page content */}
      <Container className="mt--3" fluid>
        <Row className="justify-content-center">
          <Col lg="10">
            <div className="pricing card-group flex-column flex-md-row mb-3">
              <Card className="card-pricing border-0 text-center mb-4">
                <CardHeader className="bg-transparent">
                  {chargeState == "loading" &&
                    <>완료 중입니다.</>
                  }
                  {chargeState == "complete" &&
                    <>{chargedCoin} 코인이 충전되었습니다.</>
                  }
                </CardHeader>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChargeComplete;
