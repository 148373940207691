/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  Table,
  Row
} from "reactstrap";
// core components
import { request } from "network/Request.js"
import {navigation} from "components/Nav/Nav.jsx"
import dateFormat from "dateformat";

class NoticeList extends React.Component {
  state = {
    noticeCount: 0,
    noticeCurrent: 0,
    noticeList: [],
  };

  componentDidMount() {
    this.requestNoticeList(0);
  }

  redirectNotice(noticeId) {
    this.props.history.push("notice?notice_id=" + noticeId);
  }

  requestNoticeList(index) {
    this.requestList(index, (res) => {
        console.log(res);
      this.setState({
        noticeList: res["notice_array"],
        noticeCount: res["notice_size"]
      })
    })
  }

  requestList(index, resultDelegate) {
    request({
      "bk-working": "notice-list-total",
      "notice_offset": index * 10
    }, (result, res) => {
      if (result) {
        resultDelegate(res);
      } else {
        alert("네트워크 오류")
      }
    })
  }

  selectNoticeCurrent = (index) => {
    this.setState({ noticeCurrent: index })
    this.requestNoticeList(index);
  }

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd")
  }

  render() {
    return (
      <>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">제목</th>
                      <th scope="col">작성일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.noticeList.map((page, i) => (
                      <tr key={i} onClick={() => this.redirectNotice(page["notice_id"])}>
                        <td size="lg">{page["notice_subject"]}</td>
                        <td>
                          {this.dateFormatting(page["notice_time"])}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {navigation(this.selectNoticeCurrent, this.state.noticeCurrent, this.state.noticeCount, 10)}
                </CardFooter>
              </Card>
            </div>
          </Row>
          {/* Dark table */}
      </>
    );
  }
}

export default NoticeList;
