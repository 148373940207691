/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdvertiserNavbar from "components/Navbars/AdvertiserNavbar.js";
import CommonFooter from "components/Footers/CommonFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { isSignIn, request } from "network/Request.js"

import AdvertiserRoutes from "AdvertiserRoutes.js";
import RequestAdvertiser from "views/advertiser/RequestAdvertiser";
import ChatService from "views/service/ChatService.jsx"
import { analytics } from "views/service/Firebase";
import AdManage from "views/advertiser/AdManage";

const TestRoute = () => {
  const location = useLocation();

  return <div>{location.pathname}</div>
}

class Advertiser extends React.Component {
  state = {
    advertiser: false,
    sideNavOpen: true,
    loading: true,
    notiList: [],
    notiSize: 0,
  }

  constructor(props) {
    super(props);
    this.mainContent = React.createRef();
  }

  componentDidMount() {
    this.requestIsAdvertiser();
    this.requestNotiList();
    ChatService.boot({
      "pluginKey": "8f0ac675-bb2b-4371-bac5-e5f3cdc57212" //please fill with your plugin key
    });
    analytics.logEvent("advertiser-visit");
  }

  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({ sideNavOpen: !this.state.sideNavOpen });
  };

  closeSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({ sideNavOpen: false });
  }

  getNavbarTheme = () => {
    return "dark";
  };

  scrollToTop = () => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.mainContent.current.scrollTop = 0;
  }

  signed = () => {
    return isSignIn();
  }

  requestIsAdvertiser() {
    let data = {
      "bk-working": "is-advertiser",
    }

    request(data, ((result, res) => {
      console.log(res);
      this.setState({
        advertiser: result
      })
    }));
  }

  requestNotiList() {
    let data = {
      "bk-working": "noti-list",
      "noti_type": "advertiser",
      "limit_offset": 0,
      "limit_range": 6,
    }

    request(data, ((result, res) => {
      console.log(res);
      if (result) {
        this.setState({
          notiList: res["limit_array"],
          notiSize: res["noti_size"],
        })
      }
    }));
  }

  updateUserState = () => {
    this.refs.sidebar.updateUserState();
  }

  getRoutes = AdvertiserRoutes => {
    return AdvertiserRoutes.map((prop, key) => {
      if (prop.layout === "/advertiser") {
        return (
          this.signed() ?
            <Route
              element={<prop.component updateUser={this.updateUserState} />}
              path={prop.path + "/*"}
              key={key}
            /> :
            <Route path="*" element={<Navigate to={"/auth/login?dest=" + prop.path + "&layout=" + prop.layout} />} key={key} />
        );
      } else {
        return <Route key={key} element={<div>avoai</div>}></Route>;
      }
    });
  };

  getBrandText = () => {
    for (let i = 0; i < AdvertiserRoutes.length; i++) {
      return AdvertiserRoutes[i].name;
    }
    return "Brand";
  };

  render() {
    return (
      <>
        <Sidebar
          scrollToTop={this.scrollToTop}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sideNavOpen}
          {...this.props}
          routes={AdvertiserRoutes}
          logo={{
            innerLink: "/advertiser/index",
            imgSrc: require("assets/img/brand/learnward.png"),
            imgAlt: "..."
          }}
          pageTitle="광고주"
        >런워드</Sidebar>
        <div className="main-content" ref={this.mainContent}>
          <AdvertiserNavbar
            notiSize={this.state.notiSize}
            notiList={this.state.notiList}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sideNavOpen}
            brandText={this.getBrandText()}
          />
          {this.state.advertiser || !this.signed() ?
            <Routes>{this.getRoutes(AdvertiserRoutes)}</Routes>
            : <RequestAdvertiser className="main-content" />}
          <Container fluid>
            <CommonFooter />
          </Container>
        </div>
        {this.state.sideNavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.closeSidenav} />
        ) : null}
      </>
    );
  }
}

export default Advertiser;
