/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Translation from "views/partner/Translation.jsx";
import Record from "views/partner/Record.jsx";
import Partner from "views/partner/Partner.jsx";
 
var PartnerRoutes = [
  {
    path: "/index",
    name: "파트너",
    icon: "ni ni-circle-08 text-primary",
    destlayout: "partner",
    component: Partner,
    layout: "/partner"
  },
  {
    path: "/translation",
    name: "번역",
    icon: "ni ni-world-2 text-primary",
    destlayout: "partner",
    component: Translation,
    layout: "/partner"
  },
  {
    path: "/record",
    name: "녹음",
    icon: "ni ni-headphones text-primary",
    destlayout: "partner",
    component: Record,
    layout: "/partner"
  }
];
export default PartnerRoutes;
