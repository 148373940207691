/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import {
    Modal,
    Button
} from "reactstrap";

class AdRemoveModal extends React.Component {
    state = { formModal: false };

    toggleModal = () => {
        this.setState({
            formModal: !this.state.formModal
        });
    };
    componentDidMount() {
    }

    removeAd() {
        this.props.removeUpdate();
        this.toggleModal();
    }

    render() {
        return (
            <>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modal-dialog-centered w-00"
                    isOpen={this.state.formModal}
                    toggle={() => this.toggleModal()}
                >
                    <h5>삭제 하시겠습니까?</h5>
                    <Button
                        onClick={() => this.toggleModal()}>아니오</Button>
                    <Button
                        onClick={() => this.removeAd()}>
                        네
                    </Button>
                </Modal>
            </>
        );
    }
}

export default AdRemoveModal;