/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { request, requestFile } from "network/Request.js"

class RequestPartner extends React.Component {
  state = {
    partner: false,
    partnerType: "trans",
    termCheck: false,
  }

  termCheck(check) {
    this.setState({termCheck : check});
  }

  bindFile(file) {
    this.selectedFile = file;
  }

  sendRequest() {
    let job = document.getElementById("job").value;

    if (!job || job === "") {
      alert("직업을 입력해주세요.");
      return;
    }
    if (!this.selectedFile) {
      alert("파일이 없습니다.");
      return;
    }
    let data = {
      "bk-working": "request-partner",
      "partner_job": job,
      "partner_type": this.state.partnerType
    };
    requestFile(data, [this.selectedFile], (result, res) => {
      if (res["bk-result"] === true)
        alert("등록 성공");
      else {
        console.log(res)
        alert("등록 실패:" + res["result_detail"]);
      }
    });
  }

  componentDidMount() {
    request({
      "bk-working": "verify-partner"
    }, (result, res) => {
      if (result) {
      } else {
        console.log("파트너 인증 실패")
      }
    })
  }

  handleRadio(event) {
    this.setState({
      partnerType: event.target.value
    });
  }

  render() {
    return (
      <>
        <section className="section section-lg">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("assets/img/theme/promo-1.png")}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                    <i className="ni ni-check-bold" />
                  </div>
                  <h3>파트너로 지원해주세요</h3>
                  <p>
                    런워드 서비스로 사용자들에게 유익한 컨텐츠를 전달하고 싶어요.
                    <br />
                    저희는 광고를 유익한 콘텐츠로 만들어줄 파트너들이 필요해요.
                    </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-settings-gear-65" />
                          </Badge>
                        </div>
                        <div>
                          <h5 className="mb-0">
                            영어 교사나 강사
                            </h5>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h5 className="mb-0">영어 유튜버</h5>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h5 className="mb-0">
                            원어민 혹은 원어민 수준의 발음을 보유하신 분
                            </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section-contact-us">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Card className="bg-gradient-secondary shadow">
                  <CardBody className="p-lg-5">
                    <h4 className="mb-1">양식을 채워 신청하기 버튼을 눌러주세요.</h4>
                    <p className="mt-0">
                      영어 교사, 강사, 유튜버 등 올바른 영어 콘텐츠를 만들 수 있는 자격을 증명해주세요.
                      정해진 양식은 없습니다. 신분을 확인 할 수 있는 사진을 보내주세요. (학원사진x, 교재사진x, 기타 본인임을 확인 할 수 없는 자료x)
                        <br />
                    </p>
                    <h3 className="mt-2">파트너 타입을 골라주세요. </h3>
                    <div className="custom-control custom-radio mb-2">
                      <input
                        onChange={(event) => this.handleRadio(event)}
                        defaultChecked
                        className="custom-control-input"
                        id="translation_check"
                        type="radio"
                        name="custom-radio"
                        value="trans"
                      />
                      <label className="custom-control-label" htmlFor="translation_check">
                        번역
                      </label>
                    </div>
                    <div className="custom-control custom-radio mb-1">
                      <input
                        disabled
                        onChange={(event) => this.handleRadio(event)}
                        className="custom-control-input"
                        id="record_check"
                        type="radio"
                        name="custom-radio"
                        value="record"
                      />
                      <label className="custom-control-label" htmlFor="record_check">
                        녹음
                      </label>
                    </div>
                    <FormGroup
                      className={classnames("mt-4", {
                        focused: this.state.nameFocused
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                          <InputGroupText>
                            <i className="ni ni-user-run" />
                          </InputGroupText>
                        <Input
                          id="job"
                          placeholder="직업"
                          type="text"
                          onFocus={e => this.setState({ nameFocused: true })}
                          onBlur={e => this.setState({ nameFocused: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div id="resourceInput" className="custom-file col-sm">
                        <Input type="file"
                          className="custom-file-input" id="ad-resource-input" name="filename"
                          onChange={(e) => this.bindFile(e.target.files[0])}
                        />
                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                      </div>

                      <Button size="sm" className="mt-3" onClick={() => window.open("http://terms.learnward.com/partner_term.txt")}>
                        여기를 눌러 파트너 약관을 보고 동의해주세요.
                      </Button>

                      <div className="mt-1 custom-control custom-checkbox">
                        <input
                          onChange={(e) => this.termCheck(e.target.checked)}
                          className="custom-control-input"
                          id="term_check"
                          type="checkbox"
                        />
                        <label className="custom-control-label" htmlFor="term_check">
                          파트너 약관을 충분히 숙지하였으며 약관에 동의합니다.
                      </label>
                      </div>
                    </FormGroup>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="btn-round"
                        color="default"
                        size="lg"
                        type="button"
                        onClick={() => this.state.termCheck ? this.sendRequest() : alert("약관에 먼저 동의해주세요.")}
                      >
                        신청하기
                        </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default RequestPartner;