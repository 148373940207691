import React from "react";

// reactstrap components
import { Col } from "reactstrap";
import AdEdit from "views/ad/AdEdit.jsx"
import CampaignEdit from "views/ad/CampaignEdit.jsx"
import AuthModal from 'views/auth/AuthModal.jsx'
import { isSignIn } from "network/Request";
import { request } from "network/Request.js"
import AdHeader from "components/Headers/AdHeader.jsx";

class AdvertiserAdEdit extends React.Component {
  state = {
    campaignId: null,
    impressionId: null,
    impressionState: 10,
    adArray: null,
  };

  componentDidMount() {
    var campaignId = this.getQueryVariable("campaign_id");
    var impressionState = this.getQueryVariable("impression_state");
    var impressionId = this.getQueryVariable("impression_id");
    var expired = this.getQueryVariable("expired");
    this.setState({
      campaignId: campaignId,
      impressionState: impressionState,
      impressionId: impressionId,
      expired: expired
    });
    this.requestAdList(campaignId);
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  release() {
    var data = {
      "bk-working": "release-impression",
      "campaign_id": this.state.campaignId,
      "impression_id": this.state.impressionId,
    }

    request(data, (result, res) => {
      if (result) {
        alert("광고가 시작됩니다.");
      } else {
        alert("네트워크 오류");
        console.log(res);
      }
    })
  }

  suspend() {
    var data = {
      "bk-working": "suspend-contents",
      "campaign_id": this.state.campaignId,
      "impression_id": this.state.impressionId,
    }

    request(data, (result, res) => {
      if (result) {
        alert("광고가 중단됩니다.");
      } else {
        alert("네트워크 오류");
        console.log(res);
      }
    })
  }

  resume() {
    var data = {
      "bk-working": "resume-contents",
      "campaign_id": this.state.campaignId,
      "impression_id": this.state.impressionId,
    }

    request(data, (result, res) => {
      if (result) {
        alert("광고가 재개됩니다.");
      } else {
        alert("네트워크 오류");
        console.log(res);
      }
    })
  }

  submit = () => {
    if (!isSignIn()) {
      this.refs.loginmodal.toggleModal();
      return;
    }
  }

  requestAdList(campaignId) {
    var data = {
      "bk-working": "advertiser-ad-list",
      "campaign_id": campaignId
    }

    request(data, (result, res) => {
      if (result) {
        var array = res["list_array"];
        this.setState({
          adArray: array,
        })
      }
    });
  }

  render() {
    return (
      <>
        <AdHeader />
        <div>
          <Col className="mt--8" id="adregister_ad">
            {Number(this.state.impressionState) === 4 ?
              <Col className="mb-3">
                <h3 className="display-10 text-white">준비가 완료되었습니다.</h3>
                <button type="button" className="btn btn-primary" onClick={() => this.release()}>광고 바로 시작</button>
              </Col>
              : Number(this.state.impressionState) > 4 ?
                <Col className="mb-3">
                  <h3 className="display-10 text-white">콘텐츠가 제작중입니다.</h3>
                </Col>
                : Number(this.state.impressionState) === 1 ?
                <Col className="mb-3">
                <h3 className="display-10 text-white">광고가 중단되었습니다.</h3>
                <button type="button" className="btn btn-primary" onClick={() => this.resume()}>광고 재개</button>
                </Col>
                : Number(this.state.impressionState) === -10 ?
                <Col className="mb-3">
                <h3 className="display-10 text-white">광고가 거절되었습니다.</h3>
                </Col>
                  :
                  <Col className="mb-3">
                    <h3 className="display-10 text-white">광고가 운영중입니다.</h3>
                    <button type="button" className="btn btn-primary" onClick={() => this.suspend()}>광고 중단</button>
                  </Col>
            }
            {this.state.adArray != null ?
              <div >
                <CampaignEdit
                expired={this.state.expired}
                working="impression-info"
                campaignId={this.state.campaignId} />
              <AdEdit
                ads={this.state.adArray}
                ref="adEdit"
                editable={false}
                campaignId={this.state.campaignId} >
              </AdEdit>
              </div>
              : ""}
          </Col>
        </div>
        {/* <div id="adregister_campaign">
          <CampaignRegister />
        </div> */}
        <AuthModal ref="loginmodal" updateUser={this.props.updateUser} />
      </>
    );
  }
}

export default AdvertiserAdEdit;
