import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import Header from "components/Headers/UserHeader.jsx";
import { requestAdvertiser, resizeImage } from "controller/UserController.jsx";
import Avatar from "react-avatar";
import { requestRectangleFile } from "network/Request";

class Advertiser extends React.Component {
  inputFile = React.createRef();
  state = {
    userJson: {}
  }

  componentDidMount() {
    requestAdvertiser((json) => this.requestResult(json));
  }

  requestResult(json) {
    console.log(json);
    this.setState({ userJson: json });
  }

  onButtonClick = () => {
    // `current` points to the mounted file input element
    this.inputFile.current.click();
  };

  onChange = (e) => {
    var inputFile = e.target.files[0];
    resizeImage(inputFile).then((value) => {
      requestRectangleFile({
        "bk-working": "register-user-image"
      }, [value], (result, response) => {
        alert(result);
        1((json) => this.requestResult(json));
      });  
    });
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-3 mb-xl-0" xl="12">
              <Card className="card-profile shadow">
                <CardBody className="pt-3 pt-md-4">
                  <div className="text-center">
                    <input type='file' onChange={this.onChange} id='file' ref={this.inputFile} style={{ display: 'none' }} />
                    <Avatar size="100" onClick={this.onButtonClick} round src={this.state.userJson["user_image"]} />
                    <h3>
                      {this.state.userJson["user_nickname"]}
                      <span className="font-weight-light"></span>
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {this.state.userJson["user_email"]}
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      보유 코인: {this.state.userJson["coin"]}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
            </Col>
          </Row>
        </Container>
        <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">정보 변경</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="lucky.jesse"
                              id="input-username"
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="jesse@example.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Lucky"
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Jesse"
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
      </>
    );
  }
}

export default Advertiser;
