import React from "react";

// reactstrap components
import AdFullRegister from "views/advertiser/AdFullRegister.jsx"
import CampaignRegister from "views/advertiser/CampaignRegister.jsx"
import AuthModal from 'views/auth/AuthModal.jsx'
import { isSignIn } from "network/Request";
import AdHeader from "components/Headers/AdHeader.jsx";

class ContentsRegister extends React.Component {
  componentDidMount() {
  }

  submit = () => {
    if (!isSignIn()) {
      this.refs.loginmodal.toggleModal();
      return;
    }
    var campaign = this.refs.campaignregister.getCampaign();
    this.refs.adregister.uploadAdCall(campaign, true);
  }

  render() {
    return (
      <>
        <div className="">
          <AdHeader />
          <div className="mt--8" id="adregister_campaign">
            <CampaignRegister ref="campaignregister" />
          </div>
          <div id="adregister_ad">
            <AdFullRegister ref="adregister" />
          </div>
          <div className="mt-1 text-center">
            <button type="button" className="btn btn-primary" onClick={() => this.submit()}>제출</button>
          </div>
          <AuthModal ref="loginmodal" updateUser={this.props.updateUser} />
        </div>
      </>
    );
  }
}

export default ContentsRegister;
