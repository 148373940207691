/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import PartnerNavbar from "components/Navbars/PartnerNavBar.jsx";
import CommonFooter from "components/Footers/CommonFooter.jsx";
import { isSignIn, request } from "network/Request.js"
import Sidebar from "components/Sidebar/Sidebar.jsx";

import PartnerRoutes from "PartnerRoutes.js";
import RequestPartner from "views/partner/RequestPartner.jsx";

class Partner extends React.Component {
  state = {
    partner: false,
    sideNavOpen: true,
  }

  constructor(props) {
    super(props);
    this.mainContent = React.createRef();
  }

  componentDidMount() {
    console.log(this.signed());
    this.requestIsPartner();
  }

  getNavbarTheme = () => {
    return "dark";
  };

  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({ sideNavOpen: !this.state.sideNavOpen });
  };

  closeSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({ sideNavOpen: false });
  }

  scrollToTop = () => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.mainContent.current.scrollTop = 0;
  }

  signed = () => {
    return isSignIn();
  }

  requestIsPartner() {
    request({
      "bk-working": "verify-partner"
    }, (result, res) => {
      if (result) {
        this.setState({ partner: true })
      } else {
        console.log("네트워크 오류")
      }
    });
  }

  updateUserState = () => {
    this.refs.sidebar.updateUserState();
  }

  getRoutes = PartnerRoutes => {
    return PartnerRoutes.map((prop, key) => {
      if (prop.layout === "/partner") {
        return (
          this.signed() ?
            <Route
              element={<prop.component updateUser={this.updateUserState} />}
              path={prop.layout + prop.path}
              key={key}
            /> :
            <Route path={prop.layout + prop.path} element={<Navigate to={"/auth/login?dest=" + prop.path + "&layout=" + prop.layout} key={key} />} />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < PartnerRoutes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          PartnerRoutes[i].layout + PartnerRoutes[i].path
        ) !== -1
      ) {
        return PartnerRoutes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          scrollToTop={this.scrollToTop}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sideNavOpen}
          {...this.props}
          routes={PartnerRoutes}
          logo={{
            innerLink: "/partner/index",
            imgSrc: require("assets/img/brand/learnward.png"),
            imgAlt: "..."
          }}
          pageTitle="파트너"
        />
        <div className="main-content" ref={this.mainContent}>
          <PartnerNavbar
            {...this.props}
            notiSize={this.state.notiSize}
            notiList={this.state.notiList}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sideNavOpen}
            brandText={this.getBrandText(window.location.pathname)}
          />
          {
            this.state.partner || !this.signed() ?
              <Routes>{this.getRoutes(PartnerRoutes)}</Routes>
              : <RequestPartner />
          }
          <Container fluid>
            <CommonFooter />
          </Container>
          {this.state.sideNavOpen ? (
            <div className="backdrop d-xl-none" onClick={this.closeSidenav} />
          ) : null}
        </div>

      </>
    );
  }
}

export default Partner;
