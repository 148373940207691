import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';        // for authentication
import 'firebase/compat/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyD_xGaoeWeTCnW0vUoIcjfBkUKgHigb9bU",
    authDomain: "learnward.firebaseapp.com",
    databaseURL: "https://learnward.firebaseio.com",
    projectId: "learnward",
    storageBucket: "learnward.appspot.com",
    messagingSenderId: "394524003984",
    appId: "1:394524003984:web:b9d514d26432adb6e45215",
    measurementId: "G-L0S8HH1K5B"
}

export var fb = firebase.initializeApp(firebaseConfig);
export var analytics = firebase.analytics();

export function googleAuth() {
    firebase.auth().signOut();
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    return firebase.auth().signInWithPopup(provider);
}

export function appleAuth() {
  firebase.auth().signOut();
  var provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  return firebase.auth().signInWithPopup(provider);
}

export function getIdToken() {
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        return idToken;
      }).catch(function(error) {
        // Handle error
      });
      return null;
}