/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row
} from "reactstrap";
// core components
import AdHeader from "components/Headers/AdHeader.jsx";
import { request } from "network/Request.js"
import {navigation} from "components/Nav/Nav.jsx"
import dateFormat from "dateformat";

class ChargedList extends React.Component {
  state = {
    chargedCount: 0,
    chargedCurrent: 0,
    chargedList: [],
  };

  componentDidMount() {
    this.requestChargedList(0);
  }

  requestChargedList(index) {
    this.requestList("charged-list", index, (res) => {
      console.log(res);
      this.setState({
        chargedList: res["limit_array"],
        chargedCount: res["charged_list_size"]
      })
    })
  }

  requestList(working, index, resultDelegate) {
    request({
      "bk-working": working,
      "limit_range": 10,
      "limit_offset": index * 10
    }, (result, res) => {
      if (result) {
        resultDelegate(res);
      } else {
        alert("네트워크 오류")
      }
    })
  }

  selectChargedCurrent = (index) => {
    this.setState({ chargedCurrent: index })
    this.requestChargedList(index);
  }

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd")
  }
  
  routeChange(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <>
        <AdHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">충전 내역</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">아이디</th>
                      <th scope="col">주문번호</th>
                      <th scope="col">코인</th>
                      <th scope="col">가격</th>
                      <th scope="col">충전방법</th>
                      <th scope="col">날짜</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.chargedList.map((page, i) => (
                      <tr key={i}>                        
                        <td>{page["charge_id"]}</td>
                        <td>{page["charge_external_id"]}</td>
                        <td>
                            {page["coin"]}
                        </td>
                        <td>
                            {page["price"]}
                        </td>
                        <td>
                            {page["charge_from"]}
                        </td>
                        <td>{new Date(page["time_millisec"]).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {navigation(this.selectChargedCurrent, this.state.chargedCurrent, this.state.chargedCount, 10)}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ChargedList;
