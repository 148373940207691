/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Table,
  CardFooter,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import PartnerHeader from "components/Headers/PartnerHeader.jsx";
import { request } from "network/Request.js"
import { navigation } from "components/Nav/Nav.jsx"

import dateFormat from "dateformat";

class Parter extends React.Component {
  state = {
    translationCount: 0,
    translationCurrent: 0,
    translationList: [],
    recordCount: 0,
    recordCurrent: 0,
    recordList: [],
    dashboard: {},
  };

  componentDidMount() {
    this.requestTranslationHistory(0);
    this.requestRecordHistory(0);
    this.requestPartnerInfo();
  }

  requestPartnerInfo() {
    request({ "bk-working": "partner-dashboard" }, (result, res) => {
      if (result) {
        this.setState({
          dashboard : res["partner_json"],
        })
      }
    });
  }
  
  selectTranslationCurrent = (index) => {
    this.setState({ translationCurrent: index })
    this.requestTranslationHistory(index);
  }

  selectRecordCurrent = (index) => {
    this.setState({ recordCurrent: index })
    this.requestRecordHistory(index);
  }

  requestTranslationHistory(index) {
    this.requestList("list-translation", index, (res) => {
      this.setState({
        translationCount : res["list_size"],
        translationList : res["translation_array"]
      })
    });
  }

  requestRecordHistory(index) {
    this.requestList("list-record", index, (res) => {
      this.setState({
        recordCount : res["list_size"],
        recordList : res["record_array"]
      })
    });
  }

  requestList(working, index, resultDelegate) {
    request({
      "bk-working": working,
      "list_range": 5,
      "list_offset": index * 5
    }, (result, res) => {
      if (result) {
        resultDelegate(res);
      } else {
        alert("네트워크 오류")
      }
    })
  }

  calculateFirst(current, count) {
    return (count <= 7 ? 0 :
      current + 7 > count ? count - 7 :
        current)
  }

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd")
  }

  translationPagination() {
    return this.state.translationCount > 0 ? <div className="col">
      <Card className="shadow">
        <CardHeader className="border-0">
          <h3 className="mb-0">번역 목록</h3>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">영어</th>
              <th scope="col">시간</th>
              <th scope="col">포인트</th>
              <th scope="col">승인</th>
            </tr>
          </thead>
          <tbody>
            {this.state.translationList.map((page, i) => (
              <tr key={i}>
                <th scope="row">
                  <span className="mb-0 text-sm">
                    {page["ad_english"]}
                  </span>
                </th>
                <td>
                {this.dateFormatting(page["translation_time"])}
                </td>
                <td>
                  {page["point"]}
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{page["translation_state"]}</span>
                  </div>
                </td>

              </tr>
            ))}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          {navigation(this.selectTranslationCurrent, this.state.translationCurrent, this.state.translationCount)}
    </CardFooter>
      </Card>
    </div> : "";
  }

  recordPagination() {
    return this.state.recordCount > 0 ? <div className="col mt-3">
      <Card className="shadow">
        <CardHeader className="border-0">
          <h3 className="mb-0">녹음 목록</h3>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">영어</th>
              <th scope="col">시간</th>
              <th scope="col">포인트</th>
              <th scope="col">승인</th>
            </tr>
          </thead>
          <tbody>
            {this.state.recordList.map((page, i) => (
              <tr key={i}>
                <th scope="row">
                  <span className="mb-0 text-sm">
                    {page["ad_english"]}
                  </span>
                </th>
                <td>
                {this.dateFormatting(page["translation_time"])}
                </td>
                <td>
                  {page["point"]}
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{page["translation_state"]}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          {navigation(this.selectRecordCurrent, this.state.recordCurrent, this.state.recordCount)}
        </CardFooter>
      </Card>
    </div>
    : "";
  }

  render() {
    return (
      <>
        <PartnerHeader />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("assets/img/theme/account.png")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">{this.state.dashboard["pending_count"]}</span>
                          <span className="description">대기</span>
                        </div>
                        <div>
                          <span className="heading">{this.state.dashboard["accept_count"]}</span>
                          <span className="description">승인</span>
                        </div>
                        <div>
                          <span className="heading">{this.state.dashboard["reject_count"]}</span>
                          <span className="description">거절</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {this.state.dashboard["user_nickname"]}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr" />
                      브론즈
                    </div>
                    <div>
                      <i className="ni education_hat" />
                      {this.state.dashboard["remained_point"]} 포인트
                      <Button
                      className="ml-3"
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        출금하기
                      </Button>
                      </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-2" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">포인트 현황</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.translationPagination()}
                  {this.recordPagination()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Parter;
