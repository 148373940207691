import React from "react";

// reactstrap components
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
// core components

export function navigation(select, current, count, pageSize = 5) {
    var start = current - 3;
    if (start < 0)
      start = 0;
    
    var max = Math.ceil(count / pageSize);
    var end = start + 7;
    if (end >= max) {
      end = max;
      start = max - 7;
      if (start < 0)
        start = 0;
    }
    var navArray = [];
    for (var i = start; i < end; i++) {
      navArray.push(i);
    }
    return <nav aria-label="...">
    <Pagination
      size="sm"
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
        <PaginationItem>
          <PaginationLink
            onClick={e => current !== 0 ? select(current - 1) : e.preventDefault()}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
      {navArray.map((page, i) => (
        <PaginationItem active={
          page ===
          current} key={page}>
          <PaginationLink onClick={e => select(page)}>
            {page + 1}
          </PaginationLink>
        </PaginationItem>
      ))}
        <PaginationItem>
          <PaginationLink
            onClick={e => current < max - 1 ? select(current + 1) : e.preventDefault()}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>;
  }
