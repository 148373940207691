import React from "react";

// reactstrap components
import {
  Input,
  Button,
  Badge,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import {
  init,
  count,
  current,
  newAdClick,
  removeAdClick,
  resourceTypeSelect,
  bindUI,
  pageSelect,
  updateAd,
  getAdArray,
  getFileArray,
  selectAnswerType,
  selectSecondHint,
} from 'controller/AdController.jsx'
import {
  upload
} from 'controller/AdInsertController.jsx'
import AdRemoveModal from 'views/advertiser/AdRemoveModal.jsx'
import "assets/css/learnward.css"
import { getVideoArray } from "controller/AdController";

class AdFullRegister extends React.Component {
  state = {
    count: count,
    current: current,
    formModal: false,
    hintTags: [],
    answerTypeName: "주관식",
    answerTypeId: 1,
    resourceType: "image",
  };

  componentDidMount() {
    bindUI();
    init(this.setAnswerType, this.resetTag);
    this.setState({ count: 1, current: 0 });
  }

  newPage() {
    newAdClick();
    this.setState({
      count: count,
      current: current
    })
  }

  removeRequest() {
    this.refs.removeModal.toggleModal();
  }

  removePage = () => {
    if (count < 2)
      return;
    var newCurrent = removeAdClick();
    this.setState({
      current: newCurrent,
      count: this.state.count - 1
    })
  }

  uploadAdCall(campaign, skipTranslate = false) {
    updateAd(this.state.current);
    upload(campaign, getAdArray(), getFileArray(), getVideoArray(), skipTranslate);
  }

  handlePageClick(page) {
    pageSelect(page);
    this.setState({
      current: page
    })
  }

  imageError() {
    document.getElementById("image_preview").src = require("assets/img/theme/image_fallback.jpg").default;
  }

  answerTypeToggle = () => this.setState({ answerTypeDropdownOpen: !this.state.answerTypeDropdownOpen });

  setAnswerType = (typeId, name) => {
    if (typeId === this.state.answerTypeId)
      return;
    this.setState({ answerTypeName: name, answerTypeId: typeId });
    selectAnswerType(typeId);
    if (typeId === 2) {
      document.getElementById("quiz").value = "순서대로 골라 문장을 완성해주세요."
    } else if (typeId === 0) {
      document.getElementById("quiz").value = "보기 중 적절한 것을 골라주세요."
    } else {
      document.getElementById("quiz").value = "빈 칸에 맞게 정답을 채워주세요.";
    }
  }

  resetTag = (initValue) => {
    this.setState({hintTags: initValue});
  }

  setTag = (tag) => {
    this.setState({ hintTags: [...this.state.hintTags, tag] });
    selectSecondHint([...this.state.hintTags, tag])
  }

  deleteTag = (item) => {
    const deletedTags = this.state.hintTags;
    deletedTags.splice(item.index, 1)
    this.setState({ hintTags: deletedTags });
  }

  onAnswerChange = (e) => {
    if (this.state.answerTypeId === 2) {
      var answer = e.target.value;
      var hints = answer.split(' ');
      this.setState({ hintTags: hints });
      selectSecondHint(hints);
      console.log(e);
    }
  }

  media() {
    return (
      <Col className="sm-6" lg="6">
        <Badge className="mb-2" color="primary">
          광고 미디어 유형을 선택하세요.
        </Badge>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="inputGroupSelect01">
              미디어 유형
            </label>
          </div>
          <select
            id="resourceTypeSelect"
            className="custom-select"
            onChange={(e) => {
              resourceTypeSelect();
              this.setState({ resourceType: e.target.value });
            }}
          >
            <option value="image">Image</option>
            <option value="video">Video</option>
          </select>
        </div>
        <div id="resourceInput" className="custom-file col-sm">
          <Input
            accept={"image/png,image/jpeg"}
            type="file"
            id="ad-resource-input"
            name="filename"
          />
          <label className="custom-file-label" htmlFor="ad-resource-input">
            파일을 선택해주세요.
          </label>
        </div>
        <div
          className="ad-resource-container mb-2 bg-secondary"
          id="image_preview_container"
        >
          <img
            id="image_preview"
            src={require("assets/img/theme/image_fallback.jpg").default}
            className="ad-resource"
            alt="ad upload resource"
            onError={() => this.imageError()}
          />
        </div>
        <Col id="video_preview_container" hidden>
            <Input
              accept={"video/mp4"}
              type="file"
              id="ad-video-input"
              name="filename"
            />
          <div className="ad-resource-container ">
            <video id="video_preview" className="ad-resource" controls />
          </div>
        </Col>
      </Col>
    );
  }
  render() {
    return (
      <>
        <Col className="mt-3">
          <Card className="shadow border-0">
            <CardBody className="py-5">
              <Container className="">
                <Row>
                    {this.media()}
                  <Col lg="6">
                  <Badge className="bg-primary mb2">
                      레벨 없음=0, 하=1, 중=2, 상=3
                    </Badge>
                    <Input
                      className="mt-2 mb-2"
                      type="number"
                      id="level_input"
                      min="0"
                      defaultValue="0"
                      aria-label="Text input with dropdown button"
                    ></Input>

                    <Badge className="bg-primary mb-2 mt-2">
                      지급될 리워드를 선택해주세요.
                    </Badge>
                    <div className="was-validated input-group mb-2" hidden>
                      <div className="input-group-prepend">
                        <span className="input-group-text">노출 당</span>
                      </div>
                      <input
                        id="cpv"
                        type="number"
                        className="form-control"
                        min="0"
                        defaultValue="0"
                        step=""
                        data-bind="value:replyNumber"
                        aria-label="원"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">point</span>
                      </div>
                      <div className="input-group-append">
                        <button
                          className="btn btn-secondary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="노출당 발생하는 광고 비용입니다."
                        >
                          ?
                        </button>
                      </div>
                    </div>
                    <div className="was-validated input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">정답 당</span>
                      </div>
                      <input
                        id="cpco"
                        type="number"
                        className="form-control"
                        min="1"
                        data-bind="value:replyNumber"
                        defaultValue="1"
                        aria-label="원"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">point</span>
                      </div>
                      <div className="input-group-append">
                        <button
                          className="btn btn-secondary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="정답을 맞췄을 때 발생하는 광고 비용입니다."
                        >
                          ?
                        </button>
                      </div>
                    </div>
                    <Badge className="bg-primary mb-2 mt-2">광고 문구</Badge>
                    <form className="was-validated mb-1">
                      <Input
                        type="textarea"
                        className="form-control is-invalid mb-2"
                        id="english"
                        placeholder="영어"
                        required
                      ></Input>
                    </form>
                    <form className="was-validated mb-1">
                      <Input
                        type="textarea"
                        className="form-control is-invalid mb-2"
                        id="translation"
                        placeholder="한글"
                        required
                      ></Input>
                    </form>
                    <form className="was-validated mb-1">
                      <Input
                        type="textarea"
                        className="form-control is-invalid mb-2"
                        id="explanation"
                        placeholder="해설"
                        required
                      ></Input>
                    </form>
                    <ButtonDropdown
                      isOpen={this.state.answerTypeDropdownOpen}
                      toggle={this.answerTypeToggle}
                    >
                      <DropdownToggle caret>
                        {this.state.answerTypeName}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => this.setAnswerType(0, "객관식")}
                        >
                          객관식
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.setAnswerType(1, "주관식")}
                        >
                          주관식
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.setAnswerType(2, "열거형")}
                        >
                          열거형
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                    <form className="was-validated mb-1">
                      <Input
                        type="textarea"
                        className="form-control is-invalid mb-2"
                        id="quiz"
                        placeholder="유형 문구"
                        required
                        defaultValue="빈 칸에 맞게 정답을 채워주세요."
                      ></Input>
                    </form>
                    <form className="was-validated mb-1">
                      <Input
                        type="textarea"
                        className="form-control is-invalid mb-2"
                        id="hint"
                        placeholder="문제"
                        required
                      ></Input>
                    </form>
                    <form className="was-validated mb-1">
                      <Input
                        type="textarea"
                        className="form-control is-invalid mb-2"
                        id="answer"
                        placeholder="정답"
                        required
                        onChange={this.onAnswerChange}
                      ></Input>
                    </form>
                    {/* {this.state.answerTypeId !== 1 ? (
                      <YedTagInput
                        tags={this.state.hintTags}
                        tagInputErrorControl={true}
                        tagInpuTitle={"선택지"}
                        onChange={this.setTag}
                        removeTag={(item) => {
                          this.deleteTag(item);
                        }}
                      />
                    ) : (
                      ""
                    )} */}
                  </Col>
                </Row>
                <div className="container row">
                  <div className="col-sm"></div>
                </div>
                <div className="container mt-3">
                  <nav aria-label="Page navigation example">
                    <ul
                      className="pagination-sm justify-content-center"
                      id="pagination"
                    ></ul>
                  </nav>
                </div>
                <Pagination
                  className="pagination justify-content-center mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  {[...Array(this.state.count)].map((page, i) => (
                    <PaginationItem active={i === this.state.current} key={i}>
                      <PaginationLink onClick={(e) => this.handlePageClick(i)}>
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <form className="text-right mb-2 ml-3">
                    <Button
                      className="btn btn-primary"
                      size="sm"
                      type="button"
                      onClick={() => this.removeRequest()}
                    >
                      삭제
                    </Button>
                    <Button
                      className="btn btn-primary"
                      size="sm"
                      type="button"
                      onClick={() => this.newPage()}
                    >
                      추가
                    </Button>
                  </form>
                </Pagination>
              </Container>
              <AdRemoveModal ref="removeModal" removeUpdate={this.removePage} />
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default AdFullRegister;
