/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
    Card,
    Table,
    Row
} from "reactstrap";
// core components
import { request } from "network/Request.js"
import Button from "reactstrap/lib/Button";
import dateFormat from "dateformat";
import { useNavigate } from "react-router";

const Episode = () => {
    const [examList, setExamList] = useState([]);
    const [currentPickList, setCurrentPickList] = useState([]);
    const [weekContents, setWeekContents] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        requestExamList();
        requestWeekContentsList();
        requestCurrentPickList();
    }, []);

    const redirectNewExam = () => {
        if (loaded) {
            navigate("/admin/newexam?episode_id=" + getQueryVariable("episode_id") + "&index=" + examList.length);
        }
    }

    const redirectEditExam = (examId) => {
        if (loaded)
            navigate("/admin/editexam?episode_id=" + getQueryVariable("episode_id") + "&exam_id=" + examId);
    }

    const pickManage = (pickManageType, weekContentId) => {
        if (window.confirm("변경하시겠습니까?")) {
            request({
                "bk-working": "pick-manage",
                "episode_id": getQueryVariable("episode_id"),
                "week_content_id": weekContentId,
                "exam_pick_manage_type": pickManageType
            }, (result, res) => {
                alert(result);
            })
        }
    }

    const requestExamList = () => {
        request({
            "bk-working": "episode",
            "episode_id": getQueryVariable("episode_id")
        }, (result, res) => {
            if (result) {
                setExamList(res["exam_array"]);
                setLoaded(true);
            } else {
            }
        })
    }

    const requestCurrentPickList = () => {
        request({
            "bk-working": "current-pick-list",
        }, (result, res) => {
            if (result) {
                console.log(res);
                setCurrentPickList(res["limit_array"]);
            } else {
            }
        })
    }

    const requestWeekContentsList = () => {
        request({
            "bk-working": "week-contents",
            "limit_offset": 0,
            "limit_range": 30,
            "episode_id": getQueryVariable("episode_id")
        }, (result, res) => {
            if (result) {
                console.log(res["limit_array"]);
                setWeekContents(res["limit_array"]);
            } else {
            }
        })
    }

    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }

    const dateFormatting = (timeMillisec) => {
        var date = new Date(timeMillisec);
        return dateFormat(date, "yyyy.mm.dd")
    };

    return (
        <>
            {/* Table */}
            <div className="col">시험 번호: {getQueryVariable("episode_id")}</div>
            <div className="col">시작일시: {getQueryVariable("start_time")}</div>
            <Row>
                <div className="col">
                    <div className="col mt-5">시험출제관리(반드시 경매 콘텐츠를 우선 등록)</div>
                    <Button onClick={() => { redirectNewExam() }} >새 문제</Button>
                    <Card className="">
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">타입</th>
                                    <th scope="col">질문</th>
                                    <th scope="col">문장</th>
                                </tr>
                            </thead>
                            <tbody>
                                {examList.map((page, i) => (
                                    <tr key={i} onClick={() => redirectEditExam(page["exam_id"])}>
                                        <td size="lg">{page["exam_id"]}</td>
                                        <td>
                                            {page["exam_type"]}</td>
                                        <td>
                                            {page["exam_question"]}</td>
                                        <td>
                                            {page["exam_sentence"]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                    <Card className="">
                        <div className="col mt-5">현재고정픽 관리(이틀 전 픽은 해제 후 새로 등록)</div>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">사용자</th>
                                    <th scope="col">캠페인 이름</th>
                                    <th scope="col">시작시간</th>
                                    <th scope="col">평균비용</th>
                                    <th scope="col">픽 해제</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPickList.map((page, i) => (
                                    <tr key={i} >
                                        <td size="lg">{page["week_content_id"]}</td>
                                        <td>
                                            {page["user_nickname"]}</td>
                                        <td>
                                            {page["campaign_name"].slice(0, 25)}</td>
                                        <td>
                                            {dateFormatting(page["release_time"])}</td>
                                        <td>
                                            {page["cp_average"].toFixed(3)}</td>
                                        <td>
                                            <Button onClick={() => { pickManage(1, page["week_content_id"]) }} >픽 해제</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                    <Card className="">
                        <div className="col mt-5">이번 주 등록된 콘텐츠(나이 성별 제한 없는 콘텐츠만)</div>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">사용자</th>
                                    <th scope="col">캠페인 이름</th>
                                    <th scope="col">시작시간</th>
                                    <th scope="col">평균비용</th>
                                    <th scope="col">등록</th>
                                </tr>
                            </thead>
                            <tbody>
                                {weekContents.map((page, i) => (
                                    <tr key={i} >
                                        <td size="lg">{page["week_content_id"]}</td>
                                        <td>
                                            {page["user_nickname"]}</td>
                                        <td>
                                            {page["campaign_name"].slice(0, 25)}</td>
                                        <td>
                                            {dateFormatting(page["release_time"])}</td>
                                        <td>
                                            {page["cp_average"].toFixed(3)}</td>
                                        <td>
                                            {
                                                <Button onClick={() => { pickManage(0, page["week_content_id"]) }} >예상문제 등록</Button>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                </div>
            </Row>
            {/* Dark table */}
        </>
    );
}

export default Episode;