/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Input,
  Col,
  Badge,
  Collapse,
} from "reactstrap";
import { request } from "network/Request.js";
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko';
import moment from "moment-timezone";
registerLocale('ko', ko)

class CampaignRegister extends React.Component {
  state = {
    "remainedPoint": 0,
    "expire": new Date(),
    isOpen: false,
    genderSelect: "all",
    minOpen:false,
    minBirth: new Date(),
    maxOpen:false,
    maxBirth: new Date(),
  }

  target() {
    const toggle = () => this.setState({ isOpen: !this.state.isOpen });

    return (
      <div className="mt-1" >
        <Badge color="primary">
          세부설정(선택사항)
                </Badge>
        <Button className="mt-3 ml-3" size="sm" color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}>펼치기</Button>
        <Collapse isOpen={this.state.isOpen}>
          <Card>
            <CardBody>
              {/* <Badge className="badge-default" pill>
                전환 리워드(선택사향)
                </Badge>
              <div className="was-validated input-group mb-1 mt-2">
                <select size="1" className="custom-select" id="actionSelect">
                  <option value="no">선택</option>
                  <option value="click">클릭</option>
                  <option value="install">설치</option>
                  <option value="participate">참여</option>
                </select>
                <input id="cpa" type="number" className="form-control" min="0"
                  defaultValue="0" aria-label="Text input with dropdown button" />
                <div className="input-group-append">
                  <span className="input-group-text">point</span>
                </div>
                <div className="input-group-append">
                  <button className="btn btn-secondary" data-toggle="tooltip"
                    data-placement="top" title="추가 리워드 옵션 선택사항 입니다.">?</button>
                </div>
              </div> */}
              <Badge className="mt-3" color="primary">
                성별제한
                </Badge>
              <br />

              <ButtonGroup className="mt-3" >
                <Button onClick={() => this.setState({ genderSelect: "all" })} active={this.state.genderSelect === "all"}>전체</Button>
                <Button onClick={() => this.setState({ genderSelect: "m" })} active={this.state.genderSelect === "m"}>남자</Button>
                <Button onClick={() => this.setState({ genderSelect: "w" })} active={this.state.genderSelect === "w"}>여자</Button>
              </ButtonGroup>
              <br />
              <Badge className="mt-3" color="primary">
                최소연령 제한
                </Badge>
                <Button className="mt-3 ml-3" size="sm" color="primary" 
                onClick={(e) => this.setState({ minOpen: !this.state.minOpen })} style={{ marginBottom: '1rem' }}>{!this.state.minOpen ? "설정" : "없애기"}</Button>
              <Collapse isOpen={this.state.minOpen}>
                출생년도
              <Input
                  className="mt-2"
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                  type="date"
                  rows="1"
                  onChange={(e) => this.handleChange(e, "minBirth")}
                />
                이상
              </Collapse>
              <br />
              <Badge className="mt-3" color="primary">
                최대연령 제한
                </Badge>
                <Button className="mt-3 ml-3" size="sm" color="primary" 
                onClick={(e) => this.setState({ maxOpen: !this.state.maxOpen })} style={{ marginBottom: '1rem' }}>{!this.state.maxOpen ? "설정" : "없애기"}</Button>
              <Collapse isOpen={this.state.maxOpen}>
                출생년도
                <Input
                  className="mt-2"
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                  type="date"
                  rows="1"
                  onChange={(e) => this.handleChange(e, "maxBirth")}
                />이하
                </Collapse>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }

  getValueIfNotNull(field) {
    var obj = document.getElementById(field);
    if (obj !== null) {
      return obj.value;
    }
    return null;
  }

  getCampaign() {
    var cpa = this.getValueIfNotNull('cpa');
    if (!cpa)
      cpa = 0;
    var actionSelect = document.getElementById('actionSelect');
    var action = null;
    if (actionSelect !== null)
      action = actionSelect.options[actionSelect.selectedIndex].value;
    if (!action)
      action = "no";
      
    var limitPoint = this.getValueIfNotNull('campaign_limit');
    var name = this.getValueIfNotNull('campaign_name');
    var url = this.getValueIfNotNull('campaign_url');
    var campaignType = document.getElementById("campaignTypeSelect").value;

    //min birth = max age
    return {
      "action_reward": cpa,
      "action_type": action,
      "impression_limit": limitPoint,
      "campaign_name": name,
      "campaign_type": campaignType,
      "campaign_url": url,
      "campaign_expire": this.state.expire.getTime(),
      "total_gender" : this.state.genderSelect === "all",
      "gender" : this.state.genderSelect === "m" ? 1 : 2,
      "use_min_age" : this.state.minOpen,
      "max_age" : this.state.minBirth.getTime(),
      "use_max_age" : this.state.maxOpen,
      "min_age" : this.state.maxBirth.getTime(),
    };
  }

  handleChange = (e, field) => {
    const date = new Date(moment().format(e.target.value + "T00:00:00+09:00"));
    this.setState({
      expire: date
    });
  };

  componentDidMount() {
    request({
      "bk-working": "charged-coin"
    }, (result, receive) => {
      if (result) {
        console.log(receive);
        this.setState({
          "remainedPoint": receive["coin"]
        });
      } else {
        alert("네트워크 에러")
      }
    });
  }

  campaignTypeSelect() {

  }

  render() {
    return (
      <>
        <Col className="">
          <Card className="shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
            <Badge color="primary">
                캠페인 이름
                </Badge>
                <form className="was-validated mb-1">
                <Input className="mt-2 form-control is-invalid" id="campaign_name" maxLength={33330} required></Input>
                </form>
                <select hidden id="campaignTypeSelect" className="custom-select">
                      <option value="translate">영어</option>
                      <option value="single">맞춤법</option>
                    </select>

              <div className="mb-4 mt-3">
              <Badge color="primary">
                  남은 코인
              </Badge>
                <br />
                {this.state.remainedPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 코인
                <br />
                <Badge className="mt-3 mb-1" color="primary">
                  사용할 코인
                </Badge>
                <h5>한 페이지당 최소 광고비용은 10000 코인 입니다.
                <br/>콘텐츠 제작비로 광고당 5천 코인이 소모됩니다.
                  </h5>
                <Input className="mt-2" type="number" id="campaign_limit" min="0"
                  defaultValue="0" aria-label="Text input with dropdown button"></Input>
              <Badge className="mt-3" color="primary">
                  url
                </Badge>
                <Input className="mt-2" id="campaign_url" placeholder="http 혹은 https를 포함한 전체 주소"></Input>

                <Badge className="mt-3" color="primary">
                  만료일
                </Badge>
                <br />
                <Input
                  className="mt-2"
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                  type="date"
                  rows="1"
                  onChange={(e) => this.handleChange(e, "expire")}
                />
                <br />
                {this.target()}
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default CampaignRegister;
