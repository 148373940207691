/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  Table,
  Row,
  Button
} from "reactstrap";
// core components
import { request } from "network/Request.js"
import { navigation } from "components/Nav/Nav.jsx"
import dateFormat from "dateformat";
import { useNavigate } from "react-router";

const NoticeList = () => {
  const [noticeCount, setNoticeCount] = useState(0);
  const [noticeCurrent, setNoticeCurrent] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const naviate = useNavigate();

  useEffect(() => {
    requestNoticeList(0);
  }, []);

  const redirectNotice = (noticeId) => {
    naviate("/admin/notice?notice_id=" + noticeId);
  }

  const requestNoticeList = (index) => {
    requestList(index, (res) => {
      console.log(res);
      setNoticeList(res["notice_array"]);
      setNoticeCount(res["notice_size"]);
    })
  }

  const requestList = (index, resultDelegate) => {
    request({
      "bk-working": "notice-list-total",
      "notice_offset": index * 10
    }, (result, res) => {
      if (result) {
        resultDelegate(res);
      } else {
        alert("네트워크 오류")
      }
    })
  }

  const selectNoticeCurrent = (index) => {
    setNoticeCurrent(index);
    requestNoticeList(index);
  }

  const calculateFirst = (current, count) => {
    return (count <= 7 ? 0 :
      current + 7 > count ? count - 7 :
        current)
  }

  const dateFormatting = (timeMillisec) => {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd")
  }

  const remove = (noticeId) => {
    if (window.confirm("삭제하시겠어요?")) {
      request({
        "bk-working": "notice-remove",
        "notice_id": noticeId
      }, (result, response) => {
        alert(result);
      });
    }
  }

  return (
    <>
      {/* Table */}

      <Button onClick={() => naviate("/admin/notice-register")}>등록하기</Button>
      <Row>
        <div className="col">
          <Card className="">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">아이디</th>
                  <th scope="col">제목</th>
                  <th scope="col">작성일</th>
                  <th scope="col">삭제</th>
                </tr>
              </thead>
              <tbody>
                {noticeList.map((page, i) => (
                  <tr key={i} >
                    <td size="lg" >{page["notice_id"]}</td>
                    <td size="lg" onClick={() => redirectNotice(page["notice_id"])}>{page["notice_subject"]}</td>
                    <td>
                      {dateFormatting(page["notice_time"])}</td>
                    <td>
                      <Button onClick={(e) => remove(page["notice_id"])}>삭제</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              {navigation(selectNoticeCurrent, noticeCurrent, noticeCount, 10)}
            </CardFooter>
          </Card>
        </div>
      </Row>
      {/* Dark table */}
    </>
  );
}

export default NoticeList;
