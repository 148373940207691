/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, Table, Row, CardFooter, Button, CardHeader } from "reactstrap";
// core components
import { request } from "network/Request.js";
import { navigation } from "components/Nav/Nav";
import dateFormat from "dateformat";

class UserList extends React.Component {
  state = {
    limitCount: 0,
    limitCurrent: 0,
    limitList: [],
    searhText: null,
  };

  componentDidMount() {
    this.requestUserList(0);
  }

  selectUserCurrent = (index) => {
    this.setState({ limitCurrent: index });
    this.requestUserList(index);
  };

  requestUserList(index) {
    this.requestList(index, (res) => {
      this.setState({
        limitList: res["limit_array"],
        limitCount: res["limit_size"],
      });
    });
  }

  requestList(index, resultDelegate) {
    request(
      {
        "bk-working": "user-list",
        limit_offset: index * 15,
        limit_range: 15,
        limit_search: this.state.searhText,
      },
      (result, res) => {
        console.log(res);
        if (result) {
          resultDelegate(res);
        } else {
          alert("네트워크 오류");
        }
      }
    );
  }

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd");
  }

  onSearch() {
    this.setState({ limitCount: 0, limitCurrent: 0, limitList: [] }, () =>
      this.requestUserList(0)
    );
  }

  randomPush() {
    request({"bk-working": "push-random-quiz"}, (result, response) => {
      alert(result);
    });
  }

  render() {
    return (
      <>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="">
              <CardHeader className="border-0">
                <input
                  type="text"
                  id="search_text"
                  placeholder="검색어"
                  onChange={(e) => this.setState({ searhText: e.target.value })}
                />
                <Button
                  onClick={() => {
                    this.onSearch();
                  }}
                >
                  검색
                </Button>
                <Button onClick={this.randomPush}>10일 이내 접속 랜덤문제 푸시</Button>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">닉네임</th>
                    <th scope="col">연락처</th>
                    <th scope="col">포인트</th>
                    <th scope="col">레퍼러</th>
                    <th scope="col">가입일</th>
                    <th scope="col">더보기</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.limitList.map((page, i) => (
                    <tr key={i}>
                      <td size="lg">{page["user_nickname"]}</td>
                      <td size="lg">{page["user_phone"]}</td>
                      <td size="lg">{page["remained_point"]}</td>
                      <td size="lg">
                        {page["referrer"]?.substring(
                          0,
                          Math.min(20, page["referrer"].length)
                        )}
                      </td>
                      <td>
                        {this.dateFormatting(parseInt(page["time_millisec"]))}
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            window.open(
                              "/admin/user-detail/" + page.user_id,
                              "_blank"
                            )
                          }
                        >
                          상세
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {navigation(
                  this.selectUserCurrent,
                  this.state.limitCurrent,
                  5000
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </>
    );
  }
}

export default UserList;
