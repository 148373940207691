/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  Table,
  Row,
  Button
} from "reactstrap";
// core components
import { request } from "network/Request.js"
import { navigation } from "components/Nav/Nav.jsx"
import dateFormat from "dateformat";
import { useNavigate } from "react-router";

const PopupList = () => {
  const [limitCount, setLimitCount] = useState(0);
  const [limitCurrent, setLimitCurrent] = useState(0);
  const [limitList, setLimitList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    requestPopupList(0);
  }, []);

  const redirectPopupRegister = (popupId) => {
    navigate("/admin/popup-register?popup_id=" + popupId);
  }

  const requestPopupList = (index) => {
    requestList(index, (res) => {
      console.log(res);
      setLimitList(res["limit_array"]);
      setLimitCount(res["limit_size"]);
    })
  }

  const requestList = (index, resultDelegate) => {
    request({
      "bk-working": "popup-list",
      "limit_offset": index * 10,
      "limit_range": 10,
    }, (result, res) => {
      if (result) {
        resultDelegate(res);
      } else {
        alert("네트워크 오류")
      }
    })
  }

  const selectPopupCurrent = (index) => {
    setLimitCurrent(index);
    requestPopupList(index);
  }

  const calculateFirst = (current, count) => {
    return (count <= 7 ? 0 :
      current + 7 > count ? count - 7 :
        current)
  }

  const dateFormatting = (timeMillisec) => {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd")
  }

  const remove = (popupId) => {
    if (window.confirm("삭제하시겠어요?")) {
      request({
        "bk-working": "popup-remove",
        "popup_id": popupId
      }, (result, response) => {
        alert(result);
      });
    }
  }

  return (
    <>
      {/* Table */}
      <Button onClick={() => navigate("/admin/popup-register")}>등록하기</Button>
      <Row>
        <div className="col">
          <Card className="">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">아이디</th>
                  <th scope="col">제목</th>
                  <th scope="col">내용</th>
                  <th scope="col">사진</th>
                  <th scope="col">시작일</th>
                  <th scope="col">종료일</th>
                  <th scope="col">삭제</th>
                </tr>
              </thead>
              <tbody>
                {limitList.map((page, i) => (
                  <tr key={i} >
                    <td size="lg" >{page["popup_id"]}</td>
                    <td size="lg" >{page["popup_title"]}</td>
                    <td size="lg" >{page["popup_body"]}</td>
                    <td size="lg" ><img src={page["link"]} style={{ width: "50px" }}></img></td>
                    <td>
                      {dateFormatting(parseInt(page["start_millisec"]))}</td>
                    <td>
                      {dateFormatting(parseInt(page["end_millisec"]))}</td>
                    <td>
                      <Button onClick={(e) => remove(page["popup_id"])}>삭제</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              {navigation(selectPopupCurrent, limitCurrent, limitCount)}
            </CardFooter>
          </Card>
        </div>
      </Row>
      {/* Dark table */}
    </>
  );
}

export default PopupList;
