/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Container,
  Row,
  Col,
  CardImg,
} from "reactstrap";
// core components
import { request } from "network/Request.js"
import AdvertiserHeader from "components/Headers/AdvertiserHeader";

class RequestAdvertiser extends React.Component {
  state = {
    partner: false,
    termCheck: false
  }

  termCheck(check) {
    this.setState({ termCheck: check });
  }

  sendRequest() {
    if (!this.state.termCheck) {
       alert("약관에 먼저 동의해주세요.");
       return;
    }
    let data = {
      "bk-working": "request-advertiser"
    };
    request(data, (res, response) => {
      if (res) {
        alert("등록이 완료되었습니다.");
        window.location.reload();
      } else {
        alert("등록 실패");
      }
    });
  }

  render() {
    return (
      <>
        <div>
          <AdvertiserHeader onChange={(change) => this.termCheck(change)} onSubmit={() => this.sendRequest()} />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="4">
                <Card className="card-profile">
                  <CardImg
                    alt="..."
                    src={require("assets/img/theme/img-1-1000x600.jpg").default}
                    top
                  />
                  <Row className="justify-content-center">
                    <Col lg="3">
                      <div className="card-profile-image" >
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            style={{ borderRadius: 20 }}
                            src={require("assets/img/theme/learn.png").default}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <CardBody className="mt-7">
                    <div className="text-center">
                      <h5 className="h3">
                        광고를 학습용 콘텐츠로
                      </h5>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                          광고를 유익하게 만듭니다.
                  </div>
                      <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                    콘텐츠 참여한 사용자에게 보상 지급
                  </div>
                      <div >
                        <i className="ni education_hat mr-2" />
                    복습 시스템으로 지속적안 노출과 참여
                  </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-xl-2" xl="8">
                <Row>

                <Col lg="6">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">광고 콘텐츠화 과정</h5>
              </CardHeader>
              <CardBody>
                <div
                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  <div className="timeline-block">
                    <span className="timeline-step badge-success">
                      <i className="ni ni-active-40" />
                    </span>
                    <div className="timeline-content">
                      <div className="d-flex justify-content-between pt-1">
                        <div>
                          <span className="text-muted text-sm font-weight-bold">
                            광고 등록하기
                          </span>
                        </div>
                      </div>
                      <h6 className="text-sm mt-1 mb-0">
                        캠페인 정보를 입력하고 광고 별로 이미지와 한글 광고문구를 입력합니다.
                      </h6>
                    </div>
                  </div>
                  <div className="timeline-block">
                    <span className="timeline-step badge-danger">
                      <i className="ni ni-user-run" />
                    </span>
                    <div className="timeline-content">
                      <div className="d-flex justify-content-between pt-1">
                        <div>
                          <span className="text-muted text-sm font-weight-bold">
                            콘텐츠 제작
                          </span>
                        </div>
                        <div className="text-right">
                          <small className="text-muted">
                            <i className="ni ni-tag mr-1" />4 단계 검수
                          </small>
                        </div>
                      </div>
                      <h6 className="text-sm mt-1 mb-0">
                        광고가 전문가에게 전달되고 영어교육 콘텐츠로 제작됩니다. 인공지능 목소리로 음성듣기 기능을 제공합니다.
                      </h6>
                    </div>
                  </div>
                  <div className="timeline-block">
                    <span className="timeline-step badge-info">
                      <i className="ni ni-bell-55" />
                    </span>
                    <div className="timeline-content">
                      <div className="d-flex justify-content-between pt-1">
                        <div>
                          <span className="text-muted text-sm font-weight-bold">
                            광고 시작하기
                          </span>
                        </div>
                      </div>
                      <h6 className="text-sm mt-1 mb-0">
                        콘텐츠화가 완료된 광고를 즉시 시작하거나 시험 입찰에 참여할 수 있습니다.
                      </h6>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="bg-gradient-success border-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              className="text-uppercase text-muted mb-0 text-white"
                              tag="h5"
                            >
                              학습하고
                        </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              평균 참여 54초
                        </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                              <i className="ni ni-active-40" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-white mr-2">
                        매 방문시 평균 54초 콘텐츠 학습
                      </span>
                          <span className="text-nowrap text-light">
                            
                      </span>
                        </p>
                      </CardBody>
                    </Card>
                    <Card className="bg-gradient-danger border-0" tag="h5">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                              복습하고
                        </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              1.5배 추가 노출
                        </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                              <i className="ni ni-spaceship" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-white mr-2">
                        콘텐츠 재방문율 평균 1.5배 이상
                      </span>
                          <span className="text-nowrap text-light">
                            
                      </span>
                        </p>
                      </CardBody>
                    </Card>
                    <Card className="bg-gradient-info border-0" tag="h5">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                              미션에 참가
                        </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                            퀴즈, 선착순 미션 등
                        </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                              <i className="ni ni-spaceship" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-white mr-2">
                          다양한 미션에 참가하며 콘텐츠 활용
                      </span>
                          <span className="text-nowrap text-light">
                            
                      </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-1.png").default}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>사용자가 참여하는 광고</h1>
                    <p>
                      런워드의 광고는 사용자가 직접 참여합니다. 사용자는 학습하고 문제를 풀며 콘텐츠에 자연스럽게 참여합니다.
                  </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle mr-3" color="success">
                              <i className="ni ni-bulb-61" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">직접 참여하여 높은 집중도</h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle mr-3" color="success">
                              <i className="ni ni-book-bookmark" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">복습을 하며 지속적으로 노출</h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle mr-3" color="success">
                              <i className="ni ni-controller" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">미션을 위해 지속적인 복습</h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-2.png").default}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h1>시험에 등록해보세요.</h1>
                    <p>
                      더 폭발적인 홍보를 원하신다면 콘텐츠를 시험에 등록하여 월등히 많은 반복학습을 이끌어낼 수 있습니다.
                      사용자분들은 보상이 큰 시험에 관심이 매우 많습니다. 시험에 등록되면 시험 확정문제로 노출됩니다.
                  </p>
                    <Link
                      className="font-weight-bold text-warning mt-5"
                      to="#"
                    >
                      입찰식 시험출제
                  </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-3.png").default}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>간편한 관리자 기능</h1>
                    <p>
                      대시보드와 광고관리 기능으로 광고 콘텐츠의 성과를 측정할 수 있습니다.
                      간편하게 광고를 등록하시면 런워드에서 콘텐츠로 만들어 드립니다.
                  </p>
                    <Link
                      className="font-weight-bold text-info mt-5"
                      to="#"
                    >
                      광고주로 먼저 등록해주세요
                  </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </>
    );
  }
}

export default RequestAdvertiser;