/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  ButtonGroup,
} from "reactstrap";
import moment from "moment-timezone";
import { request } from "network/Request.js"

class Header extends React.Component {
  state = {
    past: {},
    morePast: {},
    time: Date.now(),
    filterType: "m",
  };

  componentDidMount() {
    this.requestStatistics();
  }

  requestStatistics() {
    var data = {
      "bk-working": "statistics"
    }
    request(data, (result, response) => {
      if (result) {
        console.log(response);
        this.setState({
          past: response["past_json"],
          morePast: response["more_past_json"]
        })
      }
    });
  }

  getPercentView(field) {
    if (this.state.past[field] == null || this.state.morePast[field] == null)
      return this.getPositivePercent(0);
    if (parseFloat(this.state.morePast[field]) - parseFloat(this.state.past[field]) === 0) {
      return this.getPositivePercent("비교대상 없음");
    }
    var percent = parseFloat(this.state.past[field]) / (parseFloat(this.state.morePast[field]) - parseFloat(this.state.past[field])) * 100;
    return percent >= 0 ? this.getPositivePercent(percent.toFixed(2)) :
      this.getNegativePercent(parseFloat(percent.toFixed(2)));
  }

  getPositivePercent(percent) {
    return <p className="mt-3 mb-0 text-muted text-sm">
      <span className="text-success mr-2">
        <i className="fas fa-arrow-up" /> {percent}%
      </span>
      <span className="text-nowrap">Since last month</span>
    </p>;
  }

  getNegativePercent(percent) {
    return <p className="mt-3 mb-0 text-muted text-sm">
      <span className="text-danger mr-2">
        <i className="fas fa-arrow-down" /> {percent}%
    <span className="text-nowrap">Since last month</span>
      </span></p>;
  }

  getCount(count) {
    if (count == null)
      count = 0;
    return count + " 회 / 30일";
  }

  datePickerChanged = (e) => {
    var time = new Date(moment().format(e.target.value + "T00:00:00+09:00")).getTime();
    this.setState({time: time});
    this.props.requestDashboard(this.state.filterType, time);
  }

  changeFilterType = (type) => {
    this.setState({filterType: type});
    this.props.requestDashboard(type, this.state.time);
  }

  render() {
    return (
      <>
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            전달 수
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.getCount(this.state.past["view_count"])}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      {this.getPercentView("view_count")}{" "}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            클릭 수
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.past["click_count"]} 회 / 30일
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      {this.getPercentView("click_count")}{" "}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            정답 수
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.past["answer_count"]} 회 / 30일
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      {this.getPercentView("answer_count")}{" "}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            노출 수
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.past["action_count"]} 회 / 30일
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                      {this.getPercentView("0")}{" "}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="align-items-center mt-3 mb-2">
                <Col>
                  <Card className="card-stats">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col style={{ minWidth: "80px", maxWidth: "120px"}} md="2" xs="2">
                            필터링
                        </Col>
                        <Col style={{ minWidth: "200px" }} md="7" sm="6" xs="6">
                          <Input
                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                            id="example-date-input"
                            type="date"
                            rows="1"
                            bsSize="sm"
                            onChange={this.datePickerChanged}
                          />
                        </Col>
                        <ButtonGroup className="text-center">
                          <Button
                          size="sm"
                            type="button"
                            color="primary"
                            onClick={e => this.changeFilterType("m")}
                            >월별</Button>
                          <Button
                          size="sm"
                          type="button"
                            color="primary"
                            onClick={e => this.changeFilterType("w")}
                            >주별</Button>
                          <Button
                          size="sm"
                            type="button"
                            color="primary"
                            onClick={e => this.changeFilterType("d")}
                            >일별</Button>
                        </ButtonGroup>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
