import {requestFile} from "network/Request.js";

function isValid(campaignJSON, adArray) {
	return true;
}

export function upload(campaignJSON, adArray, fileArray) {
	if (!isValid(campaignJSON, adArray)) {
		alert("최소 비용은 페이지당 10000포인트 입니다.");
		return;
	}
	if (campaignJSON["campaign_name"].length === 0) {
		alert("캠페인 이름을 입력해주세요.");
		return;
	}
	for (var i = 0; i < adArray.length ; i++) {
		if (adArray[i]['ad_translation'].length === 0) {
			alert((i+1) + "페이지에 광고문구가 입력되지 않았습니다.");
			return;
		}
	}

	for (var j in fileArray) {
		if (fileArray[j] !== null)
			adArray[j]["has-file"] = true;
		else
			adArray[j]["file"] = false;
	}

	let jsonParam = {
		"ad_array": adArray,
		"campaign": campaignJSON,
		"bk-working": "new-ad",
		"user_token": "WQ1MQA45d5nY2O8ZaosWfQ.u4119oMI431H/V+FzK7Lh4AetRClbX/rg56c5wQB0ofoz0Hd3pIrrQjP1tvR0nKoPgXXAIQGsfXelH3L3HG43B2GEQRuLDiwvw6BNduqWfzlAJga//4rCFuUqvoKfsvuhiUqbheHdDjFfVtQFl3zDrLeYU0n+A9VszKxUPRqakdeNzhgxxo4/TDTameGFDzb8OeRN8RF9yT1gA7eoKw0gA"
	};

	requestFile(jsonParam, fileArray, (result, res) => {
		if (res["bk-result"] === true) {
			alert("등록 성공");
			window.location.reload(false);
		} else {
			console.log(res)
			alert("등록 실패:" + res["result_detail"]);
		}
	});
}