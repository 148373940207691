/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import AdHeader from "components/Headers/AdHeader.jsx";
import { request } from "network/Request.js";
import { navigation } from "components/Nav/Nav.jsx";
import dateFormat from "dateformat";

class WithdrawalList extends React.Component {
  state = {
    liveCount: 0,
    liveCurrent: 0,
    purchaseList: [],
  };

  componentDidMount() {
    this.requestLiveList(0);
  }

  requestLiveList(index) {
    this.requestList("withdrawal-list", index, (res) => {
      console.log(res);
      this.setState({
        purchaseList: res["list_array"],
        liveCount: res["count"],
      });
    });
  }

  requestList(working, index, resultDelegate) {
    request(
      {
        "bk-working": working,
        list_range: 10,
        list_offset: index * 10,
      },
      (result, res) => {
        if (result) {
          resultDelegate(res);
        } else {
          alert("네트워크 오류");
        }
      }
    );
  }

  userDetail = (userExternalId) => {
    console.log(userExternalId);
    request(
      {
        "bk-working": "externalid-to-userid",
        user_external_id: userExternalId,
      },
      (result, response) => {

        window.open(
          "/admin/user-detail/" + response.user_id,
          "_blank")
        console.log(response);
      }
    );
  };

  selectLiveCurrent = (index) => {
    this.setState({ liveCurrent: index });
    this.requestLiveList(index);
  };

  selectEndCurrent = (index) => {
    this.setState({ endCurrent: index });
    this.requestExpireList(index);
  };

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd");
  }

  refundPoint(withdrawalId) {
    if (window.confirm("환불 하시겠어요?")) {
      request(
        {
          "bk-working": "withdrawal-refund",
          point_withdrawal_id: withdrawalId,
        },
        (result, res) => {
          alert(result);
        }
      );
    } else {
    }
  }

  changeState(withdrawalId, state) {
    if (window.confirm("상태를 변경 하시겠어요?")) {
      request(
        {
          "bk-working": "withdrawal-change",
          point_withdrawal_id: withdrawalId,
          point_withdrawal_state: state,
        },
        (result, res) => {
          this.requestLiveList(this.state.liveCurrent);
          alert(result);
        }
      );
    } else {
    }
  }

  routeChange(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <>
        <AdHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">출금 목록</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">출금아이디</th>
                      <th scope="col">사용자아이디</th>
                      <th scope="col">은행명</th>
                      <th scope="col">계좌</th>
                      <th scope="col">계좌</th>
                      <th scope="col">소유자</th>
                      <th scope="col">출금상태</th>
                      <th scope="col">포인트</th>
                      <th scope="col">환불</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.purchaseList.map((page, i) => (
                      <tr key={i}>
                        <td>{page["point_withdrawal_id"]}</td>
                        <td>
                          <button
                            onClick={() =>
                              this.userDetail(page["user_external_id"])
                            }
                          >
                            {page["user_external_id"]}
                          </button>
                        </td>
                        <td>{page["bank_name"]}</td>
                        <td>{page["bank_account_id"]}</td>
                        <td>
                          {new Date(page["time_millisec"]).toLocaleDateString()}
                        </td>
                        <td>{page["bank_account_name"]}</td>
                        <td>{page["point_withdrawal_state"]}</td>
                        <td>{page["point"]}</td>
                        <td>
                          <Button
                            onClick={() =>
                              this.changeState(page["point_withdrawal_id"], 0)
                            }
                          >
                            지급
                          </Button>
                          <Button
                            onClick={() =>
                              this.refundPoint(page["point_withdrawal_id"])
                            }
                          >
                            환불
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {navigation(
                    this.selectLiveCurrent,
                    this.state.liveCurrent,
                    this.state.liveCount
                  )}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithdrawalList;
