import { resourceUrl } from "network/Request.js";

function checkValid(adArray) {
	var result = true
	adArray.forEach((json => {
		console.log(json);
		if (json["ad_english"] === "")
			result = false;
		if (json["ad_hint"] === "")
			result = false;
		if (json["ad_quiz"] === "")
			result = false;
		if (json["answer"] === "")
			result = false;
		if (json["explanation"] === "")
			result = false;
	}))
	return result;
}

export function upload(adArray, campaignId) {
	console.log(adArray);
	if (!checkValid(adArray)) {
		alert("올바르지 않은 값이 있습니다.");
		return;
	}
	var formData = new FormData();
	var xhr = new XMLHttpRequest();
	xhr.onload = function (e) {
		if (xhr.readyState === 4) {
			if (xhr.status === 200) {
				var json = JSON.parse(xhr.responseText);
				if (json["bk-result"] === true) {
					alert("등록 성공");
					window.location.reload();
				} else {
					alert("등록 실패:" + json["result_detail"]);
				}
			} else {
				alert("등록 실패:" + xhr.statusText);
			}
		}
	};

	var jsonString = JSON.stringify({
		"ad_array": adArray,
		"bk-working": "insert-translation",
		"campaign_id" : campaignId
	});
	formData.append('json', jsonString);
	xhr.withCredentials = true;
	xhr.open('POST', resourceUrl());
	xhr.send(formData);
}