import React from "react";
import {
  Container,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  CardFooter,
} from "reactstrap";
import { request } from "network/Request.js";
import Button from "reactstrap/lib/Button";
import { navigation } from "components/Nav/Nav";

const _adAnswerTypes = ["객관식", "주관식", "열거형"];

class RegisterExam extends React.Component {
  state = {
    examTypeDropdownOpen: false,
    answerTypeDropdownOpen: false,
    sentenceTypeDropdownOpen: false,
    setOpen: false,
    examTypeName: "없음",
    examTypeId: -1,
    answerTypeName: "없음",
    answerTypeId: -1,
    sentenceTypeName: "없음",
    sentenceTypeId: -1,
    hintTags: [],
    pickCount: 0,
    pickCurrent: 0,
    pickList: [],
    reserveList: [],
  };

  componentDidMount() {
    this.requestPickList(0);
    this.requestReserveList();
  }

  requestSpeechCopyFile() {
    const adId = document.getElementById("ad_id").value;
    if (!adId || adId === "") {
      alert("콘텐츠아이디를 입력해주세요.");
      return;
    }
    request(
      {
        "bk-working": "exam-speech-file",
        episode_id: this.getQueryVariable("episode_id"),
        ad_id: adId,
      },
      (result, response) => {
        if (result) {
          alert(response["audio_path"]);
          document.getElementById("sentence").value = response["audio_path"];
        } else {
          alert("오류");
        }
      }
    );
  }

  requestReserveList() {
    request(
      {
        "bk-working": "exam-reserve-list",
      },
      (result, response) => {
        if (result) {
          console.log(response);
          this.setState({
            reserveList: response["reserve_list"],
          });
        }
      }
    );
  }

  requestPickList(index) {
    request(
      {
        "bk-working": "pick-list",
        episode_id: this.getQueryVariable("episode_id"),
        limit_range: 10,
        limit_offset: index * 10,
      },
      (result, response) => {
        if (result) {
          console.log(response);
          this.setState({
            pickList: response["limit_array"],
            pickCount: response["limit_count"],
          });
        }
      }
    );
  }

  selectCurrent = (index) => {
    this.setState({ pickCurrent: index });
    this.requestPickList(index);
  };

  requestNewExam() {
    var question = document.getElementById("exam").value;
    var adId = document.getElementById("ad_id").value;
    var sentence = document.getElementById("sentence").value;
    var answer = document.getElementById("answer").value;
    var timeLimit = document.getElementById("time_limit").value;
    var answerType = this.state.answerTypeId;
    var hintTags = this.state.hintTags;

    if (!this.checkAnswerValid(answerType, answer, hintTags)) {
      alert("보기 오류");
      return;
    }

    request(
      {
        "bk-working": "new-exam",
        exam_json: {
          episode_id: this.getQueryVariable("episode_id"),
          ad_id: adId,
          exam_type: this.state.examTypeId,
          exam_answer_type: answerType,
          exam_question: question,
          exam_sentence: sentence,
          exam_sentence_type: this.state.sentenceTypeId,
          exam_answer: answer,
          exam_hint: JSON.stringify(hintTags),
          exam_time_limit: timeLimit,
        },
      },
      (result, res) => {
        if (result) {
          alert("good");
        } else {
          alert("네트워크 오류");
        }
      }
    );
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  examTypeToggle = () =>
    this.setState({ examTypeDropdownOpen: !this.state.examTypeDropdownOpen });

  answerTypeToggle = () =>
    this.setState({
      answerTypeDropdownOpen: !this.state.answerTypeDropdownOpen,
    });

  sentenceTypeToggle = () =>
    this.setState({
      sentenceTypeDropdownOpen: !this.state.sentenceTypeDropdownOpen,
    });

  setExamType(typeId, name) {
    this.setState({ examTypeName: name, examTypeId: typeId });
  }

  setAnswerType(typeId, name) {
    this.setState({ answerTypeName: name, answerTypeId: typeId });
  }

  setSentenceType(typeId, name) {
    this.setState({ sentenceTypeName: name, sentenceTypeId: typeId });
  }
  setTag = (tag) => {
    this.setState({ hintTags: [...this.state.hintTags, tag] });
  };

  deleteTag = (item) => {
    const deletedTags = this.state.hintTags;
    deletedTags.splice(item.index, 1);
    this.setState({ hintTags: deletedTags });
  };

  checkAnswerValid(answerType, answer, hintTags) {
    var valid = false;
    const spaceContain = /\r|\n|\t/.test(answer);
    if (spaceContain) {
      alert("빈 공간이 있습니다.");
      valid = false;
      return;
    }
    console.log(answerType + "," + answer + "," + hintTags);
    if (answerType === 0) {
      for (var i = 0; i < hintTags.length; i++) {
        if (hintTags[i] === answer) valid = true;
      }
    } else if (answerType === 1) {
      var hintConcat = hintTags[0];
      for (i = 1; i < hintTags.length; i++) {
        hintConcat += " " + hintTags[i];
      }
      if (hintConcat === answer) valid = true;
    } else {
      valid = true;
    }
    return valid;
  }

  onAnserChange = (e) => {
    if (this.state.answerTypeId === 1) {
      var answer = e.target.value;
      var hints = answer.split(" ");
      this.setState({ hintTags: hints });
    }
  };

  render() {
    return (
      <>
        <Container>
          문제 등록하기
          <br />
          <br />
          <ButtonDropdown
            isOpen={this.state.examTypeDropdownOpen}
            toggle={this.examTypeToggle}
          >
            <DropdownToggle caret>{this.state.examTypeName}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.setExamType(0, "듣기")}>
                듣기
              </DropdownItem>
              <DropdownItem onClick={() => this.setExamType(1, "리딩")}>
                리딩
              </DropdownItem>
              <DropdownItem onClick={() => this.setExamType(2, "문법")}>
                문법
              </DropdownItem>
              <DropdownItem onClick={() => this.setExamType(3, "쓰기")}>
                쓰기
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <ButtonDropdown
            isOpen={this.state.answerTypeDropdownOpen}
            toggle={this.answerTypeToggle}
          >
            <DropdownToggle caret>{this.state.answerTypeName}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.setAnswerType(0, "객관식")}>
                객관식
              </DropdownItem>
              <DropdownItem onClick={() => this.setAnswerType(1, "열거형")}>
                열거형
              </DropdownItem>
              <DropdownItem onClick={() => this.setAnswerType(2, "주관식")}>
                주관식
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <ButtonDropdown
            isOpen={this.state.sentenceTypeDropdownOpen}
            toggle={this.sentenceTypeToggle}
          >
            <DropdownToggle caret>{this.state.sentenceTypeName}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.setSentenceType(0, "문장")}>
                문장
              </DropdownItem>
              <DropdownItem onClick={() => this.setSentenceType(1, "음성주소")}>
                음성
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <Input id="ad_id" placeholder="콘텐츠 아이디"></Input>
          {this.state.sentenceTypeId === 1 && (
            <Button onClick={() => this.requestSpeechCopyFile()}>듣기</Button>
          )}
          <Input type="textarea" id="sentence" placeholder="문장"></Input>
          <Input id="exam" placeholder="문제"></Input>
          <Input
            id="answer"
            placeholder="정답"
            onChange={this.onAnserChange}
          ></Input>
          {/* <YedTagInput tags={this.state.hintTags} tagInputErrorControl={true} tagInpuTitle={'힌트나 보기'} onChange={this.setTag} removeTag={(item) => { this.deleteTag(item) }} /> */}
          <Input id="time_limit" placeholder="시간제한(초)"></Input>
          <Button onClick={() => this.requestNewExam()}>제출하기</Button>
        </Container>
        <br />
        <br />
        경매로 미리 예약된 시험콘텐츠입니다.
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">콘텐츠아이디</th>
              <th scope="col">문장</th>
              <th scope="col">문제종류</th>
              <th scope="col">문제</th>
              <th scope="col">정답</th>
              <th scope="col">문제</th>
              <th scope="col">보기</th>
              <th scope="col">난이도</th>
              <th scope="col">설명</th>
            </tr>
          </thead>
          <tbody>
            {this.state.reserveList.map((page, i) => (
              <tr key={i}>
                <td>{page["ad_id"]}</td>
                <td>
                  <div style={{ width: 200, whiteSpace: "normal" }}>
                    {page["ad_translation"]}
                  </div>
                </td>
                <td>{_adAnswerTypes[page["answer_type"]]}</td>
                <td>
                  <div style={{ width: 200, whiteSpace: "normal" }}>
                    {page["ad_quiz"]}
                  </div>
                </td>
                <td>{page["answer"]}</td>
                <td>
                  <div style={{ width: 200, whiteSpace: "normal" }}>
                    {page["ad_hint"]}
                  </div>
                </td>
                <td>
                  <div style={{ width: 200, whiteSpace: "normal" }}>
                    {page["second_hint"]}
                  </div>
                </td>
                <td>{page["level"]}</td>
                <td>
                  <div style={{ width: 200, whiteSpace: "normal" }}>
                    {page["explanation"]}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <br />
        <br />
        시험은 모든 사용자들에게 전달된 콘텐츠중 골라 출제합니다. 픽종료 이전에
        중단이 된 경우 시험에서 제외합니다.
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">아이디</th>
              <th scope="col">중단</th>
              <th scope="col">픽종료</th>
              <th width="20%" scope="col">문장</th>
              <th scope="col">문제종류</th>
              <th width="20%" scope="col">문제</th>
              <th scope="col">정답</th>
              <th width="10%" scope="col">힌트</th>
              <th width="15%" scope="col">보기</th>
              <th scope="col">난이도</th>
              <th width="20%" scope="col">설명</th>
            </tr>
          </thead>
          <tbody>
            {this.state.pickList.map((page, i) => (
              <tr key={i}>
                <td>{page["ad_id"]}</td>
                <td>
                  {page["suspend_time"] <= 0
                    ? "무중단"
                    : new Date(page["suspend_time"]).toLocaleDateString()}
                </td>
                <td>{new Date(page["release_time"]).toLocaleDateString()}</td>
                <td>
                  <div style={{ whiteSpace: "normal" }}>
                    {page["ad_translation"]}
                  </div>
                </td>
                <td>{_adAnswerTypes[page["answer_type"]]}</td>
                <td>
                  <div style={{ whiteSpace: "normal" }}>{page["ad_quiz"]}</div>
                </td>
                <td>{page["answer"]}</td>
                <td>
                  <div style={{ whiteSpace: "normal" }}>{page["ad_hint"]}</div>
                </td>
                <td>
                  <div style={{ whiteSpace: "normal" }}>
                    {page["second_hint"]}
                  </div>
                </td>
                <td>{page["level"]}</td>
                <td>
                  <div style={{ whiteSpace: "normal" }}>
                    {page["explanation"]}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          {navigation(
            this.selectCurrent,
            this.state.pickCurrent,
            this.state.pickCount,
            10
          )}
        </CardFooter>
      </>
    );
  }
}

export default RegisterExam;
