import {resourceUrl} from "network/Request.js";

export function upload(campaignId, adArray, fileArray) {
	var formData = new FormData();
	var xhr = new XMLHttpRequest();
	xhr.onload = function (e) {
		if (xhr.readyState === 4) {
			if (xhr.status === 200) {
				var json = JSON.parse(xhr.responseText);
				if (json["bk-result"] === true) {
					alert("등록 성공");
					window.location.reload();
				}
				else {
					alert("등록 실패:" + json["result_detail"]);
				}
			} else {
				alert("등록 실패:" + xhr.statusText);
			}
		}
	};

	for (var i in fileArray) {
		if (fileArray[i] !== null)
			adArray[i]["has-file"] = true;
		else
			adArray[i]["file"] = false;
		formData.append('resources', fileArray[i]);
	}

	var jsonString = JSON.stringify({
		"ad_array": adArray,
		"campaign_id": campaignId,
		"bk-working": "insert-record",
	});

	formData.append('json', jsonString);
//	console.log(jsonString);
	xhr.withCredentials = true;
	xhr.open('POST', resourceUrl());
	xhr.send(formData);
}