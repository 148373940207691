/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import AdHeader from "components/Headers/AdHeader.jsx";
import { request } from "network/Request.js";
import { navigation } from "components/Nav/Nav.jsx";
import dateFormat from "dateformat";

class AdAdminManage extends React.Component {
  state = {
    liveCount: 0,
    liveCurrent: 0,
    endCount: 0,
    endCurrent: 0,
    adList: [],
    expireList: [],
    searchText: null,
  };

  componentDidMount() {
    var liveCurrent = localStorage.getItem("liveCurrent") * 1;
    var endCurrent = localStorage.getItem("endCurrent") * 1;
    this.setState({
      liveCurrent: liveCurrent ?? 1,
      endCurrent: endCurrent ?? 1,
    });
    this.requestLiveList(liveCurrent ?? 0);
    this.requestExpireList(endCurrent ?? 0);

    localStorage.setItem("liveCurrent", 0);
    localStorage.setItem("endCurrent", 0);
  }

  requestLiveList(index) {
    this.requestList("campaign-admin-list", index, (res) => {
      this.setState({
        adList: res["list_array"],
        liveCount: res["ad_count"],
      });
    });
  }

  requestExpireList(index) {
    this.requestList("campaign-admin-expire-list", index, (res) => {
      console.log(res);
      this.setState({
        expireList: res["list_array"],
        endCount: res["ad_count"],
      });
    });
  }

  requestList(working, index, resultDelegate) {
    request(
      {
        "bk-working": working,
        list_range: 10,
        list_offset: index * 10,
        search_text: this.state.searchText,
      },
      (result, res) => {
        if (result) {
          resultDelegate(res);
        } else {
          alert("네트워크 오류");
        }
      }
    );
  }

  selectLiveCurrent = (index) => {
    this.setState({ liveCurrent: index });
    this.requestLiveList(index);
  };

  selectEndCurrent = (index) => {
    this.setState({ endCurrent: index });
    this.requestExpireList(index);
  };

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd");
  }

  routeChange(path, search) {
    localStorage.setItem("liveCurrent", this.state.liveCurrent);
    localStorage.setItem("endCurrent", this.state.endCurrent);
    this.props.history.push({
      pathname: path,
      search: search,
    });
  }

  onSearch() {
    var text = document.getElementById("search_text").value;
    console.log(text);
    this.setState({ searchText: text }, () => {
      this.selectLiveCurrent(0);
      this.selectEndCurrent(0);
    });
  }

  requestReOpen(impressionId, campaignId) {
    request({
      "bk-working": "re-open-impression",
      "impression_id": impressionId,
      "campaign_id": campaignId
    }, (result, response) => {
      alert(result ? "성공" : "실패");
    })
  }
  
  render() {
    return (
      <>
        <AdHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <input type="text" id="search_text" placeholder="검색어" />
                  <Button
                    onClick={(e) => {
                      this.onSearch();
                    }}
                  >
                    검색
                  </Button>
                  <h3 className="mb-0">캠페인 목록</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">이름</th>
                      <th scope="col">사용자</th>
                      <th scope="col">노출수</th>
                      <th scope="col">클릭수</th>
                      <th scope="col">정답수</th>
                      <th scope="col">사용/제한</th>
                      <th scope="col">만료일</th>
                      <th scope="col">더보기</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.adList.map((page, i) => (
                      <tr key={i}>
                        <th scope="row">
                          <span className="mb-0 text-sm">
                            <Badge
                              color=""
                              className="badge-dot badge-lg mr-5 ml-2"
                            >
                              {page["impression_state"] === 0 ? (
                                <i className="bg-green" />
                              ) : page["impression_state"] === 1 ? (
                                <i className="bg-primary" />
                              ) : (
                                <i className="bg-warning" />
                              )}
                              {page["list_campaign_name"]}
                            </Badge>
                          </span>
                        </th>
                        <td>{page["user_nickname"]}</td>
                        <td>{page["list_view_count"]}</td>
                        <td>{page["list_click_count"]}</td>
                        <td>{page["list_answer_count"]}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">
                              {page["list_campaign_used"]}/
                              {page["list_campaign_limit"]}
                            </span>
                          </div>
                        </td>
                        <td>{this.dateFormatting(page["list_ad_expire"])}</td>
                        <td>
                          {/* <Button onClick={() =>
                            this.routeChange("adedit", "?campaign_id=" + page["campaign_id"] + "&impression_state=" + page["impression_state"] + "&impression_id=" + page["impression_id"])
                          }
                          >보기</Button> */}
                          <Button
                            href={
                              "adedit?campaign_id=" +
                              page["campaign_id"] +
                              "&impression_state=" +
                              page["impression_state"] +
                              "&impression_id=" +
                              page["impression_id"] +
                              "&expired=false"
                            }
                            target="_blank"
                          >
                            보기
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {navigation(
                    this.selectLiveCurrent,
                    this.state.liveCurrent,
                    this.state.liveCount,
                    10
                  )}
                </CardFooter>
              </Card>
            </div>
          </Row>
          {/* Dark table */}
          <Row className="mt-5">
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">중지된 캠페인</h3>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">이름</th>
                      <th scope="col">사용자</th>
                      <th scope="col">노출수</th>
                      <th scope="col">클릭수</th>
                      <th scope="col">전환수</th>
                      <th scope="col">사용/제한</th>
                      <th scope="col">만료일</th>
                      <th scope="col">더보기</th>
                      <th scope="col">재활성화</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.expireList.map((page, i) => (
                      <tr key={i}>
                        <th scope="row">
                          <span className="mb-0 text-sm">
                            {page["list_campaign_name"]}
                          </span>
                        </th>
                        <td>{page["user_nickname"]}</td>
                        <td>{page["list_view_count"]}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-warning" />
                            {page["list_click_count"]}
                          </Badge>
                        </td>
                        <td></td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">
                              {page["list_campaign_used"]}/
                              {page["list_campaign_limit"]}
                            </span>
                          </div>
                        </td>
                        <td>{this.dateFormatting(page["list_ad_expire"])}</td>
                        <td>
                        <Button
                            href={
                              "adedit?campaign_id=" +
                              page["campaign_id"] +
                              "&impression_state=" +
                              page["impression_state"] +
                              "&impression_id=" +
                              page["impression_id"] +
                              "&expired=true"
                            }
                            target="_blank"
                          >
                            보기
                          </Button>
                        </td>
                        <td>
                        <Button
                          onClick={() => this.requestReOpen(page["impression_id"], page["campaign_id"])}
                          >
                            재활성화
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="bg-default py-4">
                  {navigation(
                    this.selectEndCurrent,
                    this.state.endCurrent,
                    this.state.endCount,
                    10
                  )}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default AdAdminManage;
