/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useCallback } from "react";

// reactstrap components
import { Container, Table } from "reactstrap";
// core components
import { request } from "network/Request.js";
import { useParams } from "react-router";

/**
 * ad_count:10 answer_count:0 exam_count:0 fcfs_count:0 review_count:1
 */
const UserDetail = () => {
  const param = useParams();
  const [detail, setDetail] = useState();
  const [lastId, setLastId] = useState(1000000000);
  const [points, setPoints] = useState([]);
  const [page, setPage] = useState(0);
  const [isFetching, setFetching] = useState(false);
  const [hasNextPage, setNextPage] = useState(true);

  const fetchPoints = useCallback(async () => {
    request(
      {
        "bk-working": "user-point-list-admin",
        target_user_id: param["userId"],
        limit_range: 60,
        limit_offset: lastId,
      },
      (result, response) => {
        console.log(response);

        if (!result) {
          alert("오류");
          return;
        }

        let newPoints = response["limit_array"];
        if (newPoints.length <= 0) {
          setNextPage(false);
          return;
        }

        setLastId(response["limit_offset"]);
        setPoints([...points, ...newPoints]);
        setPage(page + 1);
        setFetching(false);
      }
    );
  }, [page]);

  useEffect(() => {
    let userId = param["userId"];
    request(
      { "bk-working": "user-detail", target_user_id: userId },
      (result, response) => {
        setDetail(response["detail_json"]);
        console.log(response);
      }
    );

    const handleScroll = () => {
      const { scrollTop, offsetHeight } = document.documentElement;
      if (window.innerHeight + scrollTop >= offsetHeight) {
        setFetching(true);
      }
    };
    setFetching(true);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isFetching && hasNextPage) fetchPoints();
    else if (!hasNextPage) setFetching(false);
  }, [isFetching]);

  return (
    <>
      <Container>
        {detail && (
          <div>
            콘텐츠 수: {detail["ad_count"]}
            <br />
            정답제출 수: {detail["answer_count"]}
            <br />
            시험참여 수: {detail["exam_count"]}
            <br />
            선착순 참여 수: {detail["fcfs_count"]}
            <br />
            복습 50포인트 수: {detail["review_count"]}
          </div>
        )}
      </Container>
      <br />
      <Table>
        <thead>
          <tr>
            <th>시간</th>
            <th>타입</th>
            <th>포인트</th>
          </tr>
        </thead>
        <tbody>
          {points.length === 0 && (
            <tr>
              <td></td>
            </tr>
          )}
          {points.map((point, index) => (
            <tr key={index}>
              <td>{new Date(point["time"]).toLocaleString()}</td>
              <td>{point["point_type"]}</td>
              <td>{point["point"]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
        {isFetching && <div>로딩중</div>}
    </>
  );
};

export default UserDetail;
