/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

class PartnerHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="pt-5 align-items-center"
          style={{
            position: "relative",
            minHeight: "250px",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}

        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
        </div>
      </>
    );
  }
}

export default PartnerHeader;
