import React from "react";
import { Container, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { request } from "network/Request.js"
import Button from "reactstrap/lib/Button";
// import YedTagInput from 'yed-tag-input'
// import 'yed-tag-input/dist/index.css'

const _examTypes = ["듣기", "리딩", "문법", "쓰기"];
const _answerTypes = ["객관식", "열거형", "주관식"];
const _sentenceTypes = ["문장", "음성"];

class EditExam extends React.Component {
    state = {
        examTypeDropdownOpen: false,
        answerTypeDropdownOpen: false,
        sentenceTypeDropdownOpen: false,
        setOpen: false,
        examTypeName: "없음",
        examTypeId: 0,
        answerTypeName: "없음",
        answerTypeId: 0,
        sentenceTypeName: "없음",
        sentenceTypeId: 0,
        hintTags: [],
        examId: 0,
        ad: null
    }
    
    componentDidMount() {
        const examId = this.getQueryVariable("exam_id");
        this.setState({examId: examId});
        this.requestExamInfo(examId);
    }

    requestAdDetail(adId) {
        request({
            "bk-working": "ad-detail",
            "ad_id": adId,
        }, (result, response) => {
            if (result) {
                const adJSON = response["ad_json"];
                this.setState({ad : adJSON}, () => console.log(this.state.ad));
                console.log(adJSON);
            }
        })
    }

    requestExamInfo(examId) {
        request({
            "bk-working": "exam-info",
            "exam_id": examId,
        }, (result, res) => {
            const examJSON = res["exam_json"];
            console.log(examJSON);
            if (result) {
                this.setExamType(examJSON["exam_type"]);
                this.setSentenceType(examJSON["sentence_type"]);
                this.setAnswerType(examJSON["answer_type"]);
                this.setState({
                    hintTags: examJSON["hint"],
                })
                document.getElementById("exam").value = examJSON["question"];
                document.getElementById("ad_id").value = examJSON["ad_id"];
                document.getElementById("sentence").value = examJSON["sentence"];
                document.getElementById("answer").value = examJSON["answer"];
                document.getElementById("time_limit").value = examJSON["time_limit_sec"];
                this.requestAdDetail(examJSON["ad_id"]);
            } else {
                alert("네트워크 오류")
            }
        })
    }

    requestNewExam() {
        var question = document.getElementById("exam").value;
        var adId = document.getElementById("ad_id").value;
        var sentence = document.getElementById("sentence").value;
        var answer = document.getElementById("answer").value;
        var timeLimit = document.getElementById("time_limit").value;
        var answerType = this.state.answerTypeId;
        var hintTags = this.state.hintTags;

        if (!this.checkAnswerValid(answerType, answer, hintTags)) {
            alert("보기 오류");
            return;
        }
        
        request({
            "bk-working": "edit-exam",
            "exam_json": {
                "episode_id": this.getQueryVariable("episode_id"),
                "exam_id": this.state.examId,
                "ad_id": adId,
                "exam_type": this.state.examTypeId,
                "exam_answer_type": answerType,
                "exam_question": question,
                "exam_sentence": sentence,
                "exam_sentence_type": this.state.sentenceTypeId,
                "exam_answer": answer,
                "exam_hint": JSON.stringify(hintTags),
                "exam_time_limit": timeLimit,
            }
        }, (result, res) => {
            if (result) {
                alert("good");
            } else {
                alert("네트워크 오류")
            }
        })
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }

    examTypeToggle = () => this.setState({ examTypeDropdownOpen: !this.state.examTypeDropdownOpen });

    answerTypeToggle = () => this.setState({ answerTypeDropdownOpen: !this.state.answerTypeDropdownOpen });

    sentenceTypeToggle = () => this.setState({ sentenceTypeDropdownOpen: !this.state.sentenceTypeDropdownOpen });

    setExamType(typeId) {
        this.setState({ examTypeName: _examTypes[typeId], examTypeId: typeId });
    }

    setAnswerType(typeId) {
        this.setState({ answerTypeName: _answerTypes[typeId], answerTypeId: typeId });
    }

    setSentenceType(typeId) {
        this.setState({ sentenceTypeName: _sentenceTypes[typeId], sentenceTypeId: typeId });
    }
    setTag = (tag) => {
        this.setState({ hintTags: [...this.state.hintTags, tag] });
    }

    deleteTag = (item) => {
        const deletedTags = this.state.hintTags;
        deletedTags.splice(item.index, 1)
        this.setState({ hintTags: deletedTags });
    }

    checkAnswerValid(answerType, answer, hintTags) {
        var valid = false;
        const spaceContain = /\r|\n|\t/.test(answer);
        if (spaceContain) {
            alert("빈 공간이 있습니다.");
            valid = false;
            return;
        }
        console.log(answerType + "," + answer + "," + hintTags);
        if (answerType === 0) {
            for (var i = 0; i < hintTags.length; i++) {
                if (hintTags[i] === answer)
                    valid = true;
            }
        } else if (answerType === 1) {
            var hintConcat = hintTags[0];
            for (i = 1; i < hintTags.length; i++) {
                hintConcat += " " + hintTags[i];
            }
            if (hintConcat === answer)
                valid = true;

        } else {
            valid = true;
        }
        return valid;
    }

    onAnserChange = (e) => {
        if (this.state.answerTypeId === 1) {
            var answer = e.target.value;
            var hints = answer.split(' ');
            this.setState({ hintTags: hints });
        }
    }

    render() {
        console.log(this.state.ad);
        return (
            <>
                <Container>
                    문제 등록하기
                    <br />
                    <br />
                    <ButtonDropdown isOpen={this.state.examTypeDropdownOpen} toggle={this.examTypeToggle}>
                        <DropdownToggle caret>
                            {this.state.examTypeName}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => this.setExamType(0, "듣기")} >듣기</DropdownItem>
                            <DropdownItem onClick={() => this.setExamType(1, "리딩")} >리딩</DropdownItem>
                            <DropdownItem onClick={() => this.setExamType(2, "문법")} >문법</DropdownItem>
                            <DropdownItem onClick={() => this.setExamType(3, "쓰기")} >쓰기</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                    <ButtonDropdown isOpen={this.state.answerTypeDropdownOpen} toggle={this.answerTypeToggle}>
                        <DropdownToggle caret>
                            {this.state.answerTypeName}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => this.setAnswerType(0, "객관식")} >객관식</DropdownItem>
                            <DropdownItem onClick={() => this.setAnswerType(1, "열거형")} >열거형</DropdownItem>
                            <DropdownItem onClick={() => this.setAnswerType(2, "주관식")} >주관식</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                    <ButtonDropdown isOpen={this.state.sentenceTypeDropdownOpen} toggle={this.sentenceTypeToggle}>
                        <DropdownToggle caret>
                            {this.state.sentenceTypeName}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => this.setSentenceType(0, "문장")} >문장</DropdownItem>
                            <DropdownItem onClick={() => this.setSentenceType(1, "음성주소")} >음성</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                    <Input id="ad_id" placeholder="콘텐츠 아이디"></Input>
                    <Input type="textarea" id="sentence" placeholder="문장"></Input>
                    <Input id="exam" placeholder="문제"></Input>
                    <Input id="answer" placeholder="정답" onChange={this.onAnserChange}></Input>
                    {/* <YedTagInput tags={this.state.hintTags} tagInputErrorControl={true} tagInpuTitle={'힌트나 보기'} onChange={this.setTag} removeTag={(item) => { this.deleteTag(item) }} /> */}
                    <Input id="time_limit" placeholder="시간제한(초)"></Input>
                    <Button onClick={() => this.requestNewExam()}>제출하기</Button>
                </Container>
                <br />
                <br />
                {this.state.ad && "영어문장:" + this.state.ad["ad_english"]}
                <br />
                {this.state.ad && "한글문장:" + this.state.ad["ad_translation"]}
                <br />
                {this.state.ad && "힌트:" + this.state.ad["ad_hint"]}
                <br />
                {this.state.ad && "영어문장:" + this.state.ad["ad_english"]}
                <br />
                {this.state.ad && "퀴즈:" + this.state.ad["ad_quiz"]}
                <br />
                {this.state.ad && "정답:" + this.state.ad["answer"]}
                <br />
                {this.state.ad && "해설:" + this.state.ad["explanation"]}
                <br />
            </>
        );
    }
}

export default EditExam;