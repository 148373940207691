import React from "react";
import AudioPlayer from "react-h5-audio-player";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  init,
  count,
  current,
  newAdClick,
  removeAdClick,
  bindUI,
  pageSelect,
  updateAd,
  setAdArray,
  selectAnswerType,
  selectSecondHint,
} from "controller/AdController.jsx";
import AdRemoveModal from "views/advertiser/AdRemoveModal.jsx";
import { request } from "network/Request";
import { getAdArray } from "controller/AdController";
import { WithContext as ReactTags } from "react-tag-input";
import { keyCodes } from "reactstrap/lib/utils";

class AdEdit extends React.Component {
  state = {
    count: count,
    current: current,
    formModal: false,
    campainId: 0,
    hintTags: [],
    answerTypeName: "주관식",
    answerTypeId: 1,
  };

  componentDidMount() {
    console.log(this.player.audio);
    bindUI(this.player);
    this.setAds(this.props.ads);
    console.log(this.props.ads);
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  setAds(ads) {
    init(this.setAnswerType, this.resetTag);
    setAdArray(ads);
    this.setState({
      count: ads.length,
    });
    this.handlePageClick(0);
  }

  newPage() {
    this.setState({
      count: count + 1,
    });
    newAdClick();
  }

  removeRequest() {
    this.refs.removeModal.toggleModal();
  }

  removePage = () => {
    if (count < 2) return;
    var newCurrent = removeAdClick();
    this.setState({
      current: newCurrent,
      count: this.state.count - 1,
    });
  };

  uploadAdCall() {
    updateAd(this.state.current);
    request(
      {
        "bk-working": "contents-edit",
        ad_array: getAdArray(),
      },
      () => alert("수정되었습니다.")
    );
  }

  handlePageClick(page) {
    console.log(page);
    pageSelect(page);
    this.setState({
      current: page,
    });
  }

  answerTypeToggle = () =>
    this.setState({
      answerTypeDropdownOpen: !this.state.answerTypeDropdownOpen,
    });

  setAnswerType = (typeId, name) => {
    if (typeId === this.state.answerTypeId) return;
    this.setState({ answerTypeName: name, answerTypeId: typeId });
    selectAnswerType(typeId);
  };

  resetTag = (initValue) => {
    this.setState({ hintTags: initValue });
  };

  setTag = (tag) => {
    const tagString = tag.text;
    this.setState({ hintTags: [...this.state.hintTags, tagString] });
    selectSecondHint([...this.state.hintTags, tagString]);
  };

  dragTag = (tag, currPos, newPos) => {
    const tagString = tag.text;
    const newTags = this.state.hintTags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tagString);

    // re-render
    this.setState({ hintTags: newTags });
  };

  deleteTag = i => {
    this.setState({
      hintTags: this.state.hintTags.filter((tag, index) => index !== i),
    });
  };

  onAnswerChange = (e) => {
    if (this.state.answerTypeId === 2) {
      var answer = e.target.value;
      var hints = answer.split(" ");
      this.setState({ hintTags: hints });
      selectSecondHint(hints);
      console.log(e);
    }
  };

  render() {
    return (
      <>
        <Card className="shadow border-0 mt-1 mb-3">
          <CardBody className="py-5">
            <Container className="">
              <Row className="mt-1">
                <Col className="sm-6" lg="6">
                  <h5>
                    <span className="badge badge-secondary">
                      광고 미디어 유형을 선택하세요.
                    </span>
                  </h5>
                  {/* {this.props.editable ?
                    <div id="resourceInput" className="custom-file col-sm">
                      <input accept="image/png image/jpeg video/mp4" type="file"
                        className="custom-file-input" id="ad-resource-input" name="filename" hidden />
                      <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                    </div>
                    : ""} */}
                  <div
                    className="ad-resource-container bg-secondary"
                    id="image_preview_container"
                  >
                    <img
                      id="image_preview"
                      src={
                        require("assets/img/theme/image_fallback.jpg").default
                      }
                      className="ad-resource"
                      alt="ad upload resource"
                      onError={() => this.imageError()}
                    />
                  </div>
                  <div
                    className="ad-resource-container mb-2"
                    id="video_preview_container"
                    hidden
                  >
                    <video
                      id="video_preview"
                      className="ad-resource"
                      controls
                    />
                  </div>
                  <h5>
                    <span className="badge badge-secondary">
                      지급될 리워드를 선택해주세요.
                    </span>
                  </h5>
                  <div className="was-validated input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">정답 당</span>
                    </div>
                    <input
                      id="cpco"
                      type="number"
                      className="form-control"
                      min="0"
                      disabled
                      defaultValue="0"
                      aria-label="Amount (to the nearest dollar)"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">point</span>
                    </div>
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="정답을 맞췄을 때 발생하는 광고 비용입니다."
                      >
                        ?
                      </button>
                    </div>
                  </div>
                  <h5>
                    <span className="badge badge-secondary">난이도</span>
                  </h5>
                  <form className="was-validated">
                    <textarea
                      className="form-control"
                      id="level"
                      disabled
                      placeholder="한글"
                      required
                    ></textarea>
                  </form>
                </Col>
                <Col className="col-sm ">
                  <div className="">
                    <h5>
                      <span className="badge badge-secondary">
                        영어 문장과 번역
                      </span>
                    </h5>
                    <form className="was-validated mb-1">
                      <textarea
                        className="form-control"
                        id="english"
                        disabled={!this.props.editable}
                        placeholder="영어"
                        required
                      ></textarea>
                    </form>
                    <AudioPlayer
                      ref={(c) => (this.player = c)}
                      id="audio-player"
                      className="mb-3"
                      src={this.props.ads[current].audio}
                      autoPlay
                      //              onPlay={e => console.log("onPlay")}
                    />
                    <form className="was-validated">
                      <textarea
                        className="form-control"
                        id="translation"
                        disabled={!this.props.editable}
                        placeholder="한글"
                        required
                      ></textarea>
                    </form>
                    <h5>
                      <span className="badge badge-secondary mt-2">
                        문제와 정답
                      </span>
                    </h5>
                    <ButtonDropdown
                      isOpen={this.state.answerTypeDropdownOpen}
                      toggle={this.answerTypeToggle}
                      disabled={!this.props.editable}
                    >
                      <DropdownToggle caret>
                        {this.state.answerTypeName}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => this.setAnswerType(0, "객관식")}
                        >
                          객관식
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.setAnswerType(1, "주관식")}
                        >
                          주관식
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.setAnswerType(2, "열거형")}
                        >
                          열거형
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                    <div className="row">
                      <form className="col-md-6 was-validated mb-1">
                        <textarea
                          disabled={!this.props.editable}
                          id="quiz"
                          className="form-control"
                          placeholder="유형"
                          required
                        />
                      </form>
                      <form className="col-md-6 was-validated mb-1">
                        <textarea
                          disabled={!this.props.editable}
                          id="answer"
                          className="form-control"
                          placeholder="정답"
                          required
                          onChange={(e) => this.onAnswerChange(e)}
                        />
                      </form>
                    </div>
                    <h5>
                      <span className="badge badge-secondary mt-2">문제</span>
                    </h5>
                    <form className="mb-1">
                      <textarea
                        id="hint"
                        className="form-control"
                        disabled={!this.props.editable}
                        placeholder=""
                        required
                      ></textarea>
                    </form>
                    {this.state.answerTypeId !== 1 ? (
                      <ReactTags
                        tags={this.state.hintTags.map((item) => ({id: item, text: item}))}
                        delimiters={[keyCodes.enter]}
                        handleDelete={this.deleteTag}
                        handleAddition={this.setTag}
                        handleDrag={this.dragTag}
                      />
                    ) : (
                      // <YedTagInput
                      //   tags={this.state.hintTags}
                      //   tagInputErrorControl={true}
                      //   tagInpuTitle={"선택지"}
                      //   onChange={this.setTag}
                      //   removeTag={(item) => {
                      //     this.deleteTag(item);
                      //   }}
                      // />
                      "no"
                    )}
                    <h5>
                      <span className="badge badge-secondary mt-2">해설</span>
                    </h5>
                    <form className="mb-1">
                      <textarea
                        id="explanation"
                        className="form-control"
                        disabled={!this.props.editable}
                        placeholder=""
                        required
                      ></textarea>
                    </form>
                  </div>
                </Col>
              </Row>
              <div className="container row">
                <div className="col-sm"></div>
              </div>
              <div className="container mt-3">
                <nav aria-label="Page navigation example">
                  <ul
                    className="pagination-sm justify-content-center"
                    id="pagination"
                  ></ul>
                </nav>
              </div>

              <Pagination
                className="pagination justify-content-center mb-0"
                listClassName="justify-content-end mb-0"
              >
                {[...Array(this.state.count)].map((page, i) => (
                  <PaginationItem active={i === this.state.current} key={i}>
                    <PaginationLink onClick={(e) => this.handlePageClick(i)}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                {/* {this.props.editable ?
                  <form className="text-right mb-2 ml-3">
                    <Button className="btn btn-primary" size="sm" type="button" onClick={() => this.removeRequest()}>삭제</Button>
                    <Button className="btn btn-primary" size="sm" type="button" onClick={(e) => this.newPage()}>추가</Button>
                  </form>
                  : ""} */}
              </Pagination>
            </Container>
          </CardBody>
        </Card>
        <AdRemoveModal ref="removeModal" removeUpdate={this.removePage} />
      </>
    );
  }
}

export default AdEdit;
