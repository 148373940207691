import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";
import EventAdRegister from "views/service/freead_event/EventAdRegister.jsx"
import EventCampaignRegister from "views/service/freead_event/EventCampaignRegister.jsx"
import AuthModal from 'views/auth/AuthModal.jsx'

class EventAdTotalRegister extends React.Component {
  state = {
    termCheck: false
  }

  termCheck(check) {
    this.setState({ termCheck: check });
  }

  componentDidMount() {
  }

  submit = () => {
    var campaign = this.refs.campaignregister.getCampaign();
    this.refs.adregister.uploadAdCall(campaign);
  }

  render() {
    return (
      <>
        <div className="">
        <div
          className="pt-5 align-items-center"
          style={{
            position: "relative",
            minHeight: "180px",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}

        >
          {/* Mask */}
          <span className="mask bg-gradient-info opacity-8" />
          {/* Header container */}
        </div>
          <Container className="mt--9" id="adregister_campaign">
            <EventCampaignRegister ref="campaignregister" />
          </Container>
          <Container id="adregister_ad">
            <EventAdRegister ref="adregister" />
          </Container>
          <div className="text-center">

            <h6 className="custom-control-label" htmlFor="term_check">
                이 페이지를 통해 신청된 광고의 결과는 마케팅적으로 활용될 수 있습니다.
                      </h6>
          <Button size="sm" className="mt-3" onClick={() => window.open("https://www.notion.so/1-9b101d086f174123be6ec10312b9c3c3")}>
              이 링크에 해당하는 광고는 승인되지 않습니다.
                      </Button>
            <Button size="sm" className="mt-3" onClick={() => window.open("https://learnward-terms.s3.ap-northeast-2.amazonaws.com/advertiser_term.txt")}>
              여기를 눌러 광고주 약관을 보고 동의해주세요.
                      </Button>

            <div className="mt-1 custom-control custom-checkbox">
              <input
                onChange={(e) => this.termCheck(e.target.checked)}
                className="custom-control-input"
                id="term_check"
                type="checkbox"
              />
              <label className="custom-control-label" htmlFor="term_check">
                광고주 약관을 충분히 숙지하였으며 약관에 동의합니다.
                      </label>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-round"
                color="default"
                size="lg"
                type="button"
                onClick={() => this.state.termCheck ? this.submit() : alert("약관에 먼저 동의해주세요.")}
              >
                신청하기
                        </Button>
            </div>
          </div>
          <AuthModal ref="loginmodal" updateUser={this.props.updateUser} />
          <br />
          <br />
        </div>
      </>
    );
  }
}

export default EventAdTotalRegister;
