/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Navigate } from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from "reactstrap";
import {digestMessage, hexString, request} from "network/Request.js"

class LoginCommon extends React.Component {
    state = {
        email: null,
        token: null
    }

    componentDidMount() {
        var email = this.getQueryVariable("email");
        var token = this.getQueryVariable("token");
        if (email && token) {
            this.setState({
                email: email,
                token: token
            })
        }

    }

    confirm() {
        var password = document.getElementById("login_password").value;
        digestMessage(`keyasalt${password}`) // Hashing
        .then(digestValue => {
          const hashed = hexString(digestValue);
          let data = {
            // Might be able to reduce extra code (DRY method)
            "bk-working": "change-password-common", //will change for some calls
            "user_email": this.state.email,
            "find_password_token": this.state.token,
            password: hashed.toLowerCase(),
          }
          request(data, (result, res) => {
              if (result) {
                  alert("비밀번호 변경이 완료되었습니다. 비밀번호 찾기 링크가 만료됩니다.");
              } else {
                  alert("비밀번호 변경이 실패하였습니다.");
              }
          })
        });
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }
    
    render() {
        if (this.state.dest) {
            return <Navigate to={(this.state.layout ? this.state.layout : "") + this.state.dest} />
        }
        return (
            <>
                <Col >
                <br/>
                <br/>
        <h2> {this.state.email} 계정의 비밀번호를 변경하실 수 있습니다.</h2>
                    <Card className="mt-5 bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        <Input id="login_password" placeholder="Password" type="password" />
                                    </InputGroup>
                                </FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id=" customCheckLogin"
                                        type="checkbox"
                                    />                                </div>
                                <div className="text-center">
                                    <Button className="" color="primary" type="button" onClick={e => this.confirm()}>
                                        변경하기
                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                    <Row className="mt-3">
                    </Row>
                </Col>
            </>
        );
    }
}

export default LoginCommon;
