/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class CommonFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col>
                <div className="copyright text-center text-xl-left text-muted">
                  © 2021 키아니스트, 사업자번호:457-07-02028, 대표자:안영수
                  <a
                    className="font-weight-bold ml-1"
                    href="mailto:support@learnward.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    support@learnward.com
              </a>
                </div>
                <div className="copyright text-center text-xl-left text-muted ml">
                  울산광역시 중구 태화로 210-1 4층
                  <a
                    className="font-weight-bold ml-3"
                    href="https://resource.learnward.com/terms/advertiser_term.txt"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    광고주약관
              </a>
              <a
                    className="font-weight-bold ml-3"
                    href="https://resource.learnward.com/terms/service_term.txt"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    서비스이용약관
              </a>
                  <a
                    className="font-weight-bold ml-3"
                    href="https://resource.learnward.com/terms/privacy_term.txt"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    개인정보처리방침
              </a>
                </div>
              </Col>
              {/* <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink
                      target="_blank"
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      target="_blank"
                    >
                      Blog
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      // href=""
                      target="_blank"
                    >
                      Opensource License
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col> */}
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CommonFooter;
