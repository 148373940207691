// Login.js
import { request } from "network/Request";
import React, { useState } from "react";
import { appleAuth, googleAuth } from "../Firebase";

const Withdrawal = () => {
  const [email, setUserEmail] = useState("");
  const [password, setPassword] = useState("");

  const digestMessage = async (message) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return await window.crypto.subtle.digest("SHA-256", data);
  };

  const hexString = (buffer) => {
    const byteArray = new Uint8Array(buffer);

    const hexCodes = [...byteArray].map((value) => {
      const hexCode = value.toString(16);
      const paddedHexCode = hexCode.padStart(2, "0");
      return paddedHexCode;
    });

    return hexCodes.join("");
  };

  const handleChangeEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const googleWithdrawal = () => {
    googleAuth().then((result) => {
      if (result.credential) {
        result.user.getIdToken().then((token) => {
          this.handleSubmit(1, token);
        });
      }
    });
  };

  const appleWithdrawal = () => {
    appleAuth().then((result) => {
      if (result.credential) {
        result.user.getIdToken().then((token) => {
          this.handleSubmit(2, token);
        });
      }
    });
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (type = 0, token = null) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      const digestValue = await digestMessage(`ufo${password}`); // Hashing;
      const hashed = hexString(digestValue);

      request(
        {
          "bk-working": "withdrawal-web",
          login_type: type,
          thirdparty_token: token,
          user_email: email,
          password: hashed.toLowerCase(),
        },
        (result, response) => {
          alert(result ? "성공" : "실패");
        }
      );
    }
  };

  return (
    <div className="login-container">
      <div className="logo">
        <h1>UFO 계정 탈퇴</h1>
      </div>

      <form>
        <div className="input-group">
          <label htmlFor="userId">이메일</label>
          <input
            type="text"
            id="userId"
            value={email}
            onChange={handleChangeEmail}
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">비밀번호</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handleChangePassword}
          />
        </div>
        <div className="link-group">
          <button onClick={handleSubmit}>탈퇴</button>
        </div>
      </form>

      <div className="social-login">
        <div className="divider-with-text">소셜 로그인을 이용해 탈퇴</div>
        <div className="btn-wrapper text-center">
          <img
            height={49}
            onClick={(e) => googleWithdrawal()}
            alt="..."
            src={require("assets/img/icons/common/google_login.png")}
          />
          <img
            height={44}
            onClick={(e) => appleWithdrawal()}
            alt="..."
            src={require("assets/img/icons/common/apple_login.png")}
          />
        </div>
        <div className="btn-box"></div>
      </div>
    </div>
  );
};

export default Withdrawal;
