/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { digestMessage, hexString, request } from "network/Request.js"
import { googleAuth } from "views/service/Firebase.jsx";

class Signup extends React.Component {
  state = {
    redirect: false,
    check: false,
    email: null,
    nickname: null,
    password: null,
    time: {}, seconds: 0,
    type: 0, token: "",
  };

  constructor() {
    super();
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  };

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    this.setState({ seconds: 180 }, () => {
      if (this.timer === 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      };
    });
  }

  googleJoin() {
    if (!document.getElementById("privacyTerm").checked || !document.getElementById("serviceTerm").checked) {
      alert("모두 동의해야 다음으로 넘어 갈 수 있습니다.");
      return;
    }

    googleAuth().then((result) => {
      if (result.credential) {
        result.user.getIdToken().then((token) => {
          this.sendBase(result.user.email, "firebase_learnward" + result.user.email, "", 1, token);
        })
      }
    });
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds < 10 ? "0" + seconds : seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  isEmail(email) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  };

  dupCheck() {
    var name = document.getElementById("user_name").value;
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    if (!this.isEmail(email)) {
      alert("올바른 메일 형태가 아닙니다.");
      return;
    }
    if (password.length < 8) {
      alert("비밀번호를 최소 8자 입력해주세요.");
      return;
    }
    digestMessage(`keyasalt${password}`) // Hashing
      .then(digestValue => {
        this.sendBase(email, name, hexString(digestValue));
      });
  }

  sendBase(email, name, password, type = 0, token = null) {
    if (!document.getElementById("privacyTerm").checked || !document.getElementById("serviceTerm").checked) {
      alert("모두 동의해야 다음으로 넘어 갈 수 있습니다.");
      return;
    }

    let data = {
      // Might be able to reduce extra code (DRY method)
      "bk-working": "user-duplicate", //will change for some calls
      "user_nickname": name,
      "user_email": email,
    };
    request(data, (result, receive) => {
      if (result) {
        this.setState({ check: true, email: email, nickname: name, password: password, type: type, token: token });
      } else {
        alert(receive["duplicate_field"] + "이 이미 존재합니다.");
      }
    })
  }

  requestPhoneCode() {
    this.setState({ seconds: 180 }, () => {
      if (this.timer === 0 && this.state.seconds > 0) {
        var phone = document.getElementById("phone").value;

        let data = {
          "bk-working": "register-phone",
          "user_phone": phone,
          "user_country": "82",
        };
        request(data, (result, receive) => {
          if (result) {
            this.startTimer();
          } else {
            alert(receive.result_detail);
          }
        })
      };
    });
  }

  signup() {
    var phone = document.getElementById("phone").value;
    var smsCode = document.getElementById("sms_code").value;
    let data = {
      // Might be able to reduce extra code (DRY method)
      "bk-working": "join", //will change for some calls
      "login_type": this.state.type,
      "user_nickname": this.state.nickname,
      "user_country": "82",
      "user_phone": phone,
      "sms_code": smsCode,
      "thirdparty_token": this.state.token,
      user_email: this.state.email,
      password: this.state.password,
    };
    request(data, (result, receive) => {
      if (result) {
        window.history.back();
      } else {
        console.log(receive);
        if (receive["bk-result-from"] === "f_phone_code")
          alert("인증번호가 잘못 되었습니다");
        else if (receive["bk-result-from"] === "f_join")
          alert("이메일이나 닉네임이 중복됩니다.");
        else
          alert("회원가입에 실패하였습니다.")
      }
    })
  }

  baseInfo() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <Row>
                <Col>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="serviceTerm"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="serviceTerm"
                    >
                      <span className="text-muted">
                        <a href="https://resource.learnward.com/terms/service_term.txt">
                          서비스 이용약관
                          </a>
                          을 확인하였고 동의합니다.{" "}
                      </span>
                    </label>
                  </div>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="privacyTerm"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="privacyTerm"
                    >
                      <span className="text-muted">
                        <a href="https://resource.learnward.com/terms/privacy_term.html">
                          개인정보 처리방침
                          </a>
                          을 확인하였고 동의합니다.{" "}
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">

              <div className="text-muted text-center mb-2">
                <small>Sign up with</small>
              </div>
              <div className="text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  onClick={e => this.googleJoin()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg").default}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
              <div className="text-center text-muted mb-4 mt-4">
                <small>Or sign up with credentials</small>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    <Input id="user_name" placeholder="닉네임" type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    <Input id="email" placeholder="이메일" type="email" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    <Input id="password" placeholder="비밀번호" type="password" />
                  </InputGroup>
                </FormGroup>
                {/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={() => this.dupCheck()}>
                    다음으로
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }

  phone() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>휴대폰 정보를 입력해주세요.</small>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input id="phone" placeholder="휴대폰 번호" />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="mb-4" color="primary" type="button" disabled={this.state.seconds > 0} onClick={() => this.requestPhoneCode()}>
                    인증번호 전송
                </Button>
                </div> {
                  this.state.seconds > 0 ?
                    <Col>
                      <h1 className="text-center" style={{ fontSize: 15 }}>문자로 인증번호가 발송되었습니다. 3분안에 인증을 완료해주세요.
                    </h1>
                      <h1 className="text-center" style={{ fontSize: 15 }}>{this.state.time.m}:{this.state.time.s < 10 ? "0" + this.state.time.s : this.state.time.s}
                      </h1></Col>
                    : ""
                }
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input id="sms_code" placeholder="인증번호" />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="" color="primary" type="button" onClick={e => this.state.seconds > 0 ? this.signup() : e.preventDefault()} disabled={this.state.seconds > 0 ? false : true}>
                    계정 생성
                </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }

  render() {
    return this.state.check ? this.phone() : this.baseInfo();
  }
}

export default Signup;
