import React from "react";

// reactstrap components
import {
  Button, Modal
} from "reactstrap"
import AdSimpleRegister from "views/advertiser/AdSimpleRegister.jsx"
import CampaignRegister from "views/advertiser/CampaignRegister.jsx"
import AuthModal from 'views/auth/AuthModal.jsx'
import { isSignIn } from "network/Request";
import AdHeader from "components/Headers/AdHeader.jsx";

class AdTotalRegister extends React.Component {
  state = {
    exampleModal: false
  };
  
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  
  submit = () => {
    this.toggleModal();
    if (!isSignIn()) {
      this.refs.loginmodal.toggleModal();
      return;
    }
    var campaign = this.refs.campaignregister.getCampaign();
    this.refs.adregister.uploadAdCall(campaign);
  }

  reconfirmModal = () => {
    return <Modal
      className="modal-dialog-centered"
      isOpen={this.state.exampleModal}
      toggle={() => this.toggleModal("exampleModal")}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          광고를 등록하시겠습니까?
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => this.toggleModal("exampleModal")}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">약관의 환불 규정을 확인하시고 확인을 눌러주세요.</div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => this.toggleModal("exampleModal")}
        >
          취소
        </Button>
        <Button color="primary" type="button" onClick={() => this.submit()}>
          확인
        </Button>
      </div>
    </Modal>
  }

  render() {
    return (
      <>
        <div className="">
          <AdHeader />
          <div className="mt--8" id="adregister_campaign">
            <CampaignRegister ref="campaignregister" />
          </div>
          <div id="adregister_ad">
            <AdSimpleRegister ref="adregister" />
          </div>
          <div className="mt-1 text-center">
            <button type="button" className="btn btn-primary" onClick={() => this.toggleModal("exampleModal")}>제출</button>
          </div>
          {this.reconfirmModal()}
          <AuthModal ref="loginmodal" updateUser={this.props.updateUser} />
        </div>
      </>
    );
  }
}

export default AdTotalRegister;
