import { request } from "network/Request.js";
import imageCompression from 'browser-image-compression';

export function requestUser(callback) {
  let date = {
      "bk-working": "account"
  };

  request(date, (result, res) => {
      if (result) {
          callback(res["account_json"]);
      }
  });
}

export function requestAdvertiser(callback) {
    let date = {
        "bk-working": "advertiser"
    };

    request(date, (result, res) => {
        if (result) {
            callback({...res["account_json"], "coin": res["coin"]});
        }
    });
}

export async function resizeImage(file) {
  const options = { 
    maxSizeMB: 2, 
    maxWidthOrHeight: 500
  }
  if (file === null)
    return;
  try {
    var result = await imageCompression(file, options);
    return new File([result], result.name);
  } catch (error) {
    console.log(error);
  }
}
