/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

function AdvertiserHeader({onChange, onSubmit}) {
  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "500px",
          backgroundImage:
            'url("' +
            require("assets/img/theme/profile-cover.jpg") +
            '")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">광고주 등록하기</h1>
              <p className="text-white mt-0 mb-4">
                런워드와 파트너 전문가들이 광고를 유익한 교육 콘텐츠로 재생산합니다.
                한번 등록한 광고는 지속적으로 사용자에게 노출됩니다.
              </p>
              
              <div className="mt-1 ">
                <input
                  onChange={(e) => onChange(e.target.checked)}
                  className=""
                  id="term_check"
                  type="checkbox"
                />
                <label style={{ fontSize: 13, color: "white" }} className="ml-2" htmlFor="term_check">
                  광고주 약관을 충분히 숙지하였으며 약관에 동의합니다.
                      </label>
                      <Link
                      style={{ fontSize: 13}}
                      to="#"
                      className="font-weight-bold text-info ml-1"
                      onClick={() => window.open("https://learnward-terms.s3.ap-northeast-2.amazonaws.com/advertiser_term.txt")}
                    >
                      약관 보기
                  </Link>
              </div>
              <Button
                className="btn-neutral"
                color="default"
                onClick={() => onSubmit()}
              >
                신청하기
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AdvertiserHeader;
