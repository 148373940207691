/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
// learnward style
import "assets/css/learnward.css"

import AdvertiserLayout from "layouts/Advertiser.jsx";
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import PartnerLayout from "layouts/Partner.jsx"
import Landing from "views/service/Landing.jsx"
import NoticeList from "views/service/NoticeList.jsx"
import Notice from "views/service/Notice.jsx"
import ChangePassword from "views/auth/ChangePassword.jsx"
import EventAdTotalRegister from "views/service/freead_event/EventAdTotalRegister";
import Withdrawal from "views/service/withdrawal/Withrawal";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/freead" element={ <EventAdTotalRegister />} />
      <Route path="/changepassword/*" element={<ChangePassword />} />
      <Route path="/noticelist/*" element={<NoticeList />} />
      <Route path="/notice/*" element={<Notice />} />
      <Route path="/landing" element={<Landing />} />
      <Route path="/partner/*" element={<PartnerLayout />} />
      <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/advertiser/*" element={<AdvertiserLayout />} />
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="/withdrawal/" element={<Withdrawal />} />
      <Route path="/" element={<Navigate to="/landing" />} />
    </Routes>
  </BrowserRouter >,
);
