/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and `this` permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { isSignIn, request } from "network/Request.js"

import AdminRoutes from "AdminRoutes.js";

function Admin(props) {
  const [admin, setAdmin] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [permissionGroup, setPemissionGroup] = useState("nopermission");
  const mainContent = useRef(React.createRef());

  const signed = () => {
    return isSignIn();
  }

  useEffect(() => {
    requestIsAdmin();
  }, [])


  const requestIsAdmin = () => {
    let data = {
      "bk-working": "is-admin"
    }

    request(data, ((result, res) => {
      if (result) {
        console.log(res);
        setAdmin(true);
        setPemissionGroup(res["permission_group"]);
      }
    }));
  }

  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return "dark";
  };

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }

  const restoreAdminRoutes = () => {
    return AdminRoutes.filter((value) => {
      if ((value.permission && value.permission.includes(permissionGroup))) {
        return true;
      } else if (!value.permission) {
        return true;
      }
      return false;
    });
  }

  const getRoutes = (AdminRoutes) => {
    return AdminRoutes.map((prop, key) => {
      if (
        prop.layout === "/admin" &&
        prop.permission &&
        prop.permission.includes(permissionGroup)
      ) {
        return signed() ? (
          <Route
            element={<prop.component />}
            path={prop.path + "/*"}
            key={key}
          />
        ) : (
          <Route
            path="*"
            element={
              <Navigate
                to={"/auth/login?dest=" + prop.path + "&layout=" + prop.layout}
                key={key}
              />
            }
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < AdminRoutes.length; i++) {
      return AdminRoutes[i].name;
    }
    return "Brand";
  };

  return (
    <>

      {admin ?
        <div>
          <Sidebar
            scrollToTop={scrollToTop}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            {...props}
            routes={restoreAdminRoutes()}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("assets/img/brand/learnward.png"),
              imgAlt: "..."
            }}
            pageTitle="관리자"

          >런워드</Sidebar>
          <div className="main-content" ref={mainContent}>
            <AdminNavbar
              theme={getNavbarTheme()}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              brandText={getBrandText()}
            />
            <Routes>{getRoutes(restoreAdminRoutes())}</Routes>

          </div>
          {sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={toggleSidenav} />
          ) : null}
        </div>
        : "관리자가 아닙니다."}
    </>
  );
}

export default Admin;
