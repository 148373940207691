/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row
} from "reactstrap";
// core components
import AdHeader from "components/Headers/AdHeader.jsx";
import { request } from "network/Request.js"
import {navigation} from "components/Nav/Nav.jsx"
import dateFormat from "dateformat";

class NotificationList extends React.Component {
  state = {
    page: 0,
    notiList: [],
    notiSize: 0,
  }

  componentDidMount() {
    this.requestNotiList(0);
  }

  requestNotiList(offset) {
    let data = {
      "bk-working": "noti-list",
      "noti_type": "advertiser",
      "limit_offset": offset * 10,
      "limit_range": 10,
    }

    request(data, ((result, res) => {
      console.log(res);
      if (result) {
        this.setState({
          notiList: res["limit_array"],
          notiSize: res["noti_size"],
        })
      }
    }));
  }

  selectNotiCurrent = (index) => {
    this.setState({ page: index })
    this.requestNotiList(index);
  }

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd")
  }
  
  routeChange(path) {
    window.history.push(path);
  }

  render() {
    return (
      <>
        <AdHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">알림 내역</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">제목</th>
                      <th scope="col">내용</th>
                      <th scope="col">날짜</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.notiList.map((page, i) => (
                      <tr key={i}>                        
                        <td>{page["noti_title"]}</td>
                        <td>{page["noti_body"]}</td>
                        <td>
                            {this.dateFormatting(page["time_millisec"])}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {navigation(this.selectNotiCurrent, this.state.page, this.state.notiSize, 10)}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default NotificationList;
