/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { request } from "network/Request.js"
import Button from "reactstrap/lib/Button";
import Input from "reactstrap/lib/Input";
import Datetime from 'react-datetime';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

class NewEpisode extends React.Component {
    state = {
        start: this.currentDate().setSeconds(0),
        end: this.currentDate().setSeconds(0),
        point: 0,
    };

    currentDate() {
        var date = new Date();
        date.setMinutes(0);
        return date;
    }

    requestNewEpisode() {
        var divisionPoint = document.getElementById("point").value;
        request({
            "bk-working": "new-episode",
            "start_time": this.state.start.getTime(),
            "end_time": this.state.end.getTime(),
            "division_point": divisionPoint,
        }, (result, res) => {
            if (result) {
                alert("good");
            } else {
                alert("네트워크 오류")
            }
        })
    }
    
    render() {
        return (
            <>
                <div className="col">
                <br/>
                <br/>
                포인트
                    <Input id="point" plcaeceholder="point"/>
시작일
                    <Datetime
                  className="mt-2"
                  value={this.state.start}
                  onChange={(time) => this.setState({start: new Date(moment(time).format("YYYY-MM-DDTHH:mm:ss+09:00"))})}
                />종료일
                    <Datetime
                  className="mt-2"
                  value={this.state.end}
                  onChange={(time) => this.setState({end: new Date(moment(time).format("YYYY-MM-DDTHH:mm:ss+09:00"))})}
                />
                </div>
                <Button onClick={() => this.requestNewEpisode()}>
                    제출하기
                </Button>
            </>
        );
    }
}

export default NewEpisode;
