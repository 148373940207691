/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Input,
  Col,
  Badge,
  Collapse,
} from "reactstrap";
import { request } from "network/Request.js";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
registerLocale("ko", ko);

class CampaignEdit extends React.Component {
  state = {
    impressionId: 0,
    campaignName: "",
    campaignType: "translate",
    limit: -1,
    expire: new Date(),
    isOpen: false,
    genderSelect: "all",
    minOpen: false,
    minBirth: new Date(),
    maxOpen: false,
    maxBirth: new Date(),
    url: "",
    editable: this.props.editable ?? false,
  };

  componentDidMount() {
    this.requestImpression();
  }

  target() {
    const toggle = () => this.setState({ isOpen: !this.state.isOpen });

    return (
      <div className="mt-3">
        <Badge className="badge-default badge-secondary" pill>
          세부설정(선택사항)
        </Badge>
        <Button
          className="mt-3 ml-3"
          size="sm"
          color="primary"
          onClick={toggle}
          style={{ marginBottom: "1rem" }}
        >
          펼치기
        </Button>
        <Collapse isOpen={this.state.isOpen}>
          <Card>
            <CardBody>
              <Badge className="badge-default badge-secondary mt-3" pill>
                성별제한
              </Badge>
              <br />

              <ButtonGroup className="mt-3">
                <Button
                  onClick={(e) => e.preventDefault()}
                  active={this.state.genderSelect === "all"}
                >
                  전체
                </Button>
                <Button
                  onClick={(e) => e.preventDefault()}
                  active={this.state.genderSelect === "m"}
                >
                  남자
                </Button>
                <Button
                  onClick={(e) => e.preventDefault()}
                  active={this.state.genderSelect === "w"}
                >
                  여자
                </Button>
              </ButtonGroup>
              <br />
              <Badge className="badge-default badge-secondary mt-3" pill>
                최소연령 제한
              </Badge>
              <Collapse isOpen={this.state.minOpen}>
                <DatePicker
                  disabled
                  locale="ko"
                  showYearDropdown
                  className="mt-2"
                  selected={this.state.minBirth}
                  onChange={(date) => this.setState({ minBirth: date })}
                />
              </Collapse>
              <br />
              <Badge className="badge-default badge-secondary mt-3" pill>
                최대연령 제한
              </Badge>
              <Collapse isOpen={this.state.maxOpen}>
                <DatePicker
                  disabled
                  locale="ko"
                  showYearDropdown
                  className="mt-2"
                  selected={this.state.maxBirth}
                  onChange={(date) => this.setState({ maxBirth: date })}
                />
              </Collapse>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }

  getValueIfNotNull(field) {
    var obj = document.getElementById(field);
    if (obj !== null) {
      return obj.value;
    }
    return null;
  }

  requestImpression() {
    request(
      {
        "bk-working": this.props.working,
        campaign_id: this.props.campaignId,
        expired: this.props.expired ?? false,
      },
      (result, receive) => {
        console.log(receive);
        if (result) {
          var impressionJSON = receive["impression_json"];
          this.setState({
            impressionId: impressionJSON["impression_id"],
            campaignName: impressionJSON["campaign_name"],
            campaignType: impressionJSON["campaign_type"],
            limit: impressionJSON["impression_limit"],
            minOpen: impressionJSON["use_min_age"],
            maxOpen: impressionJSON["use_max_age"],
            minBirth: new Date(impressionJSON["max_age"]),
            maxBirth: new Date(impressionJSON["min_age"]),
            genderSelect:
              impressionJSON["gender"] === 1
                ? "m"
                : impressionJSON["gender"] === 2
                ? "w"
                : "all",
            url: impressionJSON["campaign_url"],
            expire: new Date(impressionJSON["campaign_expire"]),
          });
          new Date();
          console.log(receive);
        } else {
          alert("네트워크 에러");
        }
      }
    );
  }

  handleChange = (date) => {
    this.setState({
      expire: date,
    });
  };

  campaignTypeSelect() {}

  edit() {
    console.log(this.state.limit, this.state.expire.getDate())
    request(
      {
        "bk-working": "change-impression-limit",
        "impression_limit": this.state.limit,
        "campaign_expire": this.state.expire.getTime(),
        "update_impression": true,
        "impression_expired": this.props.expired,
        "impression_id": this.state.impressionId,
      },
      (result, response) => {
        alert(result ? "성공": "실패");
      }
    );
  }

  render() {
    return (
      <>
        <Col className="">
          <Card className="shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Badge className="badge-default badge-secondary" pill>
                캠페인 이름
              </Badge>
              <form className="was-validated mb-1">
                <Input
                  className="mt-2 form-control"
                  id="campaign_name"
                  maxLength={30}
                  value={this.state.campaignName}
                  disabled={true}
                ></Input>
              </form>
              <select
                id="campaignTypeSelect"
                className="custom-select"
                defaultValue={this.state.campaignType}
                disabled={true}
              >
                <option value="translate">영어</option>
                <option value="single">맞춤법</option>
              </select>

              <div className="mb-4 mt-4">
                <br />
                <Badge className="badge-default badge-secondary" pill>
                  사용할 코인
                </Badge>
                <Input
                  className="mt-2"
                  id="campaign_limit"
                  min="0"
                  value={this.state.limit}
                  disabled={!this.state.editable}
                  onChange={(e) =>
                    this.setState({ ...this.state, limit: e.target.value })
                  }
                ></Input>
                <Badge className="badge-default badge-secondary mt-4" pill>
                  url
                </Badge>
                <Input
                  className="mt-2"
                  id="campaign_url"
                  disabled
                  defaultValue={this.state.url}
                ></Input>

                <Badge className="badge-default badge-secondary mt-3" pill>
                  만료일
                </Badge>
                <br />
                <DatePicker
                  disabled={!this.state.editable}
                  className="mt-2"
                  selected={this.state.expire}
                  onChange={this.handleChange}
                />
                <br />
                {this.target()}

                <Button onClick={() => this.edit()}>수정완료</Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default CampaignEdit;
