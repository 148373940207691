import React from "react";

// reactstrap components
// core components
import { request } from "network/Request.js"
import Container from "reactstrap/lib/Container";
import CommonFooter from "components/Footers/CommonFooter";

class Notice extends React.Component {
    state = {
        notice: {}
    }
    componentDidMount() {
        var noticeId = this.getQueryVariable("notice_id");
        this.requestNotice(noticeId);
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }

    requestNotice(noticeId) {
        let data = {
            "bk-working": "notice",
            "notice_id": noticeId
        };
        request(data, (result, res) => {
            console.log(res);
            this.setState({notice : res["notice_json"]});
        });
    }

    render() {
        return (
            <>
            <Container>
                <h4 className="mt-3">{this.state.notice["notice_subject"]}</h4>
                <hr
                    style={{
                        margin: 0,
                        color: "#ffffff",
                        backgroundColor: "ffffff",
                        height: 1
                    }}
                />
                <h5 className="mt-2">{this.state.notice["notice_body"]}</h5>
                <CommonFooter/>
                </Container>
          </>
        )
    }
}

export default Notice;
