/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row
} from "reactstrap";
// core components
import AdHeader from "components/Headers/AdHeader.jsx";
import { request } from "network/Request.js"
import {navigation} from "components/Nav/Nav.jsx"
import dateFormat from "dateformat";

class PurchaseList extends React.Component {
  state = {
    liveCount: 0,
    liveCurrent: 0,
    purchaseList: [],
  };

  componentDidMount() {
    this.requestLiveList(0);
  }

  requestLiveList(index) {
    this.requestList("purchase-list", index, (res) => {
      console.log(res);
      this.setState({
        purchaseList: res["list_array"],
        liveCount: res["count"]
      })
    })
  }
  
  requestList(working, index, resultDelegate) {
    request({
      "bk-working": working,
      "list_range": 5,
      "list_offset": index * 5
    }, (result, res) => {
      if (result) {
        resultDelegate(res);
      } else {
        alert("네트워크 오류")
      }
    })
  }

  selectLiveCurrent = (index) => {
    this.setState({ liveCurrent: index })
    this.requestLiveList(index);
  }

  selectEndCurrent = (index) => {
    this.setState({ endCurrent: index });
    this.requestExpireList(index);
  }

  dateFormatting(timeMillisec) {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd")
  }

  refundPoint(purchaseId) {
    if (window.confirm('환불 하시겠어요?')) {
      request({"bk-working": "user-purchase-refund",
      "purchase_id": purchaseId
    }, (result, res) => {
        alert(result);
      })
    } else {
    }
  }
  
  routeChange(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <>
        <AdHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">구매 목록</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">구매아이디</th>
                      <th scope="col">사용자</th>
                      <th scope="col">상품명</th>
                      <th scope="col">날짜</th>
                      <th scope="col">상태</th>
                      <th scope="col">3자 구매 아이디</th>
                      <th scope="col">환불</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.purchaseList.map((page, i) => (
                      <tr key={i}>
                        <td>{page["purchase_id"]}</td>
                        <td>
                            {page["user_external_id"]}
                        </td>
                        <td>
                            {page["product_name"]}
                        </td>
                        <td>
                            {new Date(page["time_millisec"]).toLocaleDateString()}
                        </td>
                        <td>
                            {page["purchase_state"]}
                        </td>
                        <td>
                            {page["order_id"]}
                        </td>
                        <td>
                          <Button onClick={() =>
                            this.refundPoint(page["purchase_id"])
                          }
                          >환불</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {navigation(this.selectLiveCurrent, this.state.liveCurrent, this.state.liveCount)}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default PurchaseList;
