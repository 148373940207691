import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import moment from "moment-timezone";
import { requestFile } from "network/Request";

export const PopupRegister = () => {
    const [noticeId, setNoticeId] = useState("");
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [startTime, setStartTime] = useState(new Date().getTime());
    const [endTime, setEndTime] = useState(new Date().getTime());

    const register = () => {
        requestFile({
            "bk-working": "popup-register",
            "popup_title": title,
            "popup_body": body,
            "notice_id": noticeId,
            "start_millisec": startTime,
            "end_millisec": endTime,
        }, [selectedFile], (result, respons) => {
            alert(result);
        });
    }

    const changeStartTime = (e) => {
        setStartTime(new Date(e.target.value).getTime());
    }

    const changeEndChange = (e) => {
        setEndTime(new Date(e.target.value).getTime());
    }
    
    return (<>
        <div className="col">
            <br />
            공지사항아이디<Input value={noticeId} onChange={(e) => setNoticeId(e.target.value)} />
            제목<Input value={title} onChange={(e) => setTitle(e.target.value)} />
            내용<Input rows={5} type="textarea" value={body} onChange={(e) => setBody(e.target.value)} />
            시작일
            <Input
                className="mt-2"
                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                type="date"
                rows="1"
                onChange={(e) => changeStartTime(e)}
            />
            종료일
            <Input
                className="mt-2"
                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                type="date"
                rows="1"
                onChange={(e) => changeEndChange(e)}
            />
            이미지
            <Input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
            <br/>
            <Button onClick={() => register()} >등록</Button>
        </div>
    </>);
}