import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";
// core components
import AdvertiserHeader from "components/Headers/AdHeader.jsx";

const ChargeFail = () => {

  return (
    <>
      <AdvertiserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        결제오류
      </Container>
    </>
  );
}

export default ChargeFail;
