/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import {
    Modal,
} from "reactstrap";
import LoginCommon from "./LoginCommon";

class AuthModal extends React.Component {
    state = { formModal: false };
    
    toggleModal = () => {
        this.setState({
            formModal: !this.state.formModal
        });
    };
    componentDidMount() {
    }

    render() {
        return (
            <>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modal-dialog-centered w-00"
                    isOpen={this.state.formModal}
                    toggle={() => this.toggleModal()}
                >
                    <LoginCommon toggle={this.toggleModal} updateUser={this.props.updateUser}/>
                </Modal>
            </>
        );
    }
}

export default AuthModal;