import React from "react";
import AudioPlayer from "react-h5-audio-player";
import {
  Row,
  Badge,
  Col,
  Button,
  Input,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  count,
  current,
  newAdClick,
  bindUI,
  pageSelect,
  updateAd,
  getAdArray,
  setAdArray,
  getRecordArray,
  selectRecord,
} from 'controller/AdController.jsx'
import {
  upload
} from 'controller/RecordController.jsx'
import "assets/css/learnward.css"
import { request } from "network/Request.js"


class Record extends React.Component {
  state = {
    count: count,
    current: current,
    formModal: false,
    campaignId: 0,


    isRecording: false,
    blobURL: '',
  };

  componentDidMount() {
    bindUI(this.player);
    this.requestAdList();
  }

  newPage() {
    this.setState({
      count: count + 1
    })
    newAdClick();
  }

  uploadAdCall() {
    if (this.state.campaignId <= 0) {
      alert("try later.");
    }
    updateAd(this.state.current);
    upload(this.state.campaignId, getAdArray(), getRecordArray());
  }

  handlePageClick(page) {
    pageSelect(page);
    this.setState({
      current: page
    })
  }

  requestAdList() {
    var data = {
      "bk-working": "list-need-record"
    }

    request(data, (result, res) => {
      if (result) {
        var array = res["ad_array"];
        if (!array) {
          alert("광고가 없습니다.");
          return;
        }
        if (array.length <= 0) {
          alert("유효하지 않은 광고입니다.");
          return;
        }
        setAdArray(array);
        this.setState({
          count: array.length,
          campaignId: array[0]["campaign_id"],
        })
        this.handlePageClick(0);
      }
    });
  }

  render() {
    return (
      <>
        <div className="pt-md-6 pt-sm-2">
          <Container className="" >
            <div className="row mt-3">
              <div className="col-sm ">
                <h5>
                  <span className="badge badge-secondary">광고 문구</span>
                </h5>
                <Input className="mb-2" id="translation" defaultValue="" disabled></Input>
                <h5>
                  <span className="badge badge-secondary">영어</span>
                </h5>
                <form className="was-validated mb-1">
                  <textarea className="form-control mb-2" id="english"
                    placeholder="영어" disabled ></textarea>
                </form>
              </div>
            </div>
            <br />
            <Badge className="bg-primary">파일을 골라주세요.</Badge>
            < br />
            < br />
            <Row>
              <Col className="themed-container" >
                <div id="resourceInput" className="custom-file col-sm">
                  <input accept="audio/*;capture=microphone" type="file"
                    className="custom-file-input" id="ad-record-input" name="filename" />
                  <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                </div>
              </Col>
            </Row>
            <AudioPlayer
              ref={c => (this.player = c)}
              id="audio-player"
              className="mt-3"
              autoPlay
              onPlay={e => console.log("onPlay")}
            />
            < br />

            <Pagination
              className="pagination justify-content-center mb-0"
              listClassName="justify-content-end mb-0"
            >
              {[...Array(this.state.count)].map((page, i) => (
                <PaginationItem active={i === this.state.current} key={i}>
                  <PaginationLink onClick={e => this.handlePageClick(i)}>
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            </Pagination>
            <Button className="mt-3" onClick={(e) => this.uploadAdCall()}>전송</Button>
            <br />
          </Container>
        </div>
      </>
    );
  }

  _onRecordingComplete = (blob) => {
    //    this.setState({url: URL.createObjectURL(blob)});
    var file = new File([blob], Date.now() + ".mp3", { lastModified: 1534584790000 });
    selectRecord(file);
  }

  _onRecordingError = (err) => {
    console.log('recording error', err)
  }
}

export default Record;
