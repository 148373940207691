/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/advertiser/Dashboard.jsx";
import Charge from "views/advertiser/Charge.jsx";
import AdTotalRegister from "views/advertiser/AdTotalRegister.jsx";
import AdvertiserAdEdit from "views/advertiser/AdvertiserAdEdit.jsx";
import Signup from "views/auth/Signup.jsx";
import Login from "views/auth/Login.jsx";
import ChargedList from "views/advertiser/ChargedList"
import UsedList from "views/advertiser/UsedList"
import AdManage from "views/advertiser/AdManage.jsx";
import FindPassword from "views/auth/FindPassword";
import NotificationList from "views/service/NotificationList";
import ExamAuction from "views/advertiser/ExamAuction";
import Advertiser from "views/advertiser/Advertiser";
import ChargeComplete from "views/advertiser/ChargeComplete";
import ChargeFail from "views/advertiser/ChargeFail";
 
var AdvertiserRoutes = [
  {
    path: "/index",
    name: "대시보드",
    icon: "ni ni-tv-2 text-primary",
    destlayout: "advertiser",
    component: Dashboard,
    layout: "/advertiser"
  },
  {
    path: "/admanage",
    name: "광고관리",
    icon: "ni ni-planet text-blue",
    component: AdManage,
    layout: "/advertiser"
  },
  {
    path: "/adregister",
    name: "광고등록",
    icon: "ni ni-fat-add text-blue",
    component: AdTotalRegister,
    layout: "/advertiser"
  },
  {
    path: "/charge",
    name: "코인충전",
    icon: "ni ni-money-coins text-blue",
    component: Charge,
    layout: "/advertiser"
  },
  {
    path: "/exam",
    name: "시험등록",
    icon: "ni ni-spaceship text-blue",
    component: ExamAuction,
    layout: "/advertiser"
  },
  {
    skip: true,
    path: "/login",
    name: "로그인",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    skip: true,
    path: "/noti-list",
    name: "알림내역",
    icon: "ni ni-key-25 text-info",
    component: NotificationList,
    layout: "/advertiser"
  },
  {
    skip: true,
    path: "/charge-complete",
    name: "코인충전",
    icon: "ni ni-money-coins text-blue",
    component: ChargeComplete,
    layout: "/advertiser"
  },
  {
    skip: true,
    path: "/charge-fail",
    name: "코인충전",
    icon: "ni ni-money-coins text-blue",
    component: ChargeFail,
    layout: "/advertiser"
  },
  {
    skip: true,
    path: "/charged-list",
    name: "충전내역",
    icon: "ni ni-key-25 text-info",
    component: ChargedList,
    layout: "/advertiser"
  },
  {
    skip: true,
    path: "/used-list",
    name: "사용내역",
    icon: "ni ni-key-25 text-info",
    component: UsedList,
    layout: "/advertiser"
  },
  {
    skip: true,
    path: "/signup",
    name: "회원가입",
    icon: "ni ni-key-25 text-info",
    component: Signup,
    layout: "/auth"
  },
  {
    skip: true,
    path: "/findpass",
    name: "비밀번호 찾기",
    icon: "ni ni-key-25 text-info",
    component: FindPassword,
    layout: "/auth"
  },
  {
    skip: true,
    path: "/adedit",
    name: "광고수정",
    icon: "ni ni-fat-add text-orange",
    component: AdvertiserAdEdit,
    layout: "/advertiser"
  },
  {
    skip: true,
    path: "/user",
    name: "사용자 정보",
    icon: "ni ni-fat-add text-orange",
    component: Advertiser,
    layout: "/advertiser"
  },
];
export default AdvertiserRoutes;
