/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Signup from "views/auth/Signup.jsx";
import Login from "views/auth/Login.jsx";
import FindPassword from "views/auth/FindPassword";
import AdConfirm from "views/admin/AdConfirm.jsx"
import VideoSchedule from "views/admin/VideoSchedule.jsx"
import AdJudge from "views/admin/AdJudge.jsx"
import ContentsRegister from "views/admin/ContentsRegister";
import AdAdminManage from "views/admin/AdAdminManage";
import AdminContentsEdit from "views/admin/AdminAdEdit";
import EpisodeList from "views/admin/EpisodeList";
import Episode from "views/admin/Episode";
import NewEpisode from "views/admin/NewEpisode";
import RegisterExam from "views/admin/RegisterExam";
import PurchaseList from "views/admin/PurchaseList";
import WithdrawalList from "views/admin/WithdrawalList";
import EditExam from "views/admin/EditExam";
import NoticeList from "views/admin/AdminNoticeList";
import { NoticeRegister } from "views/admin/NoticeRegister";
import Notice from "views/service/Notice";
import { PopupRegister } from "views/admin/PopupRegister";
import PopupList from "views/admin/PopupList";
import UserList from "views/admin/UserList";
import UserDetail from "views/admin/UserDetail";

var AdminRoutes = [
  {
    path: "/index",
    name: "승인대기",
    permission: "master",
    component: AdConfirm,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/judge",
    name: "광고승인",
    permission: "master",
    component: AdJudge,
    layout: "/admin"
  },
  {
    path: "/adregister",
    name: "콘텐츠등록",
    permission: "master,contents",
    component: ContentsRegister,
    layout: "/admin"
  },
  {
    path: "/manage",
    name: "콘텐츠 관리",
    permission: "master,contents",
    component: AdAdminManage,
    layout: "/admin"
  },
  {
    path: "/episodelist",
    name: "시험관리",
    permission: "master",
    component: EpisodeList,
    layout: "/admin"
  },
  {
    path: "/schedule",
    name: "비디오 스케쥴",
    permission: "master",
    component: VideoSchedule,
    layout: "/admin"
  },
  {
    path: "/withdrawal",
    name: "출금 내역",
    permission: "master",
    component: WithdrawalList,
    layout: "/admin"
  },
  {
    path: "/purchase",
    name: "포인트샵 구매내역",
    permission: "master",
    component: PurchaseList,
    layout: "/admin"
  },
  {
    path: "/user-list",
    name: "사용자",
    permission: "master",
    component: UserList,
    layout: "/admin"
  },
  {
    path: "/popup-list",
    name: "팝업",
    permission: "master",
    component: PopupList,
    layout: "/admin"
  },
  {
    path: "/notice-list",
    name: "공지사항",
    permission: "master",
    component: NoticeList,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/user-detail/:userId",
    name: "사용자",
    permission: "master",
    component: UserDetail,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/newepisode",
    name: "에피소드등록",
    permission: "master",
    icon: "ni ni-fat-add text-orange",
    component: NewEpisode,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/episode",
    name: "시험목록",
    permission: "master",
    icon: "ni ni-fat-add text-orange",
    component: Episode,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/newexam",
    name: "문제등록",
    permission: "master,contents",
    icon: "ni ni-fat-add text-orange",
    component: RegisterExam,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/editexam",
    name: "문제수정",
    permission: "master,contents",
    icon: "ni ni-fat-add text-orange",
    component: EditExam,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/login",
    name: "로그인",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    skip: true,
    path: "/signup",
    name: "회원가입",
    icon: "ni ni-key-25 text-info",
    component: Signup,
    layout: "/auth"
  },
  {
    skip: true,
    path: "/findpass",
    name: "비밀번호 찾기",
    icon: "ni ni-key-25 text-info",
    component: FindPassword,
    layout: "/auth"
  },
  {
    skip: true,
    path: "/adedit",
    name: "콘텐츠수정",
    permission: "master,contents",
    icon: "ni ni-fat-add text-orange",
    component: AdminContentsEdit,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/notice-register",
    name: "공지사항등록",
    permission: "master",
    icon: "ni ni-fat-add text-orange",
    component: NoticeRegister,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/notice",
    name: "공지사항",
    permission: "master",
    icon: "ni ni-fat-add text-orange",
    component: Notice,
    layout: "/admin"
  },
  {
    skip: true,
    path: "/popup-register",
    name: "팝업등록",
    permission: "master",
    icon: "ni ni-fat-add text-orange",
    component: PopupRegister,
    layout: "/admin"
  },
];
export default AdminRoutes;
