import { requestImageVideo } from "network/Request";

function isValid(campaignJSON, adArray) {
	if (campaignJSON["impression_limit"] < adArray.length * 10000)
	return false;

	return true;
}

export function upload(campaignJSON, adArray, fileArray, videoArray, skipTranslate = false) {
	console.log(videoArray);

	if (!isValid(campaignJSON, adArray)) {
		alert("최소 비용은 페이지당 10000코인 입니다.");
		return;
	}
	if (campaignJSON["campaign_name"].length === 0) {
		alert("캠페인 이름을 입력해주세요.");
		return;
	}
	for (var i = 0; i < adArray.length; i++) {
    if ((adArray[i]["ad_translation"]?.length ?? 0) === 0) {
      alert(i + 1 + "페이지에 광고문구가 입력되지 않았습니다.");
      return;
    }
  }

  for (var j = 0; j < adArray.length; j++) {
    const json = adArray[j];
    if (
      !checkAnswerValid(
        json["answer_type"],
        json["answer"],
        json["second_hint"]
      )
    ) {
      alert("정답이 유효하지 않습니다" + JSON.stringify(json));
      return;
    }
    json["second_hint"] = shuffleArray(json["second_hint"]);
    if (json["resource_type"] === "video") {
      if (!videoArray[j]) {
        alert(j + 1 + "번째 콘텐츠에 비디오가 없습니다.");
        return;
      }
    }
  }

  for (var k = 0; k < fileArray.length; k++) {
    if (fileArray[k] !== null) {
      adArray[k]["has-file"] = true;
    } else {
      adArray[k]["file"] = false;
      alert(k + 1 + "번째 콘텐츠에 사진이 없습니다.");
      return;
    }
  }

	let jsonParam = {
		"ad_array": adArray,
		"campaign": campaignJSON,
		"bk-working": skipTranslate ? "new-ad-skip-translate" : "new-ad"
	};

	requestImageVideo(jsonParam, fileArray, videoArray, (result, res) => {
		if (res["bk-result"] === true) {
			alert("등록 성공");
			window.location.reload(false);
		} else {
			console.log(res)
			alert("등록 실패:" + res["result_detail"]);
		}
	});
}

function shuffleArray(array) {
	if (array == null)
		return null;
	for (let i = 0; i < array.length; i++) {
		let j = Math.floor(Math.random() * (i + 1));
		// [array[i], array[j]] = [array[j], array[i]];
		const x = array[i];
		array[i] = array[j];
		array[j] = x;
	}
	return array;
};

function checkAnswerValid(answerType, answer, hintTags) {
	var valid = false;
	if (answerType === 0) {
		for (var i = 0; i < hintTags.length; i++) {
			if (hintTags[i] === answer)
			valid = true;
		}
	} else if (answerType ===2) {
		var hintConcat = hintTags[0];
		for (i = 1; i < hintTags.length; i++) {
			hintConcat += " " + hintTags[i];
		}
		if (hintConcat === answer)
				valid = true;

	} else {
		valid = true;
	}
	return valid;
}