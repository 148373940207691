import React from "react";

// reactstrap components
import {
  Button,
  Input,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import {
  count,
  current,
  newAdClick,
  bindUI,
  pageSelect,
  updateAd,
  getAdArray,
  setAdArray,
} from 'controller/AdController.jsx'
import {
  upload
} from 'controller/TranslationController.jsx'
import "assets/css/learnward.css"
import { request } from "network/Request.js"

class Translation extends React.Component {
  state = {
    count: count,
    current: current,
    formModal: false,
    campaignId: 0,
    hintTags: [],
    answerTypeName: "주관식",
    answerTypeId: 1,
  };

  componentDidMount() {
    bindUI();
    this.requestAdList();
  }

  newPage() {
    this.setState({
      count: count + 1
    })
    newAdClick();
  }

  uploadAdCall() {
    updateAd(this.state.current);
    upload(getAdArray(), this.state.campaignId);
  }

  handlePageClick(page) {
    pageSelect(page);
    this.setState({
      current: page
    })
  }

  requestAdList() {
    var data = {
      "bk-working": "list-need-translation"
    }

    request(data, (result, res) => {
      console.log(res);
      if (result) {
        var array = res["ad_array"];
        if (!array) {
          alert("광고가 없습니다.");
          return;
        }
        if (array.length <= 0) {
          alert("광고가 없습니다.");
          return;
        }
        setAdArray(array);
        this.setState({
          count: array.length,
          campaignId: array[0]["campaign_id"],
        })
        this.handlePageClick(0);
      }
    });
  }

  answerTypeToggle = () => this.setState({ answerTypeDropdownOpen: !this.state.answerTypeDropdownOpen });

  setTag = (tag) => {
    this.setState({ hintTags: [...this.state.hintTags, tag] });
  }

  setAnswerType(typeId, name) {
    this.setState({ answerTypeName: name, answerTypeId: typeId });
  }

  deleteTag = (item) => {
    const deletedTags = this.state.hintTags;
    deletedTags.splice(item.index, 1)
    this.setState({ hintTags: deletedTags });
  }

  render() {
    return (
      <>
        <div className="pt-md-6 pt-sm-2">
          <Container className="" >
            <div className="row mt-3">
              <div className="col-sm ">
                <h5>
                  <span className="badge badge-secondary">광고 문구</span>
                </h5>
                <Input className="mb-2" id="translation" defaultValue="" disabled></Input>
                <h5>
                  <span className="badge badge-secondary">영어</span>
                </h5>
                <form className="was-validated mb-1">
                  <textarea className="form-control mb-2 is-invalid" id="english"
                    placeholder="영어" required></textarea>
                </form>
                <h5><span className="badge badge-secondary mt-2">해설</span></h5>
                <form className="mb-1 was-validated">
                  <textarea id="explanation" className="form-control"
                    placeholder="" required></textarea>
                </form>
                <h5><span className="badge badge-secondary mt-2">문제와 정답</span></h5>
                <div className="row">
                  <form className="col-md-6 was-validated mb-1">

                    <ButtonDropdown isOpen={this.state.answerTypeDropdownOpen} toggle={this.answerTypeToggle}>
                      <DropdownToggle caret>
                        {this.state.answerTypeName}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => this.setAnswerType(0, "객관식")} >객관식</DropdownItem>
                        <DropdownItem onClick={() => this.setAnswerType(1, "주관식")} >주관식</DropdownItem>
                        <DropdownItem onClick={() => this.setAnswerType(2, "열거형")} >열거형</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                    <textarea
                      id="quiz" className="form-control is-invalid"
                      placeholder="문제" required />
                  </form>
                  <form className="col-md-6 was-validated mb-1">
                    <textarea
                      id="hint" className="form-control is-invalid"
                      placeholder="힌트" required />
                  </form>
                  <form className="col-md-6 was-validated mb-1">
                    <textarea
                      id="answer" className="form-control is-invalid"
                      placeholder="정답" required />
                  </form>
                  {/* {this.state.answerTypeId !== 1 ?
                      <YedTagInput tags={this.state.hintTags} tagInputErrorControl={true} tagInpuTitle={'선택지'} onChange={this.setTag} removeTag={(item) => { this.deleteTag(item) }} />
                      : ""} */}
                </div>
                <h5><span className="badge badge-secondary mt-2">난이도 없음=0, 하=1, 중=2, 상=3</span></h5>
                <Input className="mt-2 mb-2" type="number" id="level_input" min="0"
                  defaultValue="0" aria-label="Text input with dropdown button" />

              </div>
            </div>
            <div className="container row">
              <div className="col-sm"></div>
            </div>
            <div className="container mt-3">
              <nav aria-label="Page navigation example">
                <ul className="pagination-sm justify-content-center" id="pagination">
                </ul>
              </nav>
            </div>
            <Pagination
              className="pagination justify-content-center mb-0"
              listClassName="justify-content-end mb-0"
            >
              {[...Array(this.state.count)].map((page, i) => (
                <PaginationItem active={i === this.state.current} key={i}>
                  <PaginationLink onClick={e => this.handlePageClick(i)}>
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            </Pagination>
            <Button onClick={(e) => this.uploadAdCall()}>전송</Button>
          </Container>
        </div>
      </>
    );
  }
}

export default Translation;
