/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  Table,
  Row
} from "reactstrap";
// core components
import { request } from "network/Request.js"
import { navigation } from "components/Nav/Nav.jsx"
import Button from "reactstrap/lib/Button";
import dateFormat from "dateformat";
import { useNavigate } from "react-router";

const EpisodeList = () => {
  const [episodeCount, setEpisodeCount] = useState(0);
  const [episodeCurrent, setEpisodeCurrent] = useState(0);
  const [episodeList, setEpisodeList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    requestEpisodeList(0);
  }, [])

  const redirectNewEpisode = () => {
    navigate("/admin/newepisode");
  }

  const redirectEpisode = (episodeId, startTime) => {
    navigate("/admin/episode?episode_id=" + episodeId + "&start_time" + "=" + startTime);
  }

  const requestEpisodeList = (index) => {
    requestList(index, (res) => {
      console.log(res);

      setEpisodeList(res["episode_array"]);
      setEpisodeCount(res["episode_size"]);
    })
  }

  const requestList = (index, resultDelegate) => {
    request({
      "bk-working": "episode-list",
      "limit_offset": index * 10
    }, (result, res) => {
      if (result) {
        resultDelegate(res);
      } else {
        alert("네트워크 오류")
      }
    })
  }

  const selectEpisodeCurrent = (index) => {
    setEpisodeCurrent(index);
    requestEpisodeList(index);
  }

  const calculateFirst = (current, count) => {
    return (count <= 7 ? 0 :
      current + 7 > count ? count - 7 :
        current)
  }

  const dateFormatting = (timeMillisec) => {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd HH:MM")
  }

  return (
    <>
      {/* Table */}
      <br />
      <br />
      <Row>
        <div className="col">
          <Card className="">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">포인트</th>
                  <th scope="col">시작</th>
                  <th scope="col">종료</th>
                  <th scope="col">참가자</th>
                </tr>
              </thead>
              <tbody>
                {episodeList.map((page, i) => (
                  <tr key={i} onClick={() => redirectEpisode(page["episode_id"], dateFormatting(page["start_time"]))}>
                    <td size="lg">{page["episode_id"]}</td>
                    <td>
                      {page["division_point"]}</td>
                    <td>
                      {dateFormatting(page["start_time"])}</td>
                    <td>
                      {dateFormatting(page["end_time"])}</td>
                    <td>
                      {page["episode_users"]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              {navigation(selectEpisodeCurrent, episodeCurrent, episodeCount)}
            </CardFooter>
          </Card>
          <Button onClick={() => redirectNewEpisode()}>시험 추가</Button>
        </div>
      </Row>
      {/* Dark table */}
    </>
  );
}

export default EpisodeList;
