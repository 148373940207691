
import { request } from "network/Request";
import React, { useState } from "react";
import { Button, Input } from "reactstrap";

export const NoticeRegister = () => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    
    const register = () => {
        request({
            "bk-working": "notice-upload",
            "notice_subject": title,
            "notice_body": body,
        }, (result, respons) => {
            alert(result);
        });
    }

    return (<>
        <div className="col">
            <br />
            제목<Input value={title} onChange={(e) => setTitle(e.target.value)} />
            내용<Input rows={5} type="textarea" value={body} onChange={(e) => setBody(e.target.value)} />
            <Button onClick={() => register()} >등록</Button>
        </div>
    </>);
}