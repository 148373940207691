/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Navigate } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import {
  sessionIn,
  digestMessage,
  hexString,
  requestToken,
} from "network/Request.js";
import { googleAuth } from "views/service/Firebase.jsx";
import { appleAuth } from "views/service/Firebase";

class LoginCommon extends React.Component {
  state = {
    state: null,
    layout: null,
  };

  googleSignIn() {
    googleAuth().then((result) => {
      if (result.credential) {
        result.user.getIdToken().then((token) => {
          this.signin(1, token);
        });
      }
    });
  }

  appleSignIn() {
    appleAuth().then((result) => {
      if (result.credential) {
        result.user.getIdToken().then((token) => {
          this.signin(2, token);
        });
      }
    });
  }

  signin(type = 0, token = null) {
    var email = document.getElementById("login_email").value;
    var password = document.getElementById("login_password").value;
    digestMessage(`keyasalt${password}`) // Hashing
      .then((digestValue) => {
        const hashed = hexString(digestValue);
        let data = {
          // Might be able to reduce extra code (DRY method)
          "bk-working": "signin", //will change for some calls
          login_type: type,
          thirdparty_token: token,
          user_email: email,
          password: hashed.toLowerCase(),
        };
        requestToken(data, (result, receive) => {
          console.log(receive["csrf_token"]);
          window.localStorage.setItem("csrf_token", receive["csrf_token"]);
          if (result) {
            this.setState({
              loggedIn: true, // Set state as Logged In
            });
            var expireTime = new Date(receive["token_expire"]);
            this.success(expireTime);
          } else {
            alert(receive["bk-result-detail"]);
          }
        });
      });
  }

  success(expire) {
    sessionIn(expire);
    if (this.props.updateUser) {
      this.props.updateUser();
    }
    if (this.props.toggle) {
      this.props.toggle();
    }
    var dest = this.getQueryVariable("dest");
    var layout = this.getQueryVariable("layout");
    if (dest) {
      this.setState({
        layout: layout,
        dest: dest,
      });
    }
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  render() {
    if (this.state.dest) {
      return (
        <Navigate
          to={(this.state.layout ? this.state.layout : "") + this.state.dest}
        />
      );
    }
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <img
                  height={49}
                  onClick={(e) => this.googleSignIn()}
                  alt="..."
                  src={require("assets/img/icons/common/google_login.png")}
                />
                <img
                  height={44}
                  onClick={(e) => this.appleSignIn()}
                  alt="..."
                  src={require("assets/img/icons/common/apple_login.png")}
                />
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                    <Input id="login_email" placeholder="Email" type="email" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                    <Input
                      id="login_password"
                      placeholder="Password"
                      type="password"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />{" "}
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={(e) => this.signin()}
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a className="text-light" href="findpass">
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a className="text-light" href="signup">
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default LoginCommon;
