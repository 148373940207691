import React from "react";

// reactstrap components
import {
  Input,
  Button,
  Badge,
  Container,
  Row,
  Col,
  Pagination,
  Card,
  CardBody,
} from "reactstrap";
import {
  init,
  count,
  current,
  newAdClick,
  removeAdClick,
  bindUI,
  pageSelect,
  updateAd,
  getAdArray,
  getFileArray,
} from 'controller/AdController.jsx'
import {
  upload
} from 'views/service/freead_event/AdEventInsert.jsx'
import AdRemoveModal from 'views/advertiser/AdRemoveModal.jsx'
import "assets/css/learnward.css"

class EventAdRegister extends React.Component {
  state = {
    count: count,
    current: current,
    formModal: false
  };

  componentDidMount() {
    bindUI();
    init();
    this.setState({count: 1, current: 0});
  }

  newPage() {
    newAdClick();
    this.setState({
      count: count,
      current: current
    })
  }

  removeRequest() {
    this.refs.removeModal.toggleModal();
  }

  removePage = () => {
    if (count < 2)
      return;
    var newCurrent = removeAdClick();
    this.setState({
      current: newCurrent,
      count: this.state.count - 1
    })
  }

  uploadAdCall(campaign) {
    updateAd(this.state.current);
    upload(campaign, getAdArray(), getFileArray());
  }

  handlePageClick(page) {
    pageSelect(page);
    this.setState({
      current: page
    })
  }

  imageError() {
    document.getElementById("image_preview").src=require("assets/img/theme/image_fallback.jpg");
  }

  render() {
    return (
      <>
        <Col className="">
          <Card className="border-0">
            <CardBody className="py-5">
              <Container className="" >
                <Row>
                  <Col className="sm-6" lg="6">
                    <div id="resourceInput" className="custom-file col-sm">
                      <Input accept="image/png image/jpeg video/mp4" type="file"
                        className="custom-file-input" id="ad-resource-input" name="filename" />
                      <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                    </div>
                    <div className="ad-resource-container mb-2 bg-secondary" >
                      <img src={require("assets/img/theme/image_fallback.jpg")} className="ad-resource" id='image_preview' alt="ad upload resource" onError={(e) => this.imageError()} />
                      <video className="ad-resource" id='video_preview' controls hidden={true}></video>
                    </div>
                    <Badge className="bg-primary mb-2 mt-2">광고 문구</Badge>
                    <form className="was-validated mb-1">
                      <Input type="textarea" className="form-control is-invalid mb-2" id="translation"
                        placeholder="문장 혹은 대화" required></Input>
                    </form>
                    <Badge className="bg-primary mb-2 mt-2">이메일 (정확하지 않으면 광고가 진행되지 않습니다.)</Badge>
                    <form className="was-validated mb-1">
                      <Input type="textarea" className="form-control is-invalid mb-2" id="extra"
                        placeholder="광고 진행 과정 결과가 이메일로 전달됩니다. 본 이벤트 외에 어떠한 용도로도 사용하지 않습니다." required></Input>
                    </form>
                    <div className="was-validated input-group" hidden>
                      <div className="input-group-prepend">
                        <span className="input-group-text">정답 당</span>
                      </div>
                      <input id="cpco" type="number" className="form-control" min="1" data-bind="value:replyNumber"
                        defaultValue="1" aria-label="원" />
                      <div className="input-group-append">
                        <span className="input-group-text">point</span>
                      </div>
                      <div className="input-group-append">
                        <button className="btn btn-secondary" data-toggle="tooltip"
                          data-placement="top" title="정답을 맞췄을 때 발생하는 광고 비용입니다.">?</button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="container row">
                  <div className="col-sm"></div>
                </div>
                <div className="container mt-3">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination-sm justify-content-center" id="pagination">
                    </ul>
                  </nav>
                </div>
                <Pagination
                  className="pagination justify-content-center mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <form className="text-right ml-3">
                    <Button className="btn btn-primary" size="sm" type="button" onClick={() => this.removeRequest()} hidden>삭제</Button>
                    <Button className="btn btn-primary" size="sm" type="button" onClick={() => this.newPage()} hidden>추가</Button>
                  </form>
                </Pagination>
              </Container>
              <AdRemoveModal ref="removeModal" removeUpdate={this.removePage} />
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default EventAdRegister;
