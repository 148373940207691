import React from "react";

import {
  Col,
  Badge,
  Button,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateFormat from "dateformat";

class VideoSchedule extends React.Component {

  state = {
    updating: false,
    time: new Date(),
    adList: [],
    liveCount: 0,
    liveCurrent: 0,
  };

  handleChange = date => {
    this.setState({
      time: date
    });
    if (date)
      document.getElementById("time").value = date.getTime();
  };

  render() {
    return (
      <>
        <Col className="align-items-center ls-1" xl="5">
          <h2 className="mb-0">기준일</h2>
          <DatePicker
            className="mt-2 mr-3 mb-3"
            selected={this.state.time}
            onChange={this.handleChange} />
          <input type="text" id="time" value={this.state.time ? this.state.time.getTime() : ""} hidden onChange={(e) => e.preventDefault()} />
          <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">이름</th>
                      <th scope="col">노출수</th>
                      <th scope="col">클릭수</th>
                      <th scope="col">전환수</th>
                      <th scope="col">사용/제한</th>
                      <th scope="col">만료일</th>
                      <th scope="col">더보기</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.adList.map((page, i) => (
                      <tr key={i}>
                        <th scope="row">
                          <span className="mb-0 text-sm">
                            <Badge color="" className="badge-dot badge-lg mr-5 ml-2">
                              {page["impression_state"] === 0 ?
                                <i className="bg-green" /> : 
                                page["impression_state"] === 1 ?
                                <i className="bg-primary" /> : 
                                <i className="bg-warning" />
                              }
                              {page["list_campaign_name"]}
                          </Badge>
                          </span>
                        </th>
                        <td>{page["list_view_count"]}</td>
                        <td>
                            {page["list_click_count"]}
                        </td>
                        <td>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">{page["list_campaign_used"]}/{page["list_campaign_limit"]}</span>
                          </div>
                        </td>
                        <td>
                          {this.dateFormatting(page["list_ad_expire"])}</td>
                        <td>
                          <Button onClick={() =>
                            this.routeChange("adedit?campaign_id=" + page["campaign_id"] + "&impression_state=" + page["impression_state"] + "&impression_id=" + page["impression_id"])
                          }
                          >보기</Button>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </Table>
        </Col>
      </>
    );
  }
}

export default VideoSchedule;