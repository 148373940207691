import cookie from 'react-cookies';
let domain = "https://api.learnward.com";
// let domain = "https://127.0.0.1:10515";
let normalAPI = domain + "/apigate-web";
let authAPI = domain + "/auth";
let fileAPI = domain + "/apigate-resource";
let imageVideoAPI = domain + "/apigate-image-video";

export function sessionIn(expireTime) {
    cookie.save('keyanist-signed', 'true', {
        path: '/',
        expires: expireTime,
        httpOnly: false
    });
}

export function sessionOut() {
    cookie.remove('keyanist-signed', {
        path: "/"
    });
}

export function isSignIn() {
    return cookie.load('keyanist-signed') != null;
}

export function digestMessage(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return window.crypto.subtle.digest("SHA-256", data);
}

export function hexString(buffer) {
    const byteArray = new Uint8Array(buffer);

    const hexCodes = [...byteArray].map(value => {
        const hexCode = value.toString(16);
        const paddedHexCode = hexCode.padStart(2, "0");
        return paddedHexCode;
    });

    return hexCodes.join("");
}

export function request(json, callback) {
    requestBase(normalAPI, json, callback);
}

export function requestToken(json, callback) {
    requestBase(authAPI, json, callback);
}

export function requestRectangleFile(json, blobArray, callback) {
    requestFileBase(domain + "/apigate-rectangle300-image", json, blobArray, callback);
}

export function requestFile(json, blobArray, callback) {
    requestFileBase(fileAPI, json, blobArray, callback);
}

export function requestImageVideo(json, images, videos, callback) {
	var formData = new FormData();
	var xhr = new XMLHttpRequest();
	xhr.onload = function (e) {
		if (xhr.readyState === 4) {
			if (xhr.status === 200) {
                var json = JSON.parse(xhr.responseText);
                callback(json["bk-result"], json);
			} else {
				alert("등록 실패:" + xhr.statusText);
			}
		}
	};

	for (var j in images) {
		formData.append('images', images[j]);
	}
	for (var j in videos) {
		formData.append('videos', videos[j]);
	}

	formData.append('json', JSON.stringify(json));
    xhr.withCredentials = true;
	xhr.open('POST', imageVideoAPI);
	xhr.send(formData);
}

export function requestFileBase(api, json, fileArray, callback) {
	var formData = new FormData();
	var xhr = new XMLHttpRequest();
	xhr.onload = function (e) {
		if (xhr.readyState === 4) {
			if (xhr.status === 200) {
                var json = JSON.parse(xhr.responseText);
                callback(json["bk-result"], json);
			} else {
				alert("등록 실패:" + xhr.statusText);
			}
		}
	};

	for (var j in fileArray) {
		formData.append('resources', fileArray[j]);
	}

	formData.append('json', JSON.stringify(json));
    xhr.withCredentials = true;
	xhr.open('POST', api);
	xhr.send(formData);
}

function requestBase(api, json, callback) {
    let otherParam = {
        crossDomain: true,
        method: "POST", //TODO DRY!!
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "cache-control": "no-cache" //TODO DRY!!
        },
        credentials: "include",
        body: JSON.stringify(json)
    };
    fetch(api, otherParam) // API call
        .then(dataResp => dataResp.json()) // Format API response as JSON
        .then(res => {
            const result = res["bk-result"]; // true if signin OK, false otherwise
            if (callback != null)
                callback(result, res);
        })
        .catch(error => console.log(error));
}

export function resourceUrl() {
    return fileAPI;
}