/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js/auto";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  viewChartData,
  costChartData,
} from "variables/charts.jsx";
import "react-datepicker/dist/react-datepicker.css";
import Header from "components/Headers/Header.jsx";
import { request } from "network/Request.js";

import dateFormat from "dateformat";
import { useNavigate } from "react-router";

const Dashboard = () => {
  const [activeNav, setActiveNav] = useState(2);
  const [viewChart, setViewChart] = useState(viewChartData.data);
  const [costChart, setCostChart] = useState(costChartData.data);
  const [adList, setAdList] = useState([]);
  const [updating, setUpdating] = useState(false);

  const viewRef = useRef();
  const costRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }

    requestList();
    requestDashboard("m");
  }, []);

  useEffect(() => {
    viewRef.current.data = viewChart;
    viewRef.current.update();
    costRef.current.data = costChart;
    costRef.current.update();
  }, [viewChart, costChart]);

  const requestList = () => {
    request(
      {
        "bk-working": "campaign-list",
        list_range: 5,
        list_offset: 0,
      },
      (result, res) => {
        if (result) {
          setAdList(res["list_array"]);
        } else {
          alert("네트워크 오류");
        }
      }
    );
  };

  const requestDashboard = (type = "d", time = Date.now()) => {
    if (updating) return;
    setUpdating(true);
    console.log(time);

    var data = {
      "bk-working": "dashboard",
      dashboard_time: time,
      dashboard_type: type,
    };
    request(data, (result, response) => {
      setUpdating(false);
      console.log("response");
      if (!result) {
        alert("오류가 발생하였습니다." + response);
        return;
      }
      setShowChart(response["dashboard_array"], response["dashboard_type"]);
    });
  };

  const setShowChart = (array, type) => {
    var viewArray = [];
    var costArray = [];

    for (var i in array) {
      viewArray.push(array[i].dashboard_view);
      costArray.push(array[i].dashboard_point);
    }
    var newViewChart = viewChartData.data;
    newViewChart.datasets[0].data = viewArray;
    var newCostChart = costChartData.data;
    newCostChart.datasets[0].data = costArray;

    var typeText = "개월";
    var typeCurrent = "이번 달";
    if (type === "m") {
    } else if (type === "w") {
      typeText = "주";
      typeCurrent = "이번 주";
    } else {
      typeText = "일";
      typeCurrent = "오늘";
    }
    var temp = [];

    for (i = 5; i > 0; i--) {
      temp.push(i + " " + typeText + " 전");
    }
    temp.push(typeCurrent);
    newViewChart["labels"] = temp;
    newCostChart["labels"] = temp;

    setViewChart({...newViewChart});
    setCostChart({...newCostChart});
  };

  const dateFormatting = (timeMillisec) => {
    var date = new Date(timeMillisec);
    return dateFormat(date, "yyyy.mm.dd");
  };

  return (
    <>
      <Header requestDashboard={requestDashboard} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">전달 수</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="">
                  <Line
                    data={viewChart}
                    options={viewChartData.options}
                    ref={viewRef}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">광고 비용</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={costChart}
                    options={costChartData.options}
                    ref={costRef}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h4 className="mb-0">진행중인 캠페인</h4>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      onClick={(e) => navigate("/advertiser/admanage")}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">캠페인 이름</th>
                    <th scope="col">노출수</th>
                    <th scope="col">만료일</th>
                    <th scope="col">완료율</th>
                  </tr>
                </thead>
                <tbody>
                  {adList.map((item, i) => (
                    <tr key={i}>
                      <th scope="row">{item.list_campaign_name}</th>
                      <td>{item.list_view_count}</td>
                      <td>{dateFormatting(item.list_ad_expire)}</td>
                      <td>
                        {parseInt(
                          (item.list_campaign_used / item.list_campaign_limit) *
                            100
                        )}
                        %
                        <div>
                          <Progress
                            max="100"
                            value={
                              (item.list_campaign_used /
                                item.list_campaign_limit) *
                              100
                            }
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h4 className="mb-0">클릭 수</h4>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      onClick={(e) => navigate("/advertiser/admanage")}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">캠페인 이름</th>
                    <th scope="col">클릭률</th>
                    <th scope="col">전환율</th>
                  </tr>
                </thead>
                <tbody>
                  {adList.map((page, i) => (
                    <tr key={i}>
                      <th scope="row">{page.list_campaign_name}</th>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">
                            {page.list_view_count === 0
                              ? 0
                              : (page.list_click_count / page.list_view_count) *
                                100}
                            %
                          </span>
                        </div>
                      </td>
                      <td>{page.list}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
