import $ from "jquery";

export var count = 1;
export var current = 0;
export var resourceType = "image";
var adArray = [];
var fileArray = [];
var videoArray = [];
var recordArray = [];
var selectedFile = null;
var selectedVideo = null;
var selectedRecord = null;
var answerType;
var secondHint = [];
var audioPlayer;

var json = {};
adArray.splice(0, 0, json);
fileArray.splice(0, 0, null);
videoArray.splice(0, 0, null);
recordArray.splice(0, 0, null);
var updateAnswerType;
var updateSecondHint;

export function init(answerTypeF, secondHintF) {
  updateAnswerType = answerTypeF;
  updateSecondHint = secondHintF;
  count = 1;
  current = 0;
  adArray = [];
  fileArray = [];
  videoArray = [];
  recordArray = [];
  selectedFile = null;
  selectedVideo = null;
  selectedRecord = null;
  resourceType = "image";
  answerType = 0;
  secondHint = [];
  answerType = 1;

  json = {};
  adArray.splice(0, 0, json);
  fileArray.splice(0, 0, null);
  videoArray.splice(0, 0, null);
  recordArray.splice(0, 0, null);
}

function inputNewAd(index) {
  var json = {};
  adArray.splice(index + 1, 0, json);
  fileArray.splice(index + 1, 0, null);
  videoArray.splice(index + 1, 0, null);
  recordArray.splice(index + 1, 0, null);
}

function loadAd(index) {
  console.log(fileArray);
  var file = fileArray[index];
  var video = videoArray[index];
  var record = recordArray[index];
  selectedFile = file;
  selectedVideo = video;
  selectedRecord = record;
  var resourceType = "image";
  if (adArray[index].resource_type != null)
    resourceType = adArray[index].resource_type;
  var resourceUrl =
    adArray[index].resource_url != null
      ? adArray[index].ad_resource
      : adArray[index].link;
  var videoUrl = adArray[index].video_url;
  var cpv = adArray[index].point;
  var cpco = adArray[index].reward;
  var english = adArray[index].ad_english;
  var translation = adArray[index].ad_translation;
  var answer = adArray[index].answer;
  var hint = null;
  var quiz = null;
  var explanation = null;
  var audio = adArray[index].audio;
  var level = adArray[index].level;
  answerType = adArray[index].answer_type ?? 1;
  secondHint = adArray[index].second_hint ?? [];

  initVideo();
  resourceTypeSelect(resourceType);
  readResource(file, resourceUrl);
  readVideo(video, videoUrl);
  var resourceTypeSelected = document.getElementById("resourceTypeSelect");
  if (resourceTypeSelected !== null) {
    select(resourceTypeSelected, resourceType);
  }

  if (adArray[index].ad_hint != null) {
    hint = adArray[index].ad_hint;
  }

  if (adArray[index].ad_quiz != null) {
    quiz = adArray[index].ad_quiz;
  }

  if (adArray[index].explanation != null) {
    explanation = adArray[index].explanation;
  }

  if (cpv == null) {
    updateValueIfNotNull("cpv", 0);
  } else {
    updateValueIfNotNull("cpv", cpv);
  }

  if (cpco == null) {
    updateValueIfNotNull("cpco", 1);
  } else {
    updateValueIfNotNull("cpco", cpco);
  }
  if (english != null) updateValueIfNotNull("english", english);
  else updateValueIfNotNull("english", "");

  if (record != null) {
    selectRecord(record);
  } else if (audio != null && audioPlayer) {
    audioPlayer.src = audio;
  } else if (audioPlayer) {
    audioPlayer.src = "";
  }

  if (translation != null) updateValueIfNotNull("translation", translation);
  else updateValueIfNotNull("translation", "");

  if (updateAnswerType != null)
    updateAnswerType(
      answerType,
      answerType === 0 ? "객관식" : answerType === 1 ? "주관식" : "열거형"
    );
  if (answer != null) updateValueIfNotNull("answer", answer);
  else updateValueIfNotNull("answer", "");

  if (explanation != null) updateValueIfNotNull("explanation", explanation);
  else updateValueIfNotNull("explanation", "");

  updateValueIfNotNull("hint", hint);

  updateValueIfNotNull("quiz", quiz);
  updateValueIfNotNull("explanation", explanation);
  updateValueIfNotNull("level_input", level);
  updateValueIfNotNull(
    "level",
    level === 0
      ? "없음"
      : level === 1
      ? "하"
      : level === 2
      ? "중"
      : level === 3
      ? "상"
      : "오류"
  );
  if (updateSecondHint != null) updateSecondHint(secondHint);
}

function updateValueIfNotNull(field, value) {
  var obj = document.getElementById(field);
  if (obj !== null) {
    obj.value = value;
  }
}

function getValueIfNotNull(field) {
  var obj = document.getElementById(field);
  if (obj !== null) {
    return obj.value;
  }
  return null;
}

function select(actionSelect, value) {
  for (var i = 0; i < actionSelect.options.length; i++) {
    if (actionSelect.options[i].value === value) {
      actionSelect.selectedIndex = i;
      actionSelect.value = value;
      break;
    }
  }
}

function removeAd(index) {
  adArray.splice(index, 1);
  fileArray.splice(index, 1);
  videoArray.splice(index, 1);
  recordArray.splice(index, 1);
}

export function setAdArray(array) {
  adArray = array;
  count = array.length;
  fileArray = [];
  videoArray = [];
  recordArray = [];
  for (var i = 0; i < array.length; i++) {
    fileArray.splice(i, 0, null);
    videoArray.splice(i, 0, null);
    recordArray.splice(i, 0, null);
  }
  loadAd(0);
}

export function getAdArray() {
  return adArray;
}

export function getFileArray() {
  return fileArray;
}

export function getVideoArray() {
  return videoArray;
}

export function getRecordArray() {
  return recordArray;
}

export function bindUI(player = null) {
  audioPlayer = player;
  $(document).ready(function () {
    resetPagination();
  });

  $("#ad-resource-input").on("change", function () {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    if (this.files && this.files[0]) {
      readResource(this.files[0]);
      selectedFile = this.files[0];
    }
    this.value = null;
  });

  $("#ad-video-input").on("change", function () {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    if (this.files && this.files[0]) {
      readVideo(this.files[0]);
      selectedVideo = this.files[0];
    }
    this.value = null;
  });

  $("#ad-record-input").on("change", function () {
    if (this.files && this.files[0]) {
      selectRecord(this.files[0]);
    }
  });
}

export function selectVideo(file) {}

export function selectAnswerType(type) {
  answerType = type;
}

export function selectSecondHint(hint) {
  secondHint = hint;
}

export function selectRecord(file) {
  selectedRecord = file;
  if (audioPlayer != null) {
    audioPlayer.audio.src = URL.createObjectURL(file);
  }
}

export function initVideo() {
  var video = document.getElementById("video_preview");
  if (video)
    video.src = "";
}

export function resourceTypeSelect(type = null) {
  resourceType = type;
  if (resourceType === null)
    resourceType = document.getElementById("resourceTypeSelect").value;
  var imageContainer = document.getElementById("image_preview_container");
  var image = document.getElementById("image_preview");
  var videoContainer = document.getElementById("video_preview_container");
  var video = document.getElementById("video_preview");
  if (imageContainer === null || video === null) {
    return;
  }
  image.src = "";
  video.src = "";
  if (resourceType === "image") {
    videoContainer.hidden = true;
  } else {
    videoContainer.hidden = false;
  }
}

function readVideo(file, url) {
  var video = document.getElementById("video_preview");
  if (file !== null) {
    video.src = URL.createObjectURL(file);
    return;
  } else if (url !== null) {
    video.src = url;
    return;
  }
  video.src = "";
}

function readResource(file, url) {
  if (file !== null) {
    var reader = new FileReader();
    reader.onload = function (e) {
      $("#image_preview").attr("src", e.target.result);
    };

    reader.readAsDataURL(file);
    return;
  } else if (url !== null) {
    $("#image_preview").attr("src", url);
    return;
  }
  $("#image_preview").attr("src", "");
}

export function updateAd(index) {
  var cpv = getValueIfNotNull("cpv");
  var cpco = getValueIfNotNull("cpco");
  var english = getValueIfNotNull("english");
  var translation = getValueIfNotNull("translation");
  var answer = getValueIfNotNull("answer");
  var hint = getValueIfNotNull("hint");
  var quiz = getValueIfNotNull("quiz");
  var explanation = getValueIfNotNull("explanation");
  var extra = getValueIfNotNull("extra");
  var level = getValueIfNotNull("level_input");

  if (selectedFile !== null) fileArray[index] = selectedFile;
  if (selectedVideo !== null) videoArray[index] = selectedVideo;
  if (selectedRecord !== null) recordArray[index] = selectedRecord;
  adArray[index].resource_type = resourceType;
  adArray[index].point = cpv;
  adArray[index].reward = cpco;
  adArray[index].ad_english = english;
  adArray[index].ad_translation = translation;
  adArray[index].answer = answer;
  adArray[index].ad_extra = extra;
  adArray[index].second_hint = secondHint;
  adArray[index].answer_type = answerType;
  if (hint != null) adArray[index].ad_hint = hint;
  if (quiz != null) adArray[index].ad_quiz = quiz;
  if (explanation != null) adArray[index].explanation = explanation;
  if (level != null && level !== "") adArray[index].level = level;
  console.log(adArray[index]);
}

function resetPagination() {}

export function pageSelect(page) {
  if (current === page) {
    return;
  }
  updateAd(current);
  current = page;
  loadAd(current);
}

export function removeAdClick() {
  if (count === 1) return;
  removeAd(current);
  count--;
  if (current >= count) {
    current = count - 1;
  }
  loadAd(current);
  return current;
}

export function newAdClick() {
  if (count >= 5) {
    alert("최대 광고 개수는 5개 입니다.");
    return;
  }
  count++;
  updateAd(current);
  inputNewAd(current);
  selectedFile = null;
  selectedVideo = null;
  selectedRecord = null;
  loadAd(current);
  resetPagination();
}
